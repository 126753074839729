import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/button";
import Text from "../components/text";
import Logo from "../assets/Logos/logoColor.png";
import Swal from 'sweetalert2';
import CryptoJS from 'crypto-js';

const Login = ({ }) => {

    const [societies, setSocieties] = useState([]);
    const [displaySocieties, setDisplaySocieties] = useState();

    const [societyId, setSocietyId] = useState("");
    const [dbName, setDbName] = useState("");
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");

    const [userInfo, setUserInfo] = useState("");

    const [rol, setRol] = useState([]);

    const [warningModalState, setWarningModalState] = useState(false);

    let navigate = useNavigate();

    useEffect(() => {
        getSocieties();
    }, [])

    useEffect(() => {

        const displayOptions = societies !== null && Array.isArray(societies) ? societies.map((societie) => {
            return (
                <option key={societie.Id} value={societie.Id} style={{ fontSize: "1vw" }}>{societie.NombreSociedad + ' / ' + societie.NombreBaseDatos}</option>
            );
        }) : <></>;

        setDisplaySocieties(displayOptions);

    }, [societies])

    async function getSocieties() {

        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        await fetch(`${process.env.REACT_APP_API_URL}admin/ListarSociedades`, requestOptions)
            .then(response => response.text())
            .then(result => setSocieties(JSON.parse(result)))
            .catch(error => console.log('error', error));

    };
    async function loginUser(requestOptions) {
        return fetch(`${process.env.REACT_APP_API_URL}autenticacion/login`, requestOptions);
    }
    const handleSubmit = async event =>  {

        event.preventDefault();

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json;charset=utf-8");
        myHeaders.append("Accept", "application/json, text/plain, */*");

        let raw = JSON.stringify({
            "Username": user,
            "Password": password,
            "IdSociedad": societyId
        });

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        if(!user || !password){
            Swal.fire({
                title: 'Error!',
                text: 'Debe indicar usuario, contraseña y seleccionar la sociedad con la que desea trabajar',
                icon: 'error',
                confirmButtonText: 'Aceptar'
              });
        } else {
            const response = (await loginUser(requestOptions));

            if (response.ok) {
                let json = await response.json();

                if(json.Error !== ''){
                    Swal.fire({
                        title: 'Error!',
                        text: json.Error,
                        icon: 'error',
                        confirmButtonText: 'Aceptar'
                    })
                } else{
                    if (json.IdUsuario != "" && json.Token != "" ) {
                        setUserInfo(json);
                    };
                }
              } else {
                alert("HTTP-Error: " + response.status);
              }
        };

    };

    useEffect(() => {

        societies.map((society) => {

            if(society.Id == societyId){
                setDbName(society.NombreBaseDatos);
            };
        });

    }, [societyId])

    useEffect(() => {

        if(userInfo){
            if(userInfo?.Error === ""){
                getRol();
            };
        };

}, [userInfo])

    useEffect(() => {

            let userData = {
                "userId": userInfo.IdUsuario,
                "username": user,
                "database": dbName,
                "token": userInfo.Token,
                "societyId": userInfo.IdSociedad,
                "userRolId": userInfo?.IdRol,
                "userRol": rol[0],
            };

            // Encrypt
            let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(userData), `${process.env.KEY_CRYPTO}`).toString();

            localStorage.setItem('userData', ciphertext);

            if (user != "") {
                navigate("/factura/crear");
            };

            setUser("");
            setPassword("");
            setDbName("");

    }, [rol])

    const getRol = async () => {

        let myHeaders = new Headers();
        myHeaders.append("Authorization", 'Bearer ' + userInfo?.Token);

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        await fetch(`${process.env.REACT_APP_API_URL}admin/ListarRoles_por_Id?IdRol=${userInfo?.IdRol}`, requestOptions)
            .then(response => response.text())
            .then(result => {setRol(JSON.parse(result))})
            .catch(error => console.log('error', error));

    };

    return (
        <div  className="divPrincipalLogin">
            <div className="divLogin">
                <form onSubmit={handleSubmit}>
                    <img src={Logo} className="imgLogo" alt="" />
                    <label className="labelLogin">Seleccione una sociedad / base de datos</label>
                    <select className="selectFields" aria-label="size 3 select example" onChange={(e) => setSocietyId(e.target.value)}>
                        <option value={0} style={{ fontSize: "1vw" }}>Seleccione...</option>
                        {displaySocieties}
                    </select>
                    <label className="labelLogin">Usuario</label>
                    <input className="textFields" type="text" id="username"
                        onChange={(e) => setUser(e.target.value)} name="username"></input>
                    <br></br>
                    <label className="labelLogin">Contraseña</label>
                    <input className="textFields" type="password" id="password"
                        onChange={(e) => setPassword(e.target.value)} name="password"></input>
                    <br></br>
                    <Button className="btns" type="submit">
                        Ingresar
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default Login
