import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

function Redirect(
) {
    const { link_parent, link_children } = useParams();

    let navigate = useNavigate();

    useEffect(() => {
        if (link_children) {
            navigate(`/${link_parent}/${link_children}`);
        }
    }, [link_children])

    return (
        <div style={{ display: "flex" }}>
        </div>
    );
};

export default Redirect;