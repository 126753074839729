import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../components/sidebar";
import Header from "../components/header";
import Footer from "../components/footer";
import Button from "../components/button";
import { BsTrash, BsPlusCircleFill, BsBorderRight } from "react-icons/bs"
import { RiArrowGoBackFill } from "react-icons/ri"
import { useNavigate, useParams } from "react-router-dom";
import { FaKey } from "react-icons/fa";
import Swal from 'sweetalert2';
import Select from 'react-select';
import DatePicker from 'react-date-picker';
import TableRows from "../components/tableRows";
import Validation from "../components/validationAccess";
import CryptoJS from 'crypto-js';
import CurrencyInput from '../components/CurrencyInput';

const BillCreate = ({
    billCreateClass,
    sidebarClass,
    sidebarToggleClass,
    handleViewSidebar,
    billCreateSelected
}) => {

    const addZero = (num) => {
        return (num >= 0 && num < 10) ? "0" + num : num + "";
    }

    const formatDate = () => {
        let documentDatetime = new Date();
        let strDateTime = [
            [documentDatetime.getFullYear(),
            addZero(documentDatetime.getMonth() + 1),
            addZero(documentDatetime.getDate())
            ].join("-"),
            [addZero(documentDatetime.getHours()),
            addZero(documentDatetime.getMinutes()),
            addZero(documentDatetime.getSeconds()) + "." + addZero(documentDatetime.getMilliseconds())].join(":")].join(" ");

        return strDateTime;
    }

    const [clientsResult, setClientsResult] = useState([]);
    const [clientsList, setClientsList] = useState([]);
    const [clientsByCardCode, setClientsByCardCode] = useState("");
    const [vendorList, setVendorList] = useState();
    const [vendorId, setVendorId] = useState(-1);
    const [selectSearchValue, setSelectSearchValue] = useState({ value: 'Seleccione', label: 'Seleccione' });
    const [dueDate, setDueDate] = useState(formatDate());
    const [date, setDate] = useState(formatDate());

    //STATES: For New table and table details
    const [selectAll, setSelectAll] = useState(false);
    const [rowsData, setRowsData] = useState([]);
    const [stockDataEdit, setStockDataEdit] = useState([]);
    const [rollback, setRollback] = useState([]);
    const [updated, setUpdated] = useState(false);
    const [loading, setloading] = useState(false);
    //END New table and table details

    //New states for clientInfo
    const [clientName, setClientName] = useState("");
    const [clientIdCard, setClientIdCard] = useState("");
    const [clientCode, setClientCode] = useState("");
    const [clientEmail, setClientEmail] = useState("");
    const [clientAvailable, setClientAvailable] = useState(0);
    const [clientBalance, setClientBalance] = useState(0);
    const [clientPhone, setClientPhone] = useState("");
    const [clientAuthorized, setClientAuthorized] = useState("");
    const [clientContado, setClientContado] = useState("N");
    const [comments, setComments] = useState("");
    const [otherComments, setOtherComments] = useState("");
    const [identificationType, setIdentificationType] = useState("");
    const [eBill, setEBill] = useState(true);
    //End of new states for clientInfo

    const [totalSinImpuestos, setTotalSinImpuestos] = useState(0);
    const [descuento, setDescuento] = useState(0);
    const [impuesto, setImpuesto] = useState(0);
    const [total, setTotal] = useState(0);

    const [creationStatus, setCreationStatus] = useState("");
    const [updateStatus, setUpdateStatus] = useState("");
    let totalStatus = useRef(false);
    const [processingStatus, setProcessingStatus] = useState(false);
    const [validationStatus, setValidationStatus] = useState(false);

    const [storesEdit, setStoresEdit] = useState(false);
    const [taxEdit, setTaxEdit] = useState(false);
    const [priceEdit, setPriceEdit] = useState(false);

    const [logedUser, setLogedUser] = useState();
    const [authorizationUser, setAuthorizationUser] = useState();

    const [docEntry, setDocEntry] = useState("");
    //Editar Factura (Get id of params)
    const { id, duplicate } = useParams();

    let navigate = useNavigate();

    let [newStock, setNewStock] = useState();

    const [taxError, setTaxError] = useState(false);
    let changedPriceList = useRef([]);

    const [isOkayFetchGetBill, setIsOkayFetchGetBill] = useState(false);
    let intervalGetBill = useRef(null);

    const [isOkayFetchGetProforma, setIsOkayFetchGetProforma] = useState(false);
    let intervalGetProforma = useRef(null);
    let count = useRef(0);
    let finishedLoad = useRef(false);
    const [availableAndBalance, setAvailableAndBalance] = useState({available: null, balance: null});

    let bytes = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
    let originalText = bytes.toString(CryptoJS.enc.Utf8);

    const [idRol, setIdRol] = useState(JSON.parse(originalText).userRol.Id);
    const [electronicTicket, setElectronicTicket] = useState(null);

    const userDataLocalStorage = JSON.parse(originalText);
    if (userDataLocalStorage?.userRol < 0 || userDataLocalStorage?.userRol?.ModuloFacturas != "Y" || userDataLocalStorage == undefined || userDataLocalStorage == null || userDataLocalStorage == '{"username":"","database":""}') { navigate("/"); }

    useEffect(() => {

        if (id && count.current == 0) {

            count.current = 1;
            setProcessingStatus(true);

            setTimeout(() => {
                if (duplicate !== undefined) {
                    if (duplicate === "true") {
                        getBillDuplicate(id);

                    } else {
                        getProforma(id);
                    };
                } else {
                    getBill(id);
                }
                //getBill(id);
            }, 500);
        }
    }, [id])

    useEffect(() => {
        setloading(true);

        setTimeout(() => {
            setloading(false)
        }, 100)
    }, [rollback]);

    useEffect(() => {
        getVendors();

        if (userDataLocalStorage.userRol.ModuloAdministracion == "Y") {
            setStoresEdit(true);
        }

        if (userDataLocalStorage.userRol.EditaAlmacen == "Y") {
            setStoresEdit(true);
        }

        let bytes = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);

        const userInfo = JSON.parse(originalText);

        if (userInfo) {

            setLogedUser(userInfo);

        };

        addTableRows();

    }, [])

    useEffect(() => {
        if (clientsResult !== null && Array.isArray(clientsResult)) {
            let list = [];
            for (let i = 0; i < clientsResult.length; i++) {
                let client = { value: clientsResult[i].CardCode, label: clientsResult[i].Cliente, client: clientsResult[i] };

                list.push(client);
            };
            setClientsList(list);
        };
    }, [clientsResult])

    useEffect(() => {

        if (clientsByCardCode === "") {

            setClientsList([]);
        } else {
            
            getClients(clientsByCardCode);

        };

    }, [clientsByCardCode])

    useEffect(() => {

        let totalSinImpuestosTemporal = 0;
        let descuentoTemporal = 0;
        let impuestoTeamporal = 0;
        let totalTemporal = 0;

        if (rowsData.length > 0) {

            rowsData.forEach(element => {

                let sinImpuesto = element.PRECIO_CON_DESCUENTO * parseFloat(element.CANTIDAD === "" ? 0 : element.CANTIDAD);
                totalSinImpuestosTemporal = totalSinImpuestosTemporal + sinImpuesto;

                let pasarDescuento = parseFloat(element.DESCUENTO === "" ? 0 : element.DESCUENTO) / 100;
                let descuento = element.PRECIO * pasarDescuento;
                let totalDescuento = descuento * parseFloat(element.CANTIDAD === "" ? 0 : element.CANTIDAD);
                descuentoTemporal = descuentoTemporal + totalDescuento;

                let impuesto = parseFloat(element.PORCENTAJE_DE_IMPUESTO) / 100;
                let descuentoImpuesto = element.PRECIO_CON_DESCUENTO * impuesto;
                let totalDescuentoImpuesto = descuentoImpuesto * parseFloat(element.CANTIDAD === "" ? 0 : element.CANTIDAD);
                impuestoTeamporal = impuestoTeamporal + totalDescuentoImpuesto;


                totalTemporal = totalTemporal + element.PRECIO_TOTAL_IVI;

            });

        };

        setTotalSinImpuestos(totalSinImpuestosTemporal.toFixed(2).toLocaleString("en-US"));
        setDescuento(descuentoTemporal.toFixed(2).toLocaleString("en-US"));
        setImpuesto(impuestoTeamporal.toFixed(2).toLocaleString("en-US"));
        setTotal(parseFloat(totalTemporal.toFixed(2)));
    }, [rowsData])

    useEffect(() => {

        setTimeout(() => {

            if (creationStatus.DocNum !== "-1" && creationStatus.DocNum !== undefined) {
                setProcessingStatus(false);
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: `Factura creada correctamente. Doc Num: ${creationStatus.DocNum}`
                })

            } else {
                //console.log(creationStatus); //TODO: revisar que validación debe ir aquí
            }
        }, 1500)

    }, [creationStatus])

    useEffect(() => {

        setTimeout(() => {

            if (updateStatus.DocNum !== "-1" && updateStatus.DocNum !== undefined) {
                setProcessingStatus(false);
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: `Factura actualizada correctamente. Doc Num: ${updateStatus.DocNum}`
                })

            } else {
                //console.log(updateStatus); //TODO: revisar que validación debe ir aquí
            };
        }, 1500)

    }, [updateStatus])

    /*useEffect(() => {

        if (!id) {
            //console.log('Comparación ', total > (clientAvailable - clientBalance) && clientContado === "N");
            if ((total > clientAvailable) && clientContado === "N") {

                console.log('12345 está entrando a la validación que no debería');
                totalStatus.current = true;

                Swal.fire({
                    title: 'Error!',
                    text: "El total a pagar no puede ser mayor al crédito del cliente",
                    icon: 'error',
                    confirmButtonText: 'Aceptar',
                })

            } else {

                totalStatus.current = false;
            };
        }


    }, [total, clientAvailable, clientBalance]) //TODO: el problema de refrescamiento radica en esta validación*/

    /*useEffect(() => { // TODO: revisar si la validación del disponible está funcionando como debería

        if (id && finishedLoad.current === true && availableAndBalance.available != null && availableAndBalance.balance != null) {

            if ((total > (clientAvailable - clientBalance)) && clientContado === "N") { // TODO: revisar si la validación del disponible está funcionando como debería
                totalStatus.current = true;

                Swal.fire({
                    title: 'Error!',
                    text: "El total a pagar no puede ser mayor al crédito del cliente",
                    icon: 'error',
                    confirmButtonText: 'Aceptar',
                })

            } else {

                totalStatus.current = false;
            };
        }
    }, [total, availableAndBalance])*/

    useEffect(() => {

        if (isOkayFetchGetBill) {
            setIsOkayFetchGetBill(false);
            return () => clearInterval(intervalGetBill.current);
        }

    }, [isOkayFetchGetBill])

    useEffect(() => {

        if (isOkayFetchGetProforma) {
            setIsOkayFetchGetProforma(false);
            return () => clearInterval(intervalGetProforma.current);
        }

    }, [isOkayFetchGetProforma])

    //FUNCTIONS: For New table and table details
    const addTableRows = () => {
        setSelectAll(false);

        if (rowsData.length == 0 || rowsData[rowsData.length - 1]['ARTICULO_DESCRIPCION'] !== "" || rowsData[rowsData.length - 1]['VALIDO']) {
            const rowsInput = {
                DATA: null,
                CHECK: false,
                ID: '',
                ARTICULO_DESCRIPCION: '',
                STOCK: 0,
                CANTIDAD: 0,
                PRECIO: 0,
                DESCUENTO: 0,
                PRECIO_CON_DESCUENTO: 0,
                PRECIO_TOTAL_IVI: 0,
                CODIGO_DE_IMPUESTO: 0,
                PORCENTAJE_DE_IMPUESTO: 0,
                ALMACEN: '',
                VALIDO: false,
                PRECIO_MODIFICADO: false,
                PRECIO_FACTURADO: 0,
                SERVICIO: false
            }

            setRowsData([...rowsData, rowsInput])
        } else {
            Swal.fire({
                title: 'Advertencia!',
                html: 'Debe completar toda la información del último producto para poder agregar más',
                icon: 'warning',
                confirmButtonText: 'Aceptar'
            });
        }
    }

    const deleteTableRows = (index) => {
        const rows = [...rowsData];
        setRollback(rowsData);
        rows.splice(index, 1);
        setRowsData(rows);
        setUpdated(!updated);
    };

    const handleChange = (index, evnt) => {

        const rowsInput = [...rowsData];

        if (evnt?.target) {
            let { name, value, checked } = evnt.target;
            
            if (evnt.target.type == 'checkbox') {
                setSelectAll(false);
                rowsInput[index][name] = !!(checked);
            } else {

                rowsInput[index][name] = value;

                if (evnt.target.name == "ALMACEN") {
                    rowsInput[index]['DATA'].Almacen = value;
                }

                if (evnt.target.name == "PRECIO") {

                    if (Number.isNaN(value) || value == '' || value == ' ') {
                        value = '0';

                    }
                    rowsInput[index]['DATA'].Precio = value.replace(/\,/g, '').replace(',', '');
                    rowsInput[index]['PRECIO'] = value.replace(/\,/g, '').replace(',', '');
                }

                if (evnt.target.name == "PORCENTAJE IMPUESTO") {

                    if (Number.isNaN(value) || value == '' || value == ' ') {
                        value = '0';
                    }

                    rowsInput[index]['DATA'].PorcentajeImpuesto = value;
                    rowsInput[index]['PORCENTAJE_DE_IMPUESTO'] = value;
                }

                if (evnt.target.name == "CODIGO IMPUESTO") {
                    rowsInput[index]['DATA'].CodigoImpuesto = value;
                    rowsInput[index]['CODIGO_DE_IMPUESTO'] = value;

                }

                // for (const iterator of userDataLocalStorage.userRol.AlmacenesAsignados) {

                // }
                // if(){
                //     rowsInput[index]['DATA'].Almacen = value;
                // } else{

                // }
            }

        } else {
            
            const { value, label, data } = evnt;

            if (data?.Articulo) {

                rowsInput[index][value] = label;
                rowsInput[index]['DATA'] = data;

                rowsInput[index]['STOCK'] = data?.Disponible;

                rowsInput[index]['PRECIO'] = parseFloat(data?.Precio);

                rowsInput[index]['CODIGO_DE_IMPUESTO'] = data?.CodigoImpuesto;
                rowsInput[index]['PORCENTAJE_DE_IMPUESTO'] = data?.PorcentajeImpuesto;

                rowsInput[index]['ALMACEN'] = data?.Almacen;
                rowsInput[index]['ARTICULO_DESCRIPCION'] = data?.Articulo;

                if (data.Propiedad == "SERVICIOS") {
                    rowsInput[index]['SERVICIO'] = true;
                }
                
            } else {
                Swal.fire({
                    title: 'Advertencia!',
                    html: 'El producto seleccionado NO esta disponible para su venta. <br /><br />' +
                        '<strong> Contacte al administrador. </strong>',
                    icon: 'warning',
                    confirmButtonText: 'Aceptar'
                });
                rowsInput[index][value] = '';
                rowsInput[index]['DATA'] = '';
                rowsInput[index]['STOCK'] = 0;
                rowsInput[index]['PRECIO'] = 0;
                rowsInput[index]['CODIGO_DE_IMPUESTO'] = '';
                rowsInput[index]['PORCENTAJE_DE_IMPUESTO'] = 0;
                rowsInput[index]['ALMACEN'] = '';
                rowsInput[index]['PRECIO_CON_DESCUENTO'] = 0;
                rowsInput[index]['PRECIO_TOTAL_IVI'] = 0;
                rowsInput[index]['ARTICULO_DESCRIPCION'] = '';
                rowsInput[index]['CANTIDAD'] = 0;
                rowsInput[index]['DESCUENTO'] = 0;
                rowsInput[index]['VALIDO'] = false;
                rowsInput[index]['SERVICIO'] = false;
            }

        };

        let discount = rowsInput[index]['DESCUENTO'] === "" ? 0 : parseFloat(rowsInput[index]['DESCUENTO']) / 100;

        let priceWithDiscount = parseFloat(rowsInput[index]['PRECIO']) - (parseFloat(rowsInput[index]['PRECIO']) * discount);

        let iva = parseFloat(rowsInput[index]['PORCENTAJE_DE_IMPUESTO'] === "" ? 0 : rowsInput[index]['PORCENTAJE_DE_IMPUESTO']) / 100;

        let priceWithIva = priceWithDiscount * iva;

        let priceDiscountIva = priceWithDiscount + priceWithIva;

        let quantity = rowsInput[index]['CANTIDAD'] === "" ? 0 : parseFloat(rowsInput[index]['CANTIDAD']);

        let totalPrice = priceDiscountIva * quantity;

        rowsInput[index]['PRECIO_CON_DESCUENTO'] = priceWithDiscount;

        rowsInput[index]['PRECIO_TOTAL_IVI'] = totalPrice;

        rowsInput[index]['VALIDO'] = true;

        setRowsData(rowsInput);
    }

    const editWarehouseStock = (rowTable, warehouse, stock) => {

        const rowsInput = [...rowsData];

        rowsInput[rowTable]['STOCK'] = stock;
        rowsInput[rowTable]['DATA'].Disponible = stock;
        rowsInput[rowTable]['ALMACEN'] = warehouse;
        rowsInput[rowTable]['DATA'].Almacen = warehouse;
        setRowsData(rowsInput);
    }

    const selectAllTable = (evnt) => {
        setSelectAll(evnt?.target?.checked);
        const tempData = rowsData.map(el => ({ ...el, CHECK: evnt?.target?.checked }));
        setRowsData(tempData);
    };

    const deleteTableSelects = () => {
        setSelectAll(false);
        let filtered = rowsData.filter(function (value) {
            return value.CHECK == false;
        });

        setRollback(rowsData);
        setRowsData(filtered);
        setUpdated(!updated);
    };
    //ENDS: For New table and table details

    const handleKeyDown = (event) => {

        let value = event.target.value + "" + event.key

        if (!validateNumbers(value) && event.key != 'Enter' && event.key != 'Del' && event.key != 'Tab' && event.key != 'Backspace'
            && event.key != 'Control' && event.key != 'v' && event.key != 'c' && (event.keyCode < '36' || event.keyCode > '40')) {
            event.preventDefault()
        }

        if (event.key === 'Enter') {
            getUserInfoByIC();
        }
    };

    const handleBlur = (event) => {
        getUserInfoByIC();
    };

    const taxBlur = (percentage, index, state) => {

        setTaxError(state);

        if (!state) {
            const rowsInput = [...rowsData];

            rowsInput[index]['PORCENTAJE_DE_IMPUESTO'] = percentage;

            let discount = rowsInput[index]['DESCUENTO'] === "" ? 0 : parseFloat(rowsInput[index]['DESCUENTO']) / 100;
            let priceWithDiscount = parseFloat(rowsInput[index]['PRECIO']) - (parseFloat(rowsInput[index]['PRECIO']) * discount);
            let iva = parseFloat(rowsInput[index]['PORCENTAJE_DE_IMPUESTO'] === "" ? 0 : rowsInput[index]['PORCENTAJE_DE_IMPUESTO']) / 100;
            let priceWithIva = priceWithDiscount * iva;
            let priceDiscountIva = priceWithDiscount + priceWithIva;
            let quantity = rowsInput[index]['CANTIDAD'] === "" ? 0 : parseFloat(rowsInput[index]['CANTIDAD']);
            let totalPrice = priceDiscountIva * quantity;
            rowsInput[index]['PRECIO_CON_DESCUENTO'] = priceWithDiscount;
            rowsInput[index]['PRECIO_TOTAL_IVI'] = totalPrice;
            rowsInput[index]['VALIDO'] = true;

            setRowsData(rowsInput);
        }
    }

    const validateNumbers = (values) => {

        let myReg = /^[0-9]+$/;
        let isNum = false;

        if (values.match(myReg)) {
            isNum = true;
        }

        return isNum;
    }

    const clientInfoBySearch = (clientInfo) => {
        setClientName(clientInfo.CardName);
        setClientIdCard(clientInfo.Cedula);
        setClientCode(clientInfo.CardCode);
        setClientEmail(clientInfo.Email);
        setClientAvailable(parseInt(clientInfo.Disponible));
        setClientBalance(parseInt(clientInfo.SaldoCuenta));
        setClientPhone(clientInfo.Telefono);
        setClientAuthorized(clientInfo.Autorizados);
        setClientContado(clientInfo.ClienteContado);
        setAvailableAndBalance({
            available: parseInt(clientInfo.Disponible),
            balance: parseInt(clientInfo.SaldoCuenta)
        });
        setElectronicTicket(clientInfo.EsTiqueteElectronico);
        (clientInfo.EsTiqueteElectronico == "S") ? setEBill(false) : setEBill(true);
    };

    const clientInfoByIC = (clientInfo) => {
        setClientName(clientInfo.Nombre);
        setClientIdCard(clientInfo.Cedula);
        //setClientCode(clientInfo.CodigoCC); // TODO: se comentó porque daba bug con códigos
        setClientEmail(clientInfo.Correo);
        setIdentificationType(clientInfo.TipoIdentificacion);
        setClientAvailable(0);
        setClientBalance(0);
        setClientPhone("");
        setClientAuthorized("");
        setClientContado("S");
    };

    const showErrorMessage = (errorMessage) => {

        let split = errorMessage.split('/');

        setProcessingStatus(false);

        if (errorMessage == "Token -1" || (split[1] && split[1] == "")) {
            Swal.fire({
                title: 'Se venció la sesión!',
                html: "Debes iniciar sesión nuevamente" + '<br/> serás redirigido automáticamente en 3 segundos...',
                timer: 3000,
                icon: 'error',
                showConfirmButton: false
            }).then((result) => {
                navigate("/");
            });
        } else if (errorMessage !== null && errorMessage !== "") {
            Swal.fire({
                title: 'Error!',
                text: errorMessage,
                icon: 'error',
                confirmButtonText: 'Aceptar',
            })
        };
    };

    const cancelWarning = () => {

        Swal.fire({
            title: '¡Advertencia!',
            text: "¿Está seguro de querer cancelar el progreso? Esta acción es irreversible.",
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Aceptar',
            denyButtonText: 'Cancelar'

        }).then((result) => {

            if (result.isConfirmed) {

                navigate(`/factura/buscar`);
            } else if (result.isDenied) {

            }
        });

    };

    const verification = (type) => {

        let index = 1;
        let errorMessage = "";
        let discountLines = "";
        let stockLines = "";
        let priceWithDiscount = "";
        let quantityLines = "";

        if (rowsData !== null && Array.isArray(rowsData)) {

            rowsData.map((row) => {

                if (parseFloat(row?.DESCUENTO) > logedUser?.userRol?.DescuentoPermitido) {

                    if (authorizationUser) {

                        if (parseFloat(row?.DESCUENTO) > authorizationUser?.userRol?.DescuentoPermitido) {

                            discountLines = discountLines + index + ", ";

                        };

                    } else {
                        discountLines = discountLines + index + ", ";
                    };

                };

                if ((parseFloat(row?.CANTIDAD) > parseFloat(row?.STOCK)) && type === "create" && row.SERVICIO != true) {

                    stockLines = stockLines + index + ", ";
                };

                if ((parseFloat(row?.CANTIDAD) == 0)) {
                    quantityLines = quantityLines + index + ", ";
                }

                index = index + 1;

            });

        };

        if (rowsData !== null && Array.isArray(rowsData) && stockDataEdit !== null && Array.isArray(stockDataEdit) && type === "update") {

            let i = 0;

            while (i <= rowsData.length - 1) {

                if (stockDataEdit[i] != null && stockDataEdit[i] != undefined) {
                    if ((parseFloat(rowsData[i]?.CANTIDAD) > parseFloat(stockDataEdit[i]?.CANTIDAD)) && (parseFloat(rowsData[i]?.CANTIDAD) > parseFloat(rowsData[i]?.STOCK)) && rowsData[i].SERVICIO != true) {
                        stockLines = stockLines + (i + 1) + ", ";
                    };
                } else {
                    if ((parseFloat(rowsData[i]?.CANTIDAD) > parseFloat(rowsData[i]?.STOCK)) && rowsData[i].SERVICIO != true) {
                        stockLines = stockLines + (i + 1) + ", ";
                    };
                }

                i = i + 1;
            }
        }

        if (discountLines !== "") {

            errorMessage = "En la línea " + discountLines.slice(0, -2) + " el descuento es el mayor al permitido. ";

        };

        if (quantityLines !== "") {
            errorMessage = "En la línea " + quantityLines.slice(0, -2) + " la cantidad no puede ser 0. ";
        }

        if (stockLines !== "" && type !== "update") {

            errorMessage = errorMessage + "En la línea " + stockLines.slice(0, -2) + " la cantidad a vender es mayor a la cantidad disponible del producto. ";

        } else if (stockLines !== "" && type === "update") {

            errorMessage = errorMessage + "En la línea " + stockLines.slice(0, -2) + " la cantidad a vender es mayor a la cantidad disponible del producto. ";
        }

        if ((total < clientAvailable) && clientContado === "N") {
            totalStatus.current = false;
        } else if (clientContado != "N") {
            totalStatus.current = false;
        } else {
            totalStatus.current = true;
        }

        if (discountLines !== "" || stockLines !== "" || priceWithDiscount !== "" || quantityLines !== "") {

            setProcessingStatus(false);

            Swal.fire({
                title: 'Error!',
                text: errorMessage,
                icon: 'error',
                confirmButtonText: 'Aceptar',
            })

        } else {

            if (parseInt(vendorId) === -1) {

                setProcessingStatus(false);

                Swal.fire({
                    title: 'Error!',
                    text: "Debe seleccionar un vendedor",
                    icon: 'error',
                    confirmButtonText: 'Aceptar',
                })
            } else if (taxError) {

                setProcessingStatus(false);

                Swal.fire({
                    title: '!Error!',
                    text: "Debe digitar un código de impuesto correcto",
                    icon: 'error',
                    confirmButtonText: 'Aceptar',
                })
            } else {

                if (type === "create" && totalStatus.current === false) {
                    createBill(); // TODO: descomentar
                    console.log('Entró al create');
                } else {

                    if (totalStatus.current === true) {

                        setProcessingStatus(false);

                        Swal.fire({
                            title: 'Error!',
                            text: "El total a pagar no puede ser mayor al crédito del cliente",
                            icon: 'error',
                            confirmButtonText: 'Aceptar',
                        })
                    };
                };

                if (type === "update" && totalStatus.current === false) {
                    updateBill(); // TODO: descomentar
                    console.log('Entró al update');
                } else {

                    if (totalStatus.current === true) {

                        setProcessingStatus(false);

                        Swal.fire({
                            title: 'Error!',
                            text: "El total a pagar no puede ser mayor al crédito del cliente",
                            icon: 'error',
                            confirmButtonText: 'Aceptar',
                        })
                    };
                };

            };

        };

    };

    const attachPreviousPrice = (documentLines) => {

        if ((changedPriceList.current != null && changedPriceList.current != undefined && changedPriceList.current != []) && documentLines != null && documentLines != undefined && documentLines.length != 0) {
            changedPriceList.current?.forEach(element => {

                let found = documentLines.find(line => line.DATA.ItemCode == element.ItemCode);

                found.PRECIO_MODIFICADO = true;
                found.PRECIO_FACTURADO = element.Precio;
            })
        }

        return documentLines;
    }

    const getPriceChange = async (docNum) => {

        let securityToken = "";
        let bytes = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        const items = JSON.parse(originalText);

        if (items && items.token) {

            securityToken = items.token;

            let myHeaders = new Headers();
            myHeaders.append("Authorization", 'Bearer ' + securityToken);

            let requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            await fetch(`${process.env.REACT_APP_API_URL}facturacion/ConsultaArticulos_Precio_Diferente?DocNum=${docNum}`, requestOptions)
                .then(response => response.text())
                .then(result => {

                    if (JSON.parse(result) != -1) {
                        if (JSON.parse(result) != 0) {

                            changedPriceList.current = JSON.parse(result);

                            let postions = "";

                            changedPriceList.current.forEach((element) => {

                                postions += (element.LineNum + 1) + ", "
                            })

                            Swal.fire({
                                title: '¡Advertencia!',
                                text: 'El precio de los artículos en las posiciones ' + postions + 'ha cambiado',
                                icon: 'warning',
                                confirmButtonText: 'Aceptar',
                            })
                        }
                    } else {
                        showErrorMessage('Se produjo un error tratando de comprobar si cambiaron los precios de los artículos');
                    }
                })
                .catch(error => showErrorMessage(error + '/' + securityToken));
        } else {
            showErrorMessage("Token -1");
        }
    }

    const getPriceChangeQuotation = async (docNum) => {

        let securityToken = "";
        let bytes = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        const items = JSON.parse(originalText);

        if (items && items.token) {

            securityToken = items.token;

            let myHeaders = new Headers();
            myHeaders.append("Authorization", 'Bearer ' + securityToken);

            let requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            await fetch(`${process.env.REACT_APP_API_URL}cotizacion/ConsultaArticulos_Precio_Diferente_Cotizacion?DocNum=${docNum}`, requestOptions)
                .then(response => response.text())
                .then(result => {

                    if (JSON.parse(result) != -1) {
                        if (JSON.parse(result) != 0) {

                            changedPriceList.current = JSON.parse(result);

                            let postions = "";

                            changedPriceList.current.forEach((element) => {

                                postions += (element.LineNum + 1) + ", "
                            })

                            Swal.fire({
                                title: '¡Advertencia!',
                                text: 'El precio de los artículos en las posiciones ' + postions + 'ha cambiado',
                                icon: 'warning',
                                confirmButtonText: 'Aceptar',
                            })
                        }
                    } else {
                        showErrorMessage('Se produjo un error tratando de comprobar si cambiaron los precios de los artículos');
                    }
                })
                .catch(error => showErrorMessage(error + '/' + securityToken));
        } else {
            showErrorMessage("Token -1");
        }
    }

    const getUserInfoByIC = async () => {

        let securityToken = "";
        let societyId = 0;

        let bytes = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);

        const items = JSON.parse(originalText);

        if (items && items.token) {
            securityToken = items.token;
            societyId = items.societyId

            let myHeaders = new Headers();
            myHeaders.append("Authorization", 'Bearer ' + securityToken);

            let requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            await fetch(`${process.env.REACT_APP_API_URL}facturacion/ConsultaClienteContado?Cedula=${clientIdCard}&IdSociedad=${societyId}`, requestOptions)
                .then(response => response.text())
                .then(result => {clientInfoByIC(JSON.parse(result)); console.log('Datos del cambio', JSON.parse(result));})
                .catch(error => showErrorMessage(error + '/' + securityToken));
        } else {
            showErrorMessage("Token -1");
        }
    };

    const getVendors = async () => {

        let securityToken = "";

        let bytes = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);

        const items = JSON.parse(originalText);

        if (items && items.token) {
            securityToken = items.token;

            let myHeaders = new Headers();
            myHeaders.append("Authorization", 'Bearer ' + securityToken);
            myHeaders.append("Accept", "application/json, */*");

            let requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            await fetch(`${process.env.REACT_APP_API_URL}facturacion/ListarVendedores`, requestOptions)
                .then(response => response.text())
                .then(result => setVendorList(JSON.parse(result)))
                .catch(error => showErrorMessage(error + '/' + securityToken));
        } else {
            showErrorMessage("Token -1");
        }

    };

    const getClients = async (cardCode) => {

        let securityToken = "";

        let bytes = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);

        const items = JSON.parse(originalText);

        if (items && items.token) {
            securityToken = items.token;

            let myHeaders = new Headers();
            myHeaders.append("Authorization", 'Bearer ' + securityToken);

            let requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            await fetch(`${process.env.REACT_APP_API_URL}bp/ConsultaSocioNegocio_por_CardCode_Facturacion?CardCode=${cardCode}`, requestOptions)
                .then(response => response.text())
                .then(result => { setClientsResult(JSON.parse(result)); })
                .catch(error => showErrorMessage(error + '/' + securityToken));
        } else {
            showErrorMessage("Token -1");
        }
    };

    const createBill = async () => {

        const documentLines = rowsData !== null && Array.isArray(rowsData) ? rowsData.map((row) => {

            if (row?.DATA?.ItemCode)
                return (
                    {
                        "ItemCode": row.DATA.ItemCode,
                        "Quantity": parseFloat(row.CANTIDAD),
                        "TaxCode": row.CODIGO_DE_IMPUESTO,
                        "Price": row.PRECIO_TOTAL_IVI,
                        "UnitPrice": row.PRECIO,
                        "TaxPercentagePerRow": row.PORCENTAJE_DE_IMPUESTO,
                        "DiscountPercent": parseFloat(row.DESCUENTO),
                        "U_NVT_DisponibleReal": row.DATA.Disponible,
                        "WarehouseCode": row.DATA.Almacen
                    }
                );
        }) : [];

        let myHeaders = new Headers();

        let securityToken = "";

        let bytes = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);

        const items = JSON.parse(originalText);

        if (items && items.token) {
            securityToken = items.token;


            myHeaders.append("Authorization", 'Bearer ' + securityToken);
            myHeaders.append("Content-Type", "application/json");

            let documentType = 0;

            if (eBill) {
                documentType = 1;
            }

            let raw = JSON.stringify({
                "U_NVT_Identificador_Fiscal": clientIdCard,
                "LicTradNum": clientIdCard,
                "CardCode": clientCode,
                "CardName": clientName,
                "E_Mail": clientEmail,
                "DocDueDate": dueDate,
                "DocDate": date,
                "SalesPersonCode": parseInt(vendorId),
                "Comments": comments,
                "U_NVT_Comentarios": otherComments,
                "EsClienteContado": clientContado,
                "DocumentLines": documentLines,
                "U_NVT_TipoDocumento": documentType,
                "TipoIdentificacion": identificationType,
                "U_NVT_Tipo_Persona": "string"
            });


            //console.log('DATOS DEL CREATE BILL', JSON.parse(raw));

            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_API_URL}facturacion/CreateOrders`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.DocNum != -1) {
                        setCreationStatus(result);
                        setTimeout(() => {
                            navigate(`/factura/buscar/${result.DocNum}`);
                        }, 500);

                    } else {
                        setProcessingStatus(false);
                        showErrorMessage(result.Error);
                    }
                })
                .catch(error => console.log(error + '/' + securityToken));
        } else {
            showErrorMessage("Token -1");
        }
    };

    // const getStockWhenDuplicate = (documentLines) => {

    //     let bytes = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
    //     let originalText = bytes.toString(CryptoJS.enc.Utf8);

    //     const items = JSON.parse(originalText);

    //     let myHeaders = new Headers();
    //     myHeaders.append("Authorization", 'Bearer ' + items?.token);

    //     let requestOptions = {
    //         method: 'GET',
    //         headers: myHeaders,
    //         redirect: 'follow'
    //     };

    //     let prueba = null;

    //     documentLines.forEach( async (line) => {

    //         await fetch(`${process.env.REACT_APP_API_URL}facturacion/ConsultaArticuloDisponibleEnBodega?CodigoArticulo=${line.DATA.ItemCode}&CodigoBodega=${line.DATA.WarehouseCode}`, requestOptions)
    //             .then(response => response.text())
    //             //.then(result => result = { 'line': line, 'response': result })
    //             .then(result => { prueba = result; }) //line.DATA.Disponible = result; line.STOCK = result;
    //             .catch(error => showErrorMessage('Se produjo un error al cargar el stock'))

    //             line.DATA.Disponible = parseFloat(prueba); 
    //             line.STOCK = parseFloat(prueba);
    //         }
    //     )

    //     console.log('documentLines', documentLines);

    //     return documentLines;
    // }

    const getBill = async (id) => {

        let bytes = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);

        const items = JSON.parse(originalText);

        intervalGetBill.current = setInterval(async () => {

            

            await fetch(`${process.env.REACT_APP_API_URL}facturacion/ConsultaOrdenesVentaEditar?DocNum=${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + items?.token
                }
            }).then(res => res.json())
                .then(response => {
                    if (response?.length > 0) {

                        setIsOkayFetchGetBill(true);
                        let lastBill = response[0];
                        //console.log('Datos del getBill billCreate', lastBill);
                        setDueDate(lastBill.Fecha_Hora);

                        let auxRowsData = [];

                        let myHeaders = new Headers();
                        myHeaders.append("Authorization", 'Bearer ' + items?.token);

                        let requestOptions = {
                            method: 'GET',
                            headers: myHeaders,
                            redirect: 'follow'
                        };

                        if (!duplicate) {
                            fetch(`${process.env.REACT_APP_API_URL}bp/ConsultaSocioNegocio_por_CardCode_Facturacion?CardCode=${lastBill.CardCode}`, requestOptions)
                                .then(response => response.json())
                                .then(result => {
                                    if (result?.length > 0) {
                                        
                                        let found = result.find(line => line.CardCode == lastBill.CardCode);
                                        let clientInfo = found;
                                        //console.log('Response del editar bill socio hola', clientInfo);
                                        setDocEntry(lastBill.DocEntry);

                                        setClientName(lastBill.CardName);
                                        setClientAvailable(parseInt(clientInfo.Disponible));
                                        setClientBalance(parseInt(clientInfo.SaldoCuenta));
                                        setClientPhone(clientInfo.Telefono);
                                        setClientAuthorized(clientInfo.Autorizados);
                                        setClientContado(clientInfo.ClienteContado);
                                        setClientCode(lastBill.CardCode);
                                        setClientIdCard(lastBill.U_NVT_Identificador_Fiscal);
                                        setClientEmail(clientInfo.Email);
                                        setComments(lastBill.Comments);
                                        setOtherComments(lastBill.OtrosComments);
                                        setSelectSearchValue({ value: lastBill.CardCode, label: lastBill.CardCode + " - " + lastBill.CardName });
                                        setAvailableAndBalance({
                                            available: parseInt(clientInfo.Disponible),
                                            balance: parseInt(clientInfo.SaldoCuenta)
                                        });
                                        setElectronicTicket(clientInfo.EsTiqueteElectronico);
                                        setIdentificationType(clientInfo.TipoIdentificacion);
                                        if(clientInfo.EsTiqueteElectronico == "S") { setEBill(false); }
                                    };
                                })
                                .catch(error => showErrorMessage(error + '/' + items?.token));
                        }

                        lastBill?.DocumentLines.forEach(element => {

                            let discount = element.DiscPrcnt === "" ? 0 : parseFloat(element.DiscPrcnt) / 100;

                            let priceWithDiscount = parseFloat(element.Price) - (parseFloat(element.Price) * discount);

                            let iva = parseFloat(element.TaxPercentagePerRow === "" ? 0 : element.TaxPercentagePerRow) / 100;

                            let priceWithIva = priceWithDiscount * iva;

                            let priceDiscountIva = priceWithDiscount + priceWithIva;

                            let quantity = element.Quantity === "" ? 0 : parseFloat(element.Quantity);

                            let totalPrice = priceDiscountIva * quantity;

                            auxRowsData.push({
                                DATA: {
                                    Almacen: element.WarehouseCode,
                                    Articulo: element.Articulo,
                                    CodigoImpuesto: element.TaxCode,
                                    Disponible: element.U_NVT_DisponibleReal,
                                    ItemCode: element.ItemCode,
                                    ItemName: element.Dscription,
                                    PorcentajeImpuesto: element.TaxPercentagePerRow,
                                    Precio: element.Price,
                                },
                                CHECK: false,
                                ID: '',
                                ARTICULO_DESCRIPCION: element.Articulo,
                                STOCK: element.U_NVT_DisponibleReal,
                                CANTIDAD: element.Quantity,
                                PRECIO: element.Price,
                                DESCUENTO: element.DiscPrcnt,
                                PRECIO_CON_DESCUENTO: priceWithDiscount,
                                PRECIO_TOTAL_IVI: totalPrice,
                                CODIGO_DE_IMPUESTO: element.TaxCode,
                                PORCENTAJE_DE_IMPUESTO: element.TaxPercentagePerRow,
                                ALMACEN: element.WarehouseCode,
                                PRECIO_MODIFICADO: false,
                                PRECIO_FACTURADO: 0
                            });

                        });

                        if (!duplicate) {
                            setVendorId(lastBill.SlpCode);
                            setComments(lastBill.Comments);
                        }

                        if (changedPriceList.current != []) {
                            auxRowsData = attachPreviousPrice(auxRowsData);
                        }

                        // if (duplicate) {
                        //     auxRowsData = getStockWhenDuplicate(auxRowsData);
                        // }

                        setRowsData(auxRowsData);
                        setStockDataEdit(JSON.parse(JSON.stringify(auxRowsData)));
                    };

                    setProcessingStatus(false);
                    finishedLoad.current = true;
                })
                .catch(error => { setProcessingStatus(false); console.error('Error:', error); });

        }, 1300); // TODO: se cambió el tiempo

        await getPriceChange(id);
    };

    const getBillDuplicate = async (id) => {

        let bytes = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);

        const items = JSON.parse(originalText);

        intervalGetBill.current = setInterval(async () => {

            

            await fetch(`${process.env.REACT_APP_API_URL}facturacion/ConsultaOrdenesVentaEditar_Duplicar?DocNum=${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + items?.token
                }
            }).then(res => res.json())
                .then(response => {
                    if (response?.length > 0) {

                        setIsOkayFetchGetBill(true);
                        let lastBill = response[0];
                        setDueDate(lastBill.Fecha_Hora);

                        let auxRowsData = [];

                        lastBill?.DocumentLines.forEach(element => {

                            let discount = element.DiscPrcnt === "" ? 0 : parseFloat(element.DiscPrcnt) / 100;
                            let priceWithDiscount = parseFloat(element.Price) - (parseFloat(element.Price) * discount);
                            let iva = parseFloat(element.TaxPercentagePerRow === "" ? 0 : element.TaxPercentagePerRow) / 100;
                            let priceWithIva = priceWithDiscount * iva;
                            let priceDiscountIva = priceWithDiscount + priceWithIva;
                            let quantity = element.Quantity === "" ? 0 : parseFloat(element.Quantity);
                            let totalPrice = priceDiscountIva * quantity;
                            let servicio = false;

                            if (element.Propiedad == "SERVICIOS") {
                                servicio = true;
                            }

                            auxRowsData.push({
                                DATA: {
                                    Almacen: element.WarehouseCode,
                                    Articulo: element.Articulo,
                                    CodigoImpuesto: element.TaxCode,
                                    Disponible: element.U_NVT_DisponibleReal,
                                    ItemCode: element.ItemCode,
                                    ItemName: element.Dscription,
                                    PorcentajeImpuesto: element.TaxPercentagePerRow,
                                    Precio: element.Price,
                                },
                                CHECK: false,
                                ID: '',
                                ARTICULO_DESCRIPCION: element.Articulo,
                                STOCK: element.U_NVT_DisponibleReal,
                                CANTIDAD: element.Quantity,
                                PRECIO: element.Price,
                                DESCUENTO: element.DiscPrcnt,
                                PRECIO_CON_DESCUENTO: priceWithDiscount,
                                PRECIO_TOTAL_IVI: totalPrice,
                                CODIGO_DE_IMPUESTO: element.TaxCode,
                                PORCENTAJE_DE_IMPUESTO: element.TaxPercentagePerRow,
                                ALMACEN: element.WarehouseCode,
                                PRECIO_MODIFICADO: false,
                                PRECIO_FACTURADO: 0,
                                SERVICIO: servicio
                            });

                        });

                        if (changedPriceList.current != []) {
                            auxRowsData = attachPreviousPrice(auxRowsData);
                        }

                        setRowsData(auxRowsData);
                        setStockDataEdit(JSON.parse(JSON.stringify(auxRowsData)));
                    };

                    setProcessingStatus(false);
                    finishedLoad.current = true;
                })
                .catch(error => { setProcessingStatus(false); console.error('Error:', error); });

        }, 1300);

        await getPriceChange(id);
    };

    const updateBill = async () => { //TODO: U_NVT_Identificador_Fiscal

        const documentLines = rowsData !== null && Array.isArray(rowsData) ? rowsData.map((row) => {
            if (row?.DATA?.ItemCode)
                return (
                    {
                        "ItemCode": row.DATA.ItemCode,
                        "Quantity": parseFloat(row.CANTIDAD),
                        "TaxCode": row.CODIGO_DE_IMPUESTO,
                        "Price": row.PRECIO_TOTAL_IVI,
                        "UnitPrice": row.PRECIO,
                        "TaxPercentagePerRow": row.PORCENTAJE_DE_IMPUESTO,
                        "DiscountPercent": parseFloat(row.DESCUENTO),
                        "U_NVT_DisponibleReal": row.DATA.Disponible,
                        "WarehouseCode": row.DATA.Almacen
                    }
                );
        }) : [];

        let myHeaders = new Headers();

        let securityToken = "";

        let bytes = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);

        const items = JSON.parse(originalText);

        if (items && items.token) {
            securityToken = items.token;


            myHeaders.append("Authorization", 'Bearer ' + securityToken);
            myHeaders.append("Content-Type", "application/json");

            let documentType = 0;
            if (eBill) {
                documentType = 1;
            }

            let raw = JSON.stringify({
                "DocEntry": docEntry,
                "DocNum": id,
                "DocStatus": "O",
                "DocDate": date,
                "DocDueDate": formatDate(dueDate), //TODO: revisar porque antes estaba new Date(date.toString().split('GMT')[0] + ' UTC').toISOString()
                "DocTotal": total.toLocaleString("en-US"),
                "Comments": comments,
                "U_NVT_Comentarios": otherComments,
                "SalesPersonCode": parseInt(vendorId),
                "DocumentLines": documentLines,
                "U_NVT_TipoDocumento": documentType,
                "TipoIdentificacion": identificationType
            }); //TODO: falta cédula y hay que poner la hora en el BE

            //console.log('DATOS DEL UPDATE BILL', JSON.parse(raw));

            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_API_URL}facturacion/UpdateOrders`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.DocNum != -1) {
                        setUpdateStatus(result);
                        setTimeout(() => {
                            navigate(`/factura/buscar/${result.DocNum}`);
                        }, 1500);
                    } else {
                        showErrorMessage(result.Error);
                    }
                })
                .catch(error => console.log(error + '/' + securityToken));
        } else {
            showErrorMessage("Token -1");
        }
    };

    const getProforma = async (id) => {

        let bytes = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);

        const items = JSON.parse(originalText);

        intervalGetProforma.current = setInterval(async () => {

            

            await fetch(`${process.env.REACT_APP_API_URL}cotizacion/ConsultaOrdenesVentaEditar_Cotizacion?DocNum=${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + items?.token
                }
            }).then(res => res.json())
                .then(response => {
                    if (response?.length > 0) {


                        setIsOkayFetchGetProforma(true);
                        let lastBill = response[0];
                        //console.log('RESPONSE DEL getProforma en el billCreate lastBill',lastBill);
                        setDueDate(lastBill.Fecha_Hora);

                        let auxRowsData = [];

                        let myHeaders = new Headers();
                        myHeaders.append("Authorization", 'Bearer ' + items?.token);

                        let requestOptions = {
                            method: 'GET',
                            headers: myHeaders,
                            redirect: 'follow'
                        };

                        fetch(`${process.env.REACT_APP_API_URL}bp/ConsultaSocioNegocio_por_CardCode_Facturacion?CardCode=${lastBill.CardCode}`, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                if (result?.length > 0) {

                                    let clientInfo = result[0];
                                    //console.log('RESPONSE DEL getProforma en el billCreate clientInfo', clientInfo);
                                    setClientName(lastBill.CardName);
                                    setClientAvailable(parseInt(clientInfo.Disponible));
                                    setClientBalance(parseInt(clientInfo.SaldoCuenta));
                                    setClientPhone(clientInfo.Telefono);
                                    setClientAuthorized(clientInfo.Autorizados);
                                    setClientContado(clientInfo.ClienteContado);
                                    setClientCode(lastBill.CardCode);
                                    setClientIdCard(lastBill.U_NVT_Identificador_Fiscal);
                                    setClientEmail(clientInfo.Email);
                                    setComments(lastBill.Comments);
                                    setOtherComments(lastBill.OtrosComments);
                                    setVendorId(lastBill.SlpCode);
                                    setSelectSearchValue({ value: lastBill.CardCode, label: lastBill.CardCode + " - " + lastBill.CardName });
                                    setElectronicTicket(clientInfo.EsTiqueteElectronico);
                                    setIdentificationType(clientInfo.TipoIdentificacion);

                                    if(clientInfo.EsTiqueteElectronico == "S") { setEBill(false); }
                                };
                            })
                            .catch(error => showErrorMessage(error + '/' + items?.token));


                        lastBill?.DocumentLines?.forEach(element => {

                            let discount = element.DiscPrcnt === "" ? 0 : parseFloat(element.DiscPrcnt) / 100;

                            let priceWithDiscount = parseFloat(element.Price) - (parseFloat(element.Price) * discount);

                            let iva = parseFloat(element.TaxPercentagePerRow === "" ? 0 : element.TaxPercentagePerRow) / 100;

                            let priceWithIva = priceWithDiscount * iva;

                            let priceDiscountIva = priceWithDiscount + priceWithIva;

                            let quantity = element.Quantity === "" ? 0 : parseFloat(element.Quantity);

                            let totalPrice = priceDiscountIva * quantity;

                            auxRowsData.push({
                                DATA: {
                                    Almacen: element.WarehouseCode,
                                    Articulo: element.Articulo,
                                    CodigoImpuesto: element.TaxCode,
                                    Disponible: element.U_NVT_DisponibleReal,
                                    ItemCode: element.ItemCode,
                                    ItemName: element.Dscription,
                                    PorcentajeImpuesto: element.TaxPercentagePerRow,
                                    Precio: element.Price,
                                },
                                CHECK: false,
                                ID: '',
                                ARTICULO_DESCRIPCION: element.Articulo,
                                STOCK: element.U_NVT_DisponibleReal,
                                CANTIDAD: element.Quantity,
                                PRECIO: element.Price,
                                DESCUENTO: element.DiscPrcnt,
                                PRECIO_CON_DESCUENTO: priceWithDiscount,
                                PRECIO_TOTAL_IVI: totalPrice,
                                CODIGO_DE_IMPUESTO: element.TaxCode,
                                PORCENTAJE_DE_IMPUESTO: element.TaxPercentagePerRow,
                                ALMACEN: element.WarehouseCode,
                                PRECIO_MODIFICADO: false,
                                PRECIO_FACTURADO: 0
                            });

                        });

                        if (changedPriceList.current != []) {
                            auxRowsData = attachPreviousPrice(auxRowsData);
                        }

                        setRowsData(auxRowsData);
                        //setOtherComments();
                    };

                    setProcessingStatus(false);
                })
                .catch(error => { setProcessingStatus(false); console.error('Error:', error); });

        }, 1300);

        await getPriceChangeQuotation(id);
    };

    const displayVendors = vendorList !== null && Array.isArray(vendorList) ? vendorList.map((vendor) => {
        return (
            <option key={vendor.Codigo} value={vendor.Codigo} style={{ fontSize: "1vw" }}>{vendor.Nombre}</option>
        );
    }) : <></>;

    const buttonAction = () => {
        if (id) {
            if (duplicate)
                return <Button className="button boldText btn" onClick={() => { setProcessingStatus(true); verification("create") }}>Crear</Button>;
            return <Button className="button boldText btn" onClick={() => { setProcessingStatus(true); verification("update") }}>Editar</Button>;
        }
        else {
            return <Button className="button boldText btn" onClick={() => { setProcessingStatus(true); verification("create") }}>Crear</Button>;
        }
    }

    const firstTopInputs = () => {

        if (id) {

            if (duplicate) {

                return <div className="container-first-inputs">

                    <label>Búsqueda</label>
                    <Select className="selectSearch" value={selectSearchValue} options={clientsList}
                        onInputChange={(e) => setClientsByCardCode(e)}
                        filterOption={(e)=>{return true;}}
                        onChange={(e) => { clientInfoBySearch(e.client); setSelectSearchValue({ value: e.value, label: e.label }) }} />
                    <label>Nombre</label>
                    <input className="createUserInformationInputs" defaultValue={clientName} onChange={(e) => { setClientName(e.target.value); }}></input>

                </div>

            } else {

                return <div className="container-first-inputs">

                    <label>Búsqueda</label>
                    <Select className="selectSearch" value={selectSearchValue} options={clientsList}
                        onInputChange={(e) => setClientsByCardCode(e)}
                        filterOption={(e)=>{return true;}}
                        onChange={(e) => { clientInfoBySearch(e.client); setSelectSearchValue({ value: e.value, label: e.label }) }} isOptionDisabled={(option) => true} isDisabled={true} />
                    <label>Nombre</label>
                    <input className="createUserInformationDisabledInputs" defaultValue={clientName} disabled></input>

                </div>

            };

        } else {

            return <div className="container-first-inputs">

                <label>Búsqueda</label>
                <Select className="selectSearch" value={selectSearchValue} options={clientsList}
                    onInputChange={(e) => setClientsByCardCode(e)}
                    filterOption={(e)=>{return true;}}
                    onChange={(e) => { clientInfoBySearch(e.client); setSelectSearchValue({ value: e.value, label: e.label }) }} />
                <label>Nombre</label>
                <input className="createUserInformationInputs" defaultValue={clientName} onChange={(e) => { setClientName(e.target.value); }}></input>
            </div>

        };

    };

    const secondTopInputs = () => {

        if (id) {

            if (duplicate) {

                return <div>

                    <label>Cédula</label>
                    <input className="createUserInformationInputs" defaultValue={clientIdCard} onChange={(e) => { setClientIdCard(e.target.value) }} onKeyDown={handleKeyDown} onBlur={handleBlur}></input>
                    <label>Código</label>
                    <input className="createUserInformationDisabledInputs" defaultValue={clientCode} disabled></input>

                </div>

            } else {

                return <div>

                    <label>Cédula</label>
                    <input className="createUserInformationDisabledInputs" defaultValue={clientIdCard} disabled></input>
                    <label>Código</label>
                    <input className="createUserInformationDisabledInputs" defaultValue={clientCode} disabled></input>

                </div>

            };

        } else {

            return <div>

                <label>Cédula</label>
                {
                    clientContado === "N" ?
                        <input className="createUserInformationInputs" defaultValue={clientIdCard} disabled></input>
                        :
                        <input className="createUserInformationInputs" defaultValue={clientIdCard} onChange={(e) => { setClientIdCard(e.target.value) }} onKeyDown={handleKeyDown} onBlur={handleBlur}></input>
                }
                <label>Código</label>
                <input className="createUserInformationDisabledInputs" defaultValue={clientCode} disabled></input>

            </div>

        };

    };

    const thirdTopInputs = () => {

        if (id) {

            if (duplicate) {

                return <div>

                    <label>Fecha</label>
                    <DatePicker value={date} className="datePicker createUserInformationDisabledInputs" disabled />
                    <label>Correo electrónico</label>
                    <input className="createUserInformationInputs" defaultValue={clientEmail} onChange={(e) => { setClientEmail(e.target.value); }}></input>

                </div>

            } else {

                return <div>

                    <label>Fecha</label>
                    <DatePicker value={date} className="datePicker createUserInformationDisabledInputs" disabled />
                    <label>Correo electrónico</label>
                    <input className="createUserInformationDisabledInputs" defaultValue={clientEmail} disabled ></input>

                </div>

            };

        } else {

            return <div>

                <label>Fecha</label>
                <DatePicker value={date} className="datePicker createUserInformationDisabledInputs" disabled />
                <label>Correo electrónico</label>
                <input className="createUserInformationInputs" defaultValue={clientEmail} onChange={(e) => { setClientEmail(e.target.value); }}></input>

            </div>

        };

    };

    return (

        <div className="divPrincipal">
            <Sidebar sidebarClass={sidebarClass} handleViewSidebar={handleViewSidebar}
                billCreateSelected={billCreateSelected} sidebarToggleClass={sidebarToggleClass} />
            <div className={billCreateClass}>
                <Header></Header>
                <div>
                    <p className="boldText pagesTitle">Creación de facturas</p>
                </div>
                <div className="divHeader">
                    <div className="col-sm createUserinformationAlign createrUserInformationMargin">
                        {firstTopInputs()}
                    </div>
                    <div className="col-sm createUserinformationAlign">
                        {secondTopInputs()}
                    </div>
                    <div className="col-sm createUserinformationAlign">
                        {thirdTopInputs()}
                    </div>
                    <div className="verticalLine" />
                    <div className="col-sm">
                        <label>Información del cliente</label>
                        <label>Disponible: <CurrencyInput placeholder="0" type="text" name="available" className="appearance" value={clientAvailable} disabled /></label>
                        <label>Balance: <CurrencyInput placeholder="0" type="text" name="available" className="appearance" value={clientBalance} disabled /></label>
                        <label>Teléfono: {clientPhone}</label>
                        <label>Email: {clientEmail}</label>
                        <label>Autorizados: {clientAuthorized}</label>
                        <div className="row">
                            <div className="col d-flex">
                                <label className="boldText labelCheck">Factura electrónica</label>

                                {
                                    electronicTicket != "S"?
                                        clientContado == "N"?
                                            <input className="" type="checkbox" onChange={(e) => { setEBill(!eBill) }} /*defaultChecked={ eBill }*/ checked={eBill} disabled></input>
                                        :
                                            <input className="" type="checkbox" onChange={(e) => { setEBill(!eBill) }} /*defaultChecked={ eBill }*/ checked={eBill} disabled></input>
                                    :

                                        <input className="" type="checkbox" onChange={(e) => { setEBill(!eBill) }} /*defaultChecked={ eBill }*/ checked={eBill} disabled></input>
                                }   

                            </div>
                        </div>
                    </div>
                </div>

                <hr />

                <div className="row card">
                    <div className="col-md-12 column rowTable">
                        <table
                            className="table table-bordered table-hover"
                            id="tab_logic">
                            <thead>
                                <tr>
                                    <th><input className="form-check-input mx-2" type="checkbox" checked={selectAll} onChange={selectAllTable}></input></th>
                                    <th><label className="thNumberWidth">#</label></th>
                                    <th><div>Artículo - Descripción</div></th>
                                    <th><div className="thStockWidth">Stock</div></th>
                                    <th><div className="thQuantityWidth">Cantidad</div></th>
                                    <th><div className="thPriceWidth">Precio</div></th>
                                    <th><div className="thDiscountWidth">Descuento</div></th>
                                    <th><div className="thPriceWidth">Precio con desc</div></th>
                                    <th><div className="thPriceWidth">Precio total I.V.I</div></th>
                                    <th><div className="thWarehouseWidth">Almacén</div></th>
                                    <th><div className="thTaxCodeWidth">Cod. Imp</div></th>
                                    <th><div className="thTaxRateWidth">% Imp</div></th>
                                    <th><div>Acción</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr className="text-left">
                                        <th><span className="text-left">Loading...</span></th>
                                    </tr>
                                ) : (
                                    <TableRows clientCode={clientCode} rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} taxBlur={taxBlur} storesEdit={storesEdit} taxEdit={taxEdit} priceEdit={priceEdit} idRol={idRol}
                                        callback={(rowTable, warehouse, articleCode, stock) => { editWarehouseStock(rowTable, warehouse, articleCode, stock) }} />
                                )}
                            </tbody>
                        </table>
                    </div>
                    <hr />
                    <div className="divBtns">
                        <button data-tip="Agregar"
                            data-for='agregar'
                            onClick={addTableRows}
                            className="btn btn-primary"
                            disabled={clientCode == '' ? true : false}>
                            <BsPlusCircleFill className="icon" />
                            {/* <ReactTooltip id="agregar" wrapper /> */}
                        </button>

                        <button data-tip="Borrar"
                            data-for='borrar'
                            onClick={deleteTableSelects}
                            disabled={clientCode == '' ? true : false}
                            className="btn btn-danger float-right">
                            <BsTrash className="icon" />
                            {/* <ReactTooltip id="borrar" /> */}
                        </button>
                        {rollback.length > 0 ? (
                            <button data-tip="Deshacer"
                                data-for='deshacer'
                                onClick={() => {
                                    setRowsData(rollback);
                                    setRollback([]);
                                    setUpdated(!updated);
                                }}
                                className="btn btn-danger float-right">
                                <RiArrowGoBackFill className="icon" />
                                {/* <ReactTooltip id="deshacer" /> */}
                            </button>
                        ) : (
                            ''
                        )}
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-4">
                        <label className="boldText">Vendedor</label>

                        <select className="selectFields fullSize" aria-label="size 3 select example" value={vendorId} onChange={(e) => setVendorId(e.target.value)}>
                            {displayVendors}
                        </select>
                    </div>
                    <div className="col-sm-8">
                        <div className="row justify-content-end">
                            <div className="col-sm-6">
                                <label className="boldText">Comentarios</label>
                                <textarea className="fullSize text" value={comments} onChange={(e) => setComments(e.target.value)}></textarea>
                            </div>
                            {userDataLocalStorage.userRol.OtrosComentarios == "Y" &&
                                <div className="col-sm-6">
                                    <label className="boldText">Otros comentarios</label>
                                    <textarea className="fullSize text" value={otherComments} onChange={(e) => setOtherComments(e.target.value)}></textarea>
                                </div>
                            }
                        </div>
                    </div>

                </div>

                <div className="row">

                    <div className="col">

                        <div className="details">
                            <div className="detail">
                                <label className="detailtext">Total sin impuestos</label>
                                <input className="pricesTextAlign" value={totalSinImpuestos} disabled></input>
                            </div>
                            <div className="detail">
                                <label className="detailtext">Descuento</label>
                                <input className="pricesTextAlign" value={descuento} disabled></input>
                            </div>
                            <div className="detail">
                                <label className="detailtext">Impuesto</label>
                                <input className="pricesTextAlign" value={impuesto} disabled></input>
                            </div>
                            <div className="detail">
                                <label className="detailtext">Total</label>
                                <input className="pricesTextAlign" value={total.toLocaleString("en-US")} disabled></input>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="divBtns createButtons">
                    <Button className="button btn" onClick={() => { setValidationStatus(true) }}><FaKey /></Button>
                    <Button className="button boldText btn" onClick={() => { cancelWarning(); }}>Cancelar</Button>
                    {buttonAction()}
                </div>

                <div>
                    {
                        processingStatus ?

                            <div className="modalOverlay ">
                                <div>

                                    <p className="boldText processing">Procesando</p>

                                    <div className="spinner-border text-light procecessingSpinner" role="status">

                                    </div>

                                </div>

                            </div>
                            :
                            <></>

                    }

                    {
                        validationStatus ?

                            <Validation setValidationStatus={setValidationStatus} societyId={logedUser.societyId} setAuthorizationUser={setAuthorizationUser}
                                callback={(idRol, allowsEditing, allowsTaxEditing, allowsPriceEditing) => {
                                    setIdRol(idRol);
                                    if (allowsEditing == 'Y') { setStoresEdit(true); }
                                    if (allowsTaxEditing == 'Y') { setTaxEdit(true); }
                                    if (allowsPriceEditing == 'Y') { setPriceEdit(true); }
                                }}></Validation>
                            :
                            <></>

                    }

                </div>
                <Footer></Footer>
            </div>
        </div >
    );
};
export default BillCreate;
