import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/sidebar";
import Header from "../components/header";
import Footer from "../components/footer";
import Box from "../components/box";
import Text from "../components/text";
import Button from "../components/button";
import ReactPaginate from "react-paginate";
import Modal from '../components/modal';
import Swal from 'sweetalert2';
import { getRoles } from '@testing-library/react';
import CryptoJS from 'crypto-js';

const Stores = ({
    storeClass,
    sidebarClass,
    sidebarToggleClass,
    handleViewSidebar,
    storeSelected
}) => {

    const [data, setData] = useState([]);
    const [stores, setStores] = useState([]);
    const [roles, setRoles] = useState([]);
    const [storesOptions, setStoresOptions] = useState();
    const [rolesOptions, setRolesOptions] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [modalState, setModalState] = useState(false);
    const [storeName, setStoreName] = useState("");
    const [idRol, setIdRol] = useState(0);
    const [idStore, setIdStore] = useState();
    const [inactiveStatus, setInactiveStatus] = useState("");
    const [modalContent, setModalContent] = useState();
    const [modalType, setModalType] = useState();
    const [creationStatus, setCreationStatus] = useState();
    const [warehouseCode, setWarehouseCode] = useState();

    let navigate = useNavigate();

    let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
    let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
    const userDataLocalStorage = JSON.parse(originalText);

    if(userDataLocalStorage?.userRol?.ModuloAdministracion != "Y"){ navigate("/factura/crear"); }
    if(userDataLocalStorage?.userRol < 0 || userDataLocalStorage == undefined || userDataLocalStorage == null || userDataLocalStorage == '{"username":"","database":""}'){ navigate("/"); }

    useEffect(() => {

        getStoresRoles();
        getStores();
        getRoles();
    }, [])

    useEffect(() => {

        const options = stores !== null && Array.isArray(stores) ? stores.map((store) => {
            return (
                <option key={store.WhsCode} value={store.WhsCode} style={{ fontSize: "1vw" }}>{store.WhsName}</option>
            );
        }) : <></>;

        setStoresOptions(options);

    }, [stores])

    useEffect(() => {

        const rolesOptions = roles !== null && Array.isArray(roles) ? roles.map((rol) => {
            return (
                <option key={rol.Id} value={rol.Id} style={{ fontSize: "1vw" }}>{rol.Nombre}</option>
            );
        }) : <></>;

        setRolesOptions(rolesOptions);

    }, [roles])

    useEffect(() => {
        if (modalType === "crear") {
            setModalContent(<Fragment>
                <Text className="boldText" color={"#808184"}
                    marginRight={0} padding={0} fontSize={1}
                    marginLeft={"23%"} marginTop={2} marginBottom={1}
                >Nombre de almacen</Text>

                <select className="selectFields" aria-label="size 3 select example" onChange={(e) => {setWarehouseCode(e.target.value); setStoreName(e.target.selectedOptions[0].innerText);}}>
                    <option value={0} style={{ fontSize: "1vw" }}>Seleccione...</option>
                    {storesOptions}
                </select>

                <Text
                    className="boldText" color={"#808184"}
                    marginRight={0} padding={0} fontSize={1}
                    marginLeft={"23%"} marginTop={2} marginBottom={1}
                >Rol</Text>
                <select className="selectFields" aria-label="size 3 select example" onChange={(e) => setIdRol(e.target.value)}>
                    <option value={0} style={{ fontSize: "1vw" }}>Seleccione...</option>
                    {rolesOptions}
                </select>

                <div className='modalBtnsBox'>
                    <button className="boldText btnModal cancelBtn"
                        onClick={() => setModalType("")}>Cancelar</button>
                    <button className="boldText btnModal submitBtn"
                        onClick={() => { createStoreRol() }}>Crear</button>
                </div>
            </Fragment>);

            setModalState(true);

        } else if (modalType === "eliminar") {
            
            inactiveWarning();

        } else if (modalType === "") {

            setWarehouseCode("");
            setStoreName("");
            setIdRol(0);
            setModalState(false);

        };

    }, [modalType, storeName, idRol])

    useEffect(() => {

        if(creationStatus === "Ok"){
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })
              
              Toast.fire({
                icon: 'success',
                title: 'Almacen creado correctamente'
              })

              setCreationStatus("");
              getStoresRoles();
        };

    },[creationStatus])

    useEffect(() => {

        if (inactiveStatus === "Ok") {

            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: 'Usuario eliminado correctamente'
            })

            setInactiveStatus("");
            getStoresRoles();
        };

    }, [inactiveStatus])

    const dataPerPage = 10;
    const pagesVisited = pageNumber * dataPerPage;

    const displayData = data !== null && Array.isArray(data) ? data.slice(pagesVisited, pagesVisited + dataPerPage)
        .map((data) => {
            return (
                <Box key={data.Id} className="filters" width={"95%"} marginLeft={"2.5%"} marginTop={2}
                    marginRight={0} marginBottom={0} minHeight={7}
                    backgroundColor="#FFFFFF">

                    <Text className="text" color={"#000000"}
                        marginRight={0} padding={0} fontSize={1}
                        marginLeft={"2%"} marginTop={0} marginBottom={0}
                        width={"30%"}
                    >{data.NombreAlmacen}</Text>

                    <Text className="text" color={"#000000"}
                        marginRight={0} padding={0} fontSize={1}
                        marginLeft={"0%"} marginTop={0} marginBottom={0}
                        width={"55%"}
                    >{data.NombreRol}</Text>


                    <button className="resultBtn delete boldText"
                        onClick={() => { setIdStore(data.Id); setModalType("eliminar") }}>Eliminar</button>

                </Box>
            );
        }) : <></>;

    const pageCount = Math.ceil(data.length / dataPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected)
    };

    const showErrorMessage = (errorMessage) => {

        if(errorMessage !== null && errorMessage !== ""){
            Swal.fire({
                title: 'Error!',
                text: errorMessage,
                icon: 'error',
                confirmButtonText: 'Aceptar'
            })
        };

    };

    const inactiveWarning = () => {

        Swal.fire({
            title: '¡Advertencia!',
            text: "¿Está seguro de querer eliminar este almacen?",
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Aceptar',
            denyButtonText: 'Cancelar'

        }).then((result) => {

            if (result.isConfirmed) {

                inactiveStore();
            } else if (result.isDenied) {
                setModalType("");
                setStoreName("");
                setIdRol(0);
                setIdStore();
            }
        });

    };

    const getStoresRoles = async() => {

        let securityToken = "";

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        if (items) {
            securityToken = items.token;
        };

        let myHeaders = new Headers();
        myHeaders.append("Authorization", 'Bearer ' + securityToken);

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        await fetch(`${process.env.REACT_APP_API_URL}admin/ListarAlmacenes_Roles`, requestOptions)
            .then(response => response.text())
            .then(result => {setData(JSON.parse(result)); /*console.log('ListarAlmacenes_Roles', JSON.parse(result));*/ })
            .catch(error => showErrorMessage(error));

    };

    const getRoles = async() => {

        let securityToken = "";

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        if (items) {
            securityToken = items.token;
        };

        let myHeaders = new Headers();
        myHeaders.append("Authorization", 'Bearer ' + securityToken);

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        await fetch(`${process.env.REACT_APP_API_URL}admin/ListarRoles`, requestOptions)
            .then(response => response.text())
            .then(result => {setRoles(JSON.parse(result)); /*console.log('ListarRoles', JSON.parse(result));*/ })
            .catch(error => showErrorMessage(error));

    };

    const getStores = async() => {

        let securityToken = "";

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        if (items) {
            securityToken = items.token;
        };

        let myHeaders = new Headers();
        myHeaders.append("Authorization", 'Bearer ' + securityToken);

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        await fetch(`${process.env.REACT_APP_API_URL}admin/ListarAlmacenes`, requestOptions)
            .then(response => response.text())
            .then(result => {setStores(JSON.parse(result)); /*console.log('ListarAlmacenes', JSON.parse(result));*/})
            .catch(error => showErrorMessage(error));

    };

    const createStoreRol = async () => {

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        let myHeaders = new Headers();
        myHeaders.append("Authorization", 'Bearer ' + items.token);
        myHeaders.append("Content-Type", "application/json");

        if (warehouseCode !== "" && idRol !== 0) {
            let raw = JSON.stringify({
                "CodigoAlmacen": warehouseCode,
                "NombreAlmacen": storeName,
                "IdRol": idRol,
                "Activo": 1
            });

            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            await fetch(`${process.env.REACT_APP_API_URL}admin/InsertarAlmacenes_Roles`, requestOptions)
                .then(response => response.text())
                .then(result => setCreationStatus(JSON.parse(result)))
                .catch(error => showErrorMessage(error));


            setModalType("");
            setStoreName("");
            setWarehouseCode("");
            setIdRol(0);
        };

    };

    const inactiveStore = async () => {

        let myHeaders = new Headers();

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        myHeaders.append("Authorization", 'Bearer ' + items.token);
        myHeaders.append("Content-Type", "application/json");

        let raw = JSON.stringify({
            "Id": idStore,
            "Activo": 0
        });

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        await fetch(`${process.env.REACT_APP_API_URL}admin/Inactivar_Activar_Almacenes`, requestOptions)
            .then(response => response.text())
            .then(result => setInactiveStatus(JSON.parse(result)))
            .catch(error => showErrorMessage(error));

            setModalType("");
            setStoreName("");
            setWarehouseCode("");
            setIdRol(0);
            setIdStore();

    };

    return (
        <div style={{ display: "flex" }}>
            <Sidebar sidebarClass={sidebarClass} handleViewSidebar={handleViewSidebar}
                storeSelected={storeSelected} sidebarToggleClass={sidebarToggleClass} />

            <div className={storeClass}>
                <Header></Header>

                <Text className="boldText" color="#000000" fontSize={1.5}
                    marginRight={0} padding={0}
                    marginLeft={"2.5%"} marginTop={4} marginBottom={0}>Listado de almacenes por roles</Text>

                <button className="addStoreBtn boldText"
                    onClick={() => { setModalType("crear") }}>Asignar un almacen a un rol</button>

                <ReactPaginate
                    previousLabel={"Anterior"}
                    nextLabel={"Siguiente"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBtns"}
                    previousLinkClassName={"previousBtn"}
                    nextLinkClassName={"nextBtn"}
                    disabledClassName={"paginationDisable"}
                    activeClassName={"paginationActive"}
                />

                <div className="resultsTitles">

                    <Text className="boldText" color={"#808184"}
                        marginRight={0} padding={0} fontSize={1} width={"28.5%"}
                        marginLeft={"4.5%"} marginTop={3} marginBottom={1}>WHCODE</Text>


                    <Text className="boldText" color={"#808184"}
                        marginRight={0} padding={0} fontSize={1}
                        marginLeft={"0%"} marginTop={3} marginBottom={1}>ROLENAME</Text>

                </div>

                <div className="storesResults" style={{ marginBottom: "8vh" }}>

                    {displayData}

                </div>

                <Modal className={"createInputs"} title={"Crear"} state={modalState} changeState={setModalState} modalType={setModalType}>

                    {modalContent}

                </Modal>

                <Footer></Footer>
            </div>

        </div>
    );

};

export default Stores;