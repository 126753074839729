import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../components/sidebar";
import Header from "../components/header";
import Footer from "../components/footer";
import Button from "../components/button";
import { BsTrash, BsPlusCircleFill, BsBorderRight } from "react-icons/bs"
import { RiArrowGoBackFill } from "react-icons/ri"
import { useNavigate, useParams } from "react-router-dom";
import { FaKey } from "react-icons/fa";
import Swal from 'sweetalert2';
import Select from 'react-select';
import DatePicker from 'react-date-picker';
import TableRows from "../components/tableRows";
import ReactTooltip from 'react-tooltip';
import Validation from "../components/validationAccess";
import { parse } from "uuid";
import CryptoJS from 'crypto-js';
import CurrencyInput from '../components/CurrencyInput';

const ProformaCreate = ({
    proformaCreateClass,
    sidebarClass,
    sidebarToggleClass,
    handleViewSidebar,
    proformaCreateSelected
    
}) => {

    const addZero = (num) => {
        return (num >= 0 && num < 10) ? "0" + num : num + "";
    }

    const formatDate = () => {
        let documentDatetime = new Date();
        let strDateTime = [
            [documentDatetime.getFullYear(),
                addZero(documentDatetime.getMonth() + 1),
            addZero(documentDatetime.getDate())
            ].join("-"),
            [addZero(documentDatetime.getHours()),
            addZero(documentDatetime.getMinutes()),
            addZero(documentDatetime.getSeconds()) + "." + addZero(documentDatetime.getMilliseconds())].join(":")].join(" ");

        return strDateTime;
    }

    const [clientsResult, setClientsResult] = useState([]);
    const [clientsList, setClientsList] = useState([]);
    const [clientsByCardCode, setClientsByCardCode] = useState("");
    const [vendorList, setVendorList] = useState();
    const [vendorId, setVendorId] = useState("");
    const [selectSearchValue, setSelectSearchValue] = useState({ value: 'Seleccione', label: 'Seleccione' });
    const [dueDate, setDueDate] = useState(formatDate());
    const [date, setDate] = useState(formatDate());

    //STATES: For New table and table details
    const [selectAll, setSelectAll] = useState(false);
    const [rowsData, setRowsData] = useState([]);
    const [rollback, setRollback] = useState([]);
    const [updated, setUpdated] = useState(false);
    const [loading, setloading] = useState(false);
    //END New table and table details

    //New states for clientInfo
    const [clientName, setClientName] = useState("");
    const [clientIdCard, setClientIdCard] = useState("");
    const [clientCode, setClientCode] = useState("");
    const [clientEmail, setClientEmail] = useState("");
    const [clientAvailable, setClientAvailable] = useState(0);
    const [clientBalance, setClientBalance] = useState(0);
    const [clientPhone, setClientPhone] = useState("");
    const [clientAuthorized, setClientAuthorized] = useState("");
    const [clientContado, setClientContado] = useState("N");
    const [comments, setComments] = useState("");
    const [identificationType, setIdentificationType] = useState("");
    const [otherComments, setOtherComments] = useState("");
    //End of new states for clientInfo

    const [totalSinImpuestos, setTotalSinImpuestos] = useState(0);
    const [descuento, setDescuento] = useState(0);
    const [impuesto, setImpuesto] = useState(0);
    const [total, setTotal] = useState(0);

    const [creationStatus, setCreationStatus] = useState("");
    const [updateStatus, setUpdateStatus] = useState("");
    const [totalStatus, setTotalStatus] = useState(false);
    const [processingStatus, setProcessingStatus] = useState(false);
    const [validationStatus, setValidationStatus] = useState(false);

    const [storesEdit, setStoresEdit] = useState(true);
    const [taxEdit, setTaxEdit] = useState(false);
    const [priceEdit, setPriceEdit] = useState(false);

    const [logedUser, setLogedUser] = useState();
    const [authorizationUser, setAuthorizationUser] = useState();

    const [docEntry, setDocEntry] = useState("");

    
    //const [idRol, setIdRol] = useState(1);

    const { id, duplicate } = useParams();

    const [isOkayFetchGetBill, setIsOkayFetchGetBill] = useState(false);
    let intervalGetBill = useRef(null);

    let count = useRef(0);

    let navigate = useNavigate();

    const [taxError, setTaxError] = useState(false);
    let changedPriceList = useRef([]);

    let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
    let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
    const userDataLocalStorage = JSON.parse(originalText);

    const [idRol, setIdRol] = useState(JSON.parse(originalText).userRol.Id);

    if(userDataLocalStorage?.userRol < 0 || userDataLocalStorage?.userRol?.ModuloProformas != "Y" || userDataLocalStorage == undefined || userDataLocalStorage == null || userDataLocalStorage == '{"username":"","database":""}'){ navigate("/"); }

    useEffect(() => {
        if (id && count.current == 0) {

            count.current = 1;
            setProcessingStatus(true);

            setTimeout(() => {

                if (duplicate !== undefined) {
                    if (duplicate === "true") {
                        getBillDuplicate(id);

                    } else {
                        getBill(id);
                    };
                } else {
                    getBill(id);
                }
            }, 500);
        }
    }, [id])

    useEffect(() => {
        setloading(true);

        setTimeout(() => {
            setloading(false)
        }, 100)
    }, [rollback]);

    useEffect(() => {
        getVendors();

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const userInfo = JSON.parse(originalText);

        if(userInfo){

            setLogedUser(userInfo);

        };

        addTableRows();

    }, [])

    useEffect(() => {
        if (clientsResult !== null && Array.isArray(clientsResult)) {
            let list = [];
            for (let i = 0; i < clientsResult.length; i++) {
                let client = { value: clientsResult[i].CardCode, label: clientsResult[i].Cliente, client: clientsResult[i] };
                list.push(client);
            };
            setClientsList(list);
        };
    }, [clientsResult])


    useEffect(() => {

        if (clientsByCardCode === "") {
            setClientsList([]);
        } else {

            getClients(clientsByCardCode);

        };

    }, [clientsByCardCode])

    useEffect(() => {

        let totalSinImpuestosTemporal = 0;
        let descuentoTemporal = 0;
        let impuestoTeamporal = 0;
        let totalTemporal = 0;

        if (rowsData.length > 0) {

            rowsData.forEach(element => {

                let sinImpuesto = element.PRECIO_CON_DESCUENTO * parseFloat(element.CANTIDAD === "" ? 0 : element.CANTIDAD);
                totalSinImpuestosTemporal = totalSinImpuestosTemporal + sinImpuesto;

                let pasarDescuento = parseFloat(element.DESCUENTO === "" ? 0 : element.DESCUENTO) / 100;
                let descuento = element.PRECIO * pasarDescuento;
                let totalDescuento = descuento * parseFloat(element.CANTIDAD === "" ? 0 : element.CANTIDAD);
                descuentoTemporal = descuentoTemporal + totalDescuento;

                let impuesto = parseFloat(element.PORCENTAJE_DE_IMPUESTO) / 100;
                let descuentoImpuesto = element.PRECIO_CON_DESCUENTO * impuesto;
                let totalDescuentoImpuesto = descuentoImpuesto * parseFloat(element.CANTIDAD === "" ? 0 : element.CANTIDAD);
                impuestoTeamporal = impuestoTeamporal + totalDescuentoImpuesto;


                totalTemporal = totalTemporal + element.PRECIO_TOTAL_IVI;

            });

        };

        setTotalSinImpuestos(totalSinImpuestosTemporal.toFixed(2).toLocaleString("en-US"));
        setDescuento(descuentoTemporal.toFixed(2).toLocaleString("en-US"));
        setImpuesto(impuestoTeamporal.toFixed(2).toLocaleString("en-US"));
        setTotal(parseFloat(totalTemporal.toFixed(2)));

    }, [rowsData])

    useEffect(() => {

        setTimeout(() => {

            if (creationStatus.DocNum !== "-1" && creationStatus.DocNum !== undefined) {

                setProcessingStatus(false);

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
    
                Toast.fire({
                    icon: 'success',
                    title: `Factura creada correctamente. Doc Num: ${creationStatus.DocNum}`
                })
    
            } else {
                console.log(creationStatus);
            }
        }, 1500)

    }, [creationStatus])

    useEffect(() => {

        setTimeout(() => {

            if (updateStatus.DocNum !== "-1" && updateStatus.DocNum !== undefined) {

                setProcessingStatus(false);

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
    
                Toast.fire({
                    icon: 'success',
                    title: `Factura actualizada correctamente. Doc Num: ${updateStatus.DocNum}`
                })
    
            } else {
                console.log(updateStatus);
            };
        }, 1500)

    }, [updateStatus])

    useEffect(() => {

        if(isOkayFetchGetBill){
            setIsOkayFetchGetBill(false);
            return ()=>clearInterval(intervalGetBill.current);
        }

    }, [isOkayFetchGetBill])

    //FUNCTIONS: For New table and table details
    const addTableRows = () => {
        setSelectAll(false);

        if (rowsData.length == 0 || rowsData[rowsData.length - 1]['ARTICULO_DESCRIPCION'] !== "" || rowsData[rowsData.length - 1]['VALIDO']) {
            const rowsInput = {
                DATA: null,
                CHECK: false,
                ID: '',
                ARTICULO_DESCRIPCION: '',
                STOCK: 0,
                CANTIDAD: 0,
                PRECIO: 0,
                DESCUENTO: 0,
                PRECIO_CON_DESCUENTO: 0,
                PRECIO_TOTAL_IVI: 0,
                CODIGO_DE_IMPUESTO: 0,
                PORCENTAJE_DE_IMPUESTO: 0,
                ALMACEN: '',
                VALIDO: false,
                PRECIO_MODIFICADO: false,
                PRECIO_FACTURADO: 0,
                SERVICIO: false
            }

            setRowsData([...rowsData, rowsInput])
        } else {
            Swal.fire({
                title: 'Advertencia!',
                html: 'Debe completar toda la información del último producto para poder agregar más',
                icon: 'warning',
                confirmButtonText: 'Aceptar'
            });
        }
    }

    const deleteTableRows = (index) => {
        const rows = [...rowsData];
        setRollback(rowsData);
        rows.splice(index, 1);
        setRowsData(rows);
        setUpdated(!updated);
    };

    const handleChange = (index, evnt) => {

        const rowsInput = [...rowsData];
        if (evnt?.target) {
            let { name, value, checked } = evnt.target;

            if (evnt.target.type == 'checkbox') {
                setSelectAll(false);
                rowsInput[index][name] = !!(checked);
            } else {
                rowsInput[index][name] = value;

                if (evnt.target.name == "ALMACEN") {
                    rowsInput[index]['DATA'].Almacen = value;
                }

                if (evnt.target.name == "PRECIO") {

                    if(Number.isNaN(value) || value == '' || value == ' '){
                        value = '0';
                    }
                    rowsInput[index]['DATA'].Precio = value.replace(/\,/g,'').replace(',', '');
                    rowsInput[index]['PRECIO'] = value.replace(/\,/g,'').replace(',', '');
                }

                if(evnt.target.name == "PORCENTAJE IMPUESTO"){

                    if(Number.isNaN(value) || value == '' || value == ' '){
                        value = '0';
                    }

                    rowsInput[index]['DATA'].PorcentajeImpuesto = value;
                    rowsInput[index]['PORCENTAJE_DE_IMPUESTO'] = value;
                }

                if(evnt.target.name == "CODIGO IMPUESTO"){

                    rowsInput[index]['DATA'].CodigoImpuesto = value;
                    rowsInput[index]['CODIGO_DE_IMPUESTO'] = value;
                }
            }

        } else { // TODO: Aquí es donde se debe poner la nueva validación de si es servicio o no
            const { value, label, data } = evnt;

            if (data?.Articulo) {

                rowsInput[index][value] = label;
                rowsInput[index]['DATA'] = data;

                rowsInput[index]['STOCK'] = data?.Disponible;

                rowsInput[index]['PRECIO'] = parseFloat(data?.Precio);

                rowsInput[index]['CODIGO_DE_IMPUESTO'] = data?.CodigoImpuesto;
                rowsInput[index]['PORCENTAJE_DE_IMPUESTO'] = data?.PorcentajeImpuesto;

                rowsInput[index]['ALMACEN'] = data?.Almacen;
                rowsInput[index]['ARTICULO_DESCRIPCION'] = data?.Articulo;

                if(data.Propiedad == "SERVICIOS"){
                    rowsInput[index]['SERVICIO'] = true;
                }
                
            } else {
                Swal.fire({
                    title: 'Advertencia!',
                    html: 'El producto seleccionado NO esta disponible para su venta. <br /><br />' +
                        '<strong> Contacte al administrador. </strong>',
                    icon: 'warning',
                    confirmButtonText: 'Aceptar'
                });
                rowsInput[index][value] = '';
                rowsInput[index]['DATA'] = '';
                rowsInput[index]['STOCK'] = 0;
                rowsInput[index]['PRECIO'] = 0;
                rowsInput[index]['CODIGO_DE_IMPUESTO'] = '';
                rowsInput[index]['PORCENTAJE_DE_IMPUESTO'] = 0;
                rowsInput[index]['ALMACEN'] = '';
                rowsInput[index]['PRECIO_CON_DESCUENTO'] = 0;
                rowsInput[index]['PRECIO_TOTAL_IVI'] = 0;
                rowsInput[index]['ARTICULO_DESCRIPCION'] = '';
                rowsInput[index]['CANTIDAD'] = 0;
                rowsInput[index]['DESCUENTO'] = 0;
                rowsInput[index]['VALIDO'] = false;
                rowsInput[index]['SERVICIO'] = false;
            }

        };

        let discount = rowsInput[index]['DESCUENTO'] === "" ? 0 : parseFloat(rowsInput[index]['DESCUENTO']) / 100;

        let priceWithDiscount = parseFloat(rowsInput[index]['PRECIO']) - (parseFloat(rowsInput[index]['PRECIO']) * discount);

        let iva = parseFloat(rowsInput[index]['PORCENTAJE_DE_IMPUESTO'] === "" ? 0 : rowsInput[index]['PORCENTAJE_DE_IMPUESTO']) / 100;

        let priceWithIva = priceWithDiscount * iva;

        let priceDiscountIva = priceWithDiscount + priceWithIva;

        let quantity = rowsInput[index]['CANTIDAD'] === "" ? 0 : parseFloat(rowsInput[index]['CANTIDAD']);

        let totalPrice = priceDiscountIva * quantity;

        rowsInput[index]['PRECIO_CON_DESCUENTO'] = priceWithDiscount;

        rowsInput[index]['PRECIO_TOTAL_IVI'] = totalPrice;

        rowsInput[index]['VALIDO'] = true;

        setRowsData(rowsInput);
    }

    const editWarehouseStock = (rowTable, warehouse, stock) => {

        const rowsInput = [...rowsData];

        rowsInput[rowTable]['STOCK'] = stock;
        rowsInput[rowTable]['DATA'].Disponible = stock;
        rowsInput[rowTable]['ALMACEN'] = warehouse;
        rowsInput[rowTable]['DATA'].Almacen = warehouse;
        setRowsData(rowsInput);
    }

    const selectAllTable = (evnt) => {
        setSelectAll(evnt?.target?.checked);
        const tempData = rowsData.map(el => ({ ...el, CHECK: evnt?.target?.checked }));
        setRowsData(tempData);
    };

    const deleteTableSelects = () => {
        setSelectAll(false);
        let filtered = rowsData.filter(function (value) {
            return value.CHECK == false;
        });

        setRollback(rowsData);
        setRowsData(filtered);
        setUpdated(!updated);
    };
    //ENDS: For New table and table details

    const handleKeyDown = (event) => {

        let value = event.target.value+""+event.key

        if (!validateNumbers(value) && event.key != 'Enter' && event.key != 'Del' && event.key != 'Tab' && event.key != 'Backspace' 
        && event.key != 'Control' && event.key != 'v' && event.key != 'c' && (event.keyCode < '36' || event.keyCode > '40')) {
            event.preventDefault()
        }

        if (event.key === 'Enter') {
            getUserInfoByIC();
        }

    };

    const handleBlur = (event) => {

        getUserInfoByIC();
    };

    const taxBlur = (percentage, index, state) => {
        
        setTaxError(state);

        if(!state){
            const rowsInput = [...rowsData];

            rowsInput[index]['PORCENTAJE_DE_IMPUESTO'] = percentage;
            let discount = rowsInput[index]['DESCUENTO'] === "" ? 0 : parseFloat(rowsInput[index]['DESCUENTO']) / 100;
            let priceWithDiscount = parseFloat(rowsInput[index]['PRECIO']) - (parseFloat(rowsInput[index]['PRECIO']) * discount);
            let iva = parseFloat(rowsInput[index]['PORCENTAJE_DE_IMPUESTO'] === "" ? 0 : rowsInput[index]['PORCENTAJE_DE_IMPUESTO']) / 100;
            let priceWithIva = priceWithDiscount * iva;
            let priceDiscountIva = priceWithDiscount + priceWithIva;
            let quantity = rowsInput[index]['CANTIDAD'] === "" ? 0 : parseFloat(rowsInput[index]['CANTIDAD']);
            let totalPrice = priceDiscountIva * quantity;
            rowsInput[index]['PRECIO_CON_DESCUENTO'] = priceWithDiscount;
            rowsInput[index]['PRECIO_TOTAL_IVI'] = totalPrice;
            rowsInput[index]['VALIDO'] = true;
    
            setRowsData(rowsInput);
        }
    }

    const validateNumbers = (values) => {

        let myReg = /^[0-9]+$/;
        let isNum = false;

        if (values.match(myReg)) {
            isNum = true;
        }

        return isNum;
    }

    const clientInfoBySearch = (clientInfo) => {

        setClientName(clientInfo.CardName);
        setClientIdCard(clientInfo.Cedula);
        setClientCode(clientInfo.CardCode);
        setClientEmail(clientInfo.Email);
        setClientAvailable(parseInt(clientInfo.Disponible));
        setClientBalance(parseInt(clientInfo.SaldoCuenta));
        setClientPhone(clientInfo.Telefono);
        setClientAuthorized(clientInfo.Autorizados);
        setClientContado(clientInfo.ClienteContado);

    };

    const clientInfoByIC = (clientInfo) => {

        setClientName(clientInfo.Nombre);
        setClientIdCard(clientInfo.Cedula);
        //setClientCode(clientInfo.CodigoCC);
        setClientEmail(clientInfo.Correo);
        setIdentificationType(clientInfo.TipoIdentificacion);
        setClientAvailable(0);
        setClientBalance(0);
        setClientPhone("");
        setClientAuthorized("");
        setClientContado("S");

    };

    const showErrorMessage = (errorMessage) => {

        let split = errorMessage.split('/');

        setProcessingStatus(false);

        if (errorMessage == "Token -1" || (split[1] && split[1] == "")) {
            Swal.fire({
                title: 'Se venció la sesión!',
                html: "Debes iniciar sesión nuevamente" + '<br/> serás redirigido automáticamente en 3 segundos...',
                timer: 3000,
                icon: 'error',
                showConfirmButton: false
            }).then((result) => {
                navigate("/");
            });
        } else if (errorMessage !== null && errorMessage !== "") {
            Swal.fire({
                title: 'Error!',
                text: errorMessage,
                icon: 'error',
                confirmButtonText: 'Aceptar',
            })
        };
    };

    const cancelWarning = () => {

        Swal.fire({
            title: '¡Advertencia!',
            text: "¿Está seguro de querer cancelar el progreso? Esta acción es irreversible.",
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Aceptar',
            denyButtonText: 'Cancelar'

        }).then((result) => {

            if (result.isConfirmed) {

                navigate(`/proforma/buscar`);
            } else if (result.isDenied) {

            }
        });

    };

    const verification = (type) => {

        let quantityLines = "";
        let index = 1;
        let errorMessage = "";
        // var discountLines = "";

        // if(rowsData !== null && Array.isArray(rowsData)){


        //     rowsData.map((row) => {

        //         if(parseFloat(row?.DESCUENTO) > logedUser?.userRol?.DescuentoPermitido){

        //             if(authorizationUser){

        //                 if(parseFloat(row?.DESCUENTO) >  authorizationUser?.userRol?.DescuentoPermitido){

        //                     discountLines = discountLines + index + ", ";

        //                 };

        //             }else{
        //                 discountLines = discountLines + index + ", ";
        //             };          

        //         };

        //         index = index +1;

        //     });

        // };

        // if(discountLines !== ""){

        //     errorMessage = "En la línea " + discountLines.slice(0, -2) + " el descuento es el mayor al permitido. ";

        // };
        //console.log('vendorId', vendorId);

        if (rowsData !== null && Array.isArray(rowsData)) {

            rowsData.map((row) => {

                if ((parseFloat(row?.CANTIDAD) == 0)) {
                    quantityLines = quantityLines + index + ", ";
                }

                index = index + 1;

            });

        };

        if (quantityLines !== "") {
            errorMessage = "En la línea " + quantityLines.slice(0, -2) + " la cantidad no puede ser 0. ";
        }

        if(quantityLines !== ""){

            setProcessingStatus(false);

            Swal.fire({
                title: 'Error!',
                text: errorMessage,
                icon: 'error',
                confirmButtonText: 'Aceptar',
            });
        } else if (vendorId == "") {

            setProcessingStatus(false);

            Swal.fire({
                title: 'Error!',
                text: "Debe seleccionar un vendedor",
                icon: 'error',
                confirmButtonText: 'Aceptar',
            })
        } else if(taxError){

            setProcessingStatus(false);

            Swal.fire({
                title: '!Error!',
                text: "Debe digitar un código de impuesto correcto",
                icon: 'error',
                confirmButtonText: 'Aceptar',
            })
        } else {

            if(type === "create"){
                createBill(); // TODO: descomentar
            }

            if(type === "update"){
                updateBill("edit"); // TODO: descomentar
            };
        }

        // if(discountLines){

        //     setProcessingStatus(false);

        //     Swal.fire({
        //         title: 'Error!',
        //         text: errorMessage,
        //         icon: 'error',
        //         confirmButtonText: 'Aceptar',
        //     })
        // }else{

            
            
        // };

    };

    const attachPreviousPrice = (documentLines) => {
        console.log('Método attachPreviousPrice ', changedPriceList.current);
        if(changedPriceList.current != null || changedPriceList.current != undefined){
            changedPriceList.current?.forEach(element => {

                let found = documentLines.find(line => line.DATA.ItemCode == element.ItemCode);
    
                found.PRECIO_MODIFICADO = true;
                found.PRECIO_FACTURADO = element.Precio;
            })
        }

        return documentLines;
    }

    const getPriceChange = async (docNum) => {

        let securityToken = "";
        let bytes = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        const items = JSON.parse(originalText);

        if (items && items.token) {

            securityToken = items.token;

            let myHeaders = new Headers();
            myHeaders.append("Authorization", 'Bearer ' + securityToken);

            let requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            await fetch(`${process.env.REACT_APP_API_URL}cotizacion/ConsultaArticulos_Precio_Diferente_Cotizacion?DocNum=${docNum}`, requestOptions)
                .then(response => response.text())
                .then(result => {
                    console.log('Resultado del ConsultaArticulos_Precio_Diferente_Cotizacion', JSON.parse(result));
                    if (JSON.parse(result) != -1) {
                        if(JSON.parse(result) != 0){
                            console.log('Entró a la validación');
                            changedPriceList.current = JSON.parse(result);

                            let postions = "";

                            changedPriceList.current.forEach((element) => {
                                
                                postions += (element.LineNum + 1) + ", "
                            })

                            Swal.fire({ 
                                title: '¡Advertencia!',
                                text: 'El precio de los artículos en las posiciones ' + postions + 'ha cambiado', 
                                icon: 'warning',
                                confirmButtonText: 'Aceptar',
                            })
                        }
                    } else {
                        showErrorMessage('Se produjo un error tratando de comprobar si cambiaron los precios de los artículos');
                    }
                })
                .catch(error => showErrorMessage(error + '/' + securityToken));
        } else {
            showErrorMessage("Token -1");
        }
    }

    const getUserInfoByIC = async () => {

        let securityToken = "";
        let societyId = 0;

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        if (items && items.token) {
            securityToken = items.token;
            societyId = items.societyId

            let myHeaders = new Headers();
            myHeaders.append("Authorization", 'Bearer ' + securityToken);

            let requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            await fetch(`${process.env.REACT_APP_API_URL}cotizacion/ConsultaClienteContado_Cotizacion?Cedula=${clientIdCard}&IdSociedad=${societyId}`, requestOptions)
                .then(response => response.text())
                .then(result => clientInfoByIC(JSON.parse(result)))
                .catch(error => showErrorMessage(error + '/' + securityToken));
        } else {
            showErrorMessage("Token -1");
        }
    };

    const getVendors = async () => {

        let securityToken = "";

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        if (items && items.token) {
            securityToken = items.token;

            let myHeaders = new Headers();
            myHeaders.append("Authorization", 'Bearer ' + securityToken);
            myHeaders.append("Accept", "application/json, */*");

            let requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            await fetch(`${process.env.REACT_APP_API_URL}cotizacion/ListarVendedores_Cotizacion`, requestOptions)
                .then(response => response.text())
                .then(result => setVendorList(JSON.parse(result)))
                .catch(error => showErrorMessage(error + '/' + securityToken));
        } else {
            showErrorMessage("Token -1");
        }

    };

    const getClients = async (cardCode) => {

        let securityToken = "";

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        if (items && items.token) {
            securityToken = items.token;

            let myHeaders = new Headers();
            myHeaders.append("Authorization", 'Bearer ' + securityToken);

            let requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            await fetch(`${process.env.REACT_APP_API_URL}bp/ConsultaSocioNegocio_por_CardCode_Facturacion?CardCode=${cardCode}`, requestOptions)
                .then(response => response.text())
                .then(result => setClientsResult(JSON.parse(result)))
                .catch(error => showErrorMessage(error + '/' + securityToken));
        } else {
            showErrorMessage("Token -1");
        }
    };

    const createBill = async () => {
        const documentLines = rowsData !== null && Array.isArray(rowsData) ? rowsData.map((row) => {
            if (row?.DATA?.ItemCode)
                return (
                    {
                        "ItemCode": row.DATA.ItemCode,
                        "Quantity": parseFloat(row.CANTIDAD),
                        "TaxCode": row.CODIGO_DE_IMPUESTO,
                        "Price": row.PRECIO_TOTAL_IVI,
                        "UnitPrice": row.PRECIO,
                        "TaxPercentagePerRow": row.PORCENTAJE_DE_IMPUESTO,
                        "DiscountPercent": parseFloat(row.DESCUENTO),
                        "U_NVT_DisponibleReal": row.DATA.Disponible,
                        "WarehouseCode": row.DATA.Almacen
                    }
                );
        }) : [];

        let myHeaders = new Headers();

        let securityToken = "";

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        if (items && items.token) {
            securityToken = items.token;


            myHeaders.append("Authorization", 'Bearer ' + securityToken);
            myHeaders.append("Content-Type", "application/json");

            let raw = JSON.stringify({
                "U_NVT_Identificador_Fiscal": clientIdCard,
                "LicTradNum": clientIdCard,
                "CardCode": clientCode,
                "CardName": clientName,
                "E_Mail": clientEmail,
                "DocDueDate": dueDate, //TODO: revisar porque estaba en date
                "DocDate": date,
                "SalesPersonCode": parseInt(vendorId),
                "Comments": comments,
                "U_NVT_Comentarios": otherComments,
                "EsClienteContado": clientContado,
                "DocumentLines": documentLines,
                "TipoIdentificacion":identificationType
            });

            console.log('DATOS DEL CREATEBILL en proforma', JSON.parse(raw));

            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_API_URL}cotizacion/CreateOrders_Cotizacion`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.DocNum != -1) {
                        setCreationStatus(result);
                        setTimeout(() => {
                            navigate(`/proforma/buscar/${result.DocNum}`);
                        }, 500);

                    } else {
                        setProcessingStatus(false);
                        showErrorMessage(result.Error);
                    }
                })
                .catch(error => console.log(error + '/' + securityToken));
        } else {
            showErrorMessage("Token -1");
        }
    };

    const getBill = async (id) => {

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        intervalGetBill.current = setInterval(async () => {

            

            await fetch(`${process.env.REACT_APP_API_URL}cotizacion/ConsultaOrdenesVentaEditar_Cotizacion?DocNum=${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + items?.token
                }
            }).then(res => res.json())
                .then(response => {
                    if (response?.length > 0) {

                        //console.log(response);
                        setIsOkayFetchGetBill(true);

                        let lastBill = response[0];
                        //console.log('lastBill proformaCreate', lastBill);

                        setDueDate(lastBill.Fecha_Hora);

                        let auxRowsData = [];

                        let myHeaders = new Headers();
                        myHeaders.append("Authorization", 'Bearer ' + items?.token);

                        let requestOptions = {
                            method: 'GET',
                            headers: myHeaders,
                            redirect: 'follow'
                        };

                        if (!duplicate) {
                            fetch(`${process.env.REACT_APP_API_URL}bp/ConsultaSocioNegocio_por_CardCode_Facturacion?CardCode=${lastBill.CardCode}`, requestOptions)
                                .then(response => response.json())
                                .then(result => {
                                    if (result?.length > 0) {
                                        let clientInfo = result[0];

                                        setDocEntry(lastBill.DocEntry);
                                        setClientEmail(clientInfo.Email);
                                        setClientName(lastBill.CardName);
                                        setClientAvailable(parseInt(clientInfo.Disponible));
                                        setClientBalance(parseInt(clientInfo.SaldoCuenta));
                                        setClientPhone(clientInfo.Telefono);
                                        setClientAuthorized(clientInfo.Autorizados);
                                        setClientContado(clientInfo.ClienteContado);
                                        setClientCode(lastBill.CardCode);
                                        setClientIdCard(lastBill.U_NVT_Identificador_Fiscal);
                                        setSelectSearchValue({ value: lastBill.CardCode, label: lastBill.CardCode + " - " + lastBill.CardName })
                                    };
                                })
                                .catch(error => showErrorMessage(error + '/' + items?.token));
                        }

                        lastBill?.DocumentLines?.forEach(element => {

                            let discount = element.DiscPrcnt === "" ? 0 : parseFloat(element.DiscPrcnt) / 100;

                            let priceWithDiscount = parseFloat(element.Price) - (parseFloat(element.Price) * discount);

                            let iva = parseFloat(element.TaxPercentagePerRow === "" ? 0 : element.TaxPercentagePerRow) / 100;

                            let priceWithIva = priceWithDiscount * iva;

                            let priceDiscountIva = priceWithDiscount + priceWithIva;

                            let quantity = element.Quantity === "" ? 0 : parseFloat(element.Quantity);

                            let totalPrice = priceDiscountIva * quantity;

                            auxRowsData.push({
                                DATA: {
                                    Almacen: element.WarehouseCode,
                                    Articulo: element.Articulo,
                                    CodigoImpuesto: element.TaxCode,
                                    Disponible: element.U_NVT_DisponibleReal,
                                    ItemCode: element.ItemCode,
                                    ItemName: element.Dscription,
                                    PorcentajeImpuesto: element.TaxPercentagePerRow,
                                    Precio: element.Price,
                                },
                                CHECK: false,
                                ID: '',
                                ARTICULO_DESCRIPCION: element.Articulo,
                                STOCK: element.U_NVT_DisponibleReal,
                                CANTIDAD: element.Quantity,
                                PRECIO: element.Price,
                                DESCUENTO: element.DiscPrcnt,
                                PRECIO_CON_DESCUENTO: priceWithDiscount,
                                PRECIO_TOTAL_IVI: totalPrice,
                                CODIGO_DE_IMPUESTO: element.TaxCode,
                                PORCENTAJE_DE_IMPUESTO: element.TaxPercentagePerRow,
                                ALMACEN: element.WarehouseCode,
                                PRECIO_MODIFICADO: false,
                                PRECIO_FACTURADO: 0
                            });

                        });

                        

                        if (!duplicate) {
                            setVendorId(lastBill.SlpCode);
                            setComments(lastBill.Comments);
                            setOtherComments(lastBill.OtrosComments);
                        }

                        // if(changedPriceList.current != []){
                        //     auxRowsData = attachPreviousPrice(auxRowsData);
                        // }

                        setRowsData(auxRowsData);
                        //setOtherComments();

                    };

                    setProcessingStatus(false);
                })
                .catch(error => {setProcessingStatus(false); console.error('Error:', error);});
        }, 1300);

        await getPriceChange(id);
    };

    const getBillDuplicate = async (id) => {

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        intervalGetBill.current = setInterval(async () => {

            

            await fetch(`${process.env.REACT_APP_API_URL}cotizacion/ConsultaOrdenesVentaEditar_Cotizacion_Duplicar?DocNum=${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + items?.token
                }
            }).then(res => res.json())
                .then(response => {
                    if (response?.length > 0) {

                        setIsOkayFetchGetBill(true);

                        let lastBill = response[0];

                        setDueDate(lastBill.Fecha_Hora);

                        let auxRowsData = [];

                        lastBill?.DocumentLines?.forEach(element => {

                            let discount = element.DiscPrcnt === "" ? 0 : parseFloat(element.DiscPrcnt) / 100;
                            let priceWithDiscount = parseFloat(element.Price) - (parseFloat(element.Price) * discount);
                            let iva = parseFloat(element.TaxPercentagePerRow === "" ? 0 : element.TaxPercentagePerRow) / 100;
                            let priceWithIva = priceWithDiscount * iva;
                            let priceDiscountIva = priceWithDiscount + priceWithIva;
                            let quantity = element.Quantity === "" ? 0 : parseFloat(element.Quantity);
                            let totalPrice = priceDiscountIva * quantity;

                            auxRowsData.push({
                                DATA: {
                                    Almacen: element.WarehouseCode,
                                    Articulo: element.Articulo,
                                    CodigoImpuesto: element.TaxCode,
                                    Disponible: element.U_NVT_DisponibleReal,
                                    ItemCode: element.ItemCode,
                                    ItemName: element.Dscription,
                                    PorcentajeImpuesto: element.TaxPercentagePerRow,
                                    Precio: element.Price,
                                },
                                CHECK: false,
                                ID: '',
                                ARTICULO_DESCRIPCION: element.Articulo,
                                STOCK: element.U_NVT_DisponibleReal,
                                CANTIDAD: element.Quantity,
                                PRECIO: element.Price,
                                DESCUENTO: element.DiscPrcnt,
                                PRECIO_CON_DESCUENTO: priceWithDiscount,
                                PRECIO_TOTAL_IVI: totalPrice,
                                CODIGO_DE_IMPUESTO: element.TaxCode,
                                PORCENTAJE_DE_IMPUESTO: element.TaxPercentagePerRow,
                                ALMACEN: element.WarehouseCode,
                                PRECIO_MODIFICADO: false,
                                PRECIO_FACTURADO: 0
                            });

                        });

                        // if(changedPriceList.current != []){
                        //     auxRowsData = attachPreviousPrice(auxRowsData);
                        // }

                        setRowsData(auxRowsData);
                    };

                    setProcessingStatus(false);
                })
                .catch(error => {setProcessingStatus(false); console.error('Error:', error);});
        }, 1300);

        await getPriceChange(id);
    };

    const navigateBill = async () => {
        navigate(`/factura/crear/${id}/false`);
    }

    const updateBill = async (type) => {

        const documentLines = rowsData !== null && Array.isArray(rowsData) ? rowsData.map((row) => {
            if (row?.DATA?.ItemCode)
                return (
                    {
                        "ItemCode": row.DATA.ItemCode,
                        "Quantity": parseFloat(row.CANTIDAD),
                        "TaxCode": row.CODIGO_DE_IMPUESTO,
                        "Price": row.PRECIO_TOTAL_IVI,
                        "UnitPrice": row.PRECIO,
                        "TaxPercentagePerRow": row.PORCENTAJE_DE_IMPUESTO,
                        "DiscountPercent": parseFloat(row.DESCUENTO),
                        "U_NVT_DisponibleReal": row.DATA.Disponible,
                        "WarehouseCode": row.DATA.Almacen
                    }
                );
        }) : [];

        let myHeaders = new Headers();

        let securityToken = "";

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        if (items && items.token) {
            securityToken = items.token;


            myHeaders.append("Authorization", 'Bearer ' + securityToken);
            myHeaders.append("Content-Type", "application/json");

            let raw = JSON.stringify({
                "DocEntry": docEntry,
                "DocNum": id,
                "DocStatus": "O",
                "DocDate": date, //TODO. revisar porque estaba en new Date(date.toString().split('GMT')[0] + ' UTC').toISOString()
                "DocDueDate": formatDate(dueDate),
                "DocTotal": total.toLocaleString("en-US"),
                "Comments": comments,
                "U_NVT_Comentarios": otherComments,
                "SalesPersonCode": parseInt(vendorId),
                "DocumentLines": documentLines
            });

            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_API_URL}cotizacion/UpdateOrders_Cotizacion`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.DocNum != -1) {
                        setUpdateStatus(result);
                        setTimeout(() => {

                            if(type === "edit"){
                                navigate(`/proforma/buscar/${result.DocNum}`);
                            };

                            if(type === "copy"){
                                navigate(`/factura/crear/${result.DocNum}/false`)
                            };
                            
                        }, 1500);
                    } else {
                        showErrorMessage(result.Error);
                    }
                })
                .catch(error => console.log(error + '/' + securityToken));
        } else {
            showErrorMessage("Token -1");
        }
    };

    const displayVendors = vendorList !== null && Array.isArray(vendorList) ? vendorList.map((vendor) => {
        return (
            <option key={vendor.Codigo} value={vendor.Codigo} style={{ fontSize: "1vw" }}>{vendor.Nombre}</option>
        );
    }) : <></>;

    const buttonAction = () => {
        if (id) {
            if (duplicate)
                return <Button className="button boldText btn" onClick={() => { setProcessingStatus(true); verification("create"); }}>Crear</Button>;
            return <Button className="button boldText btn" onClick={() => { setProcessingStatus(true); verification("update"); }}>Editar</Button>;
        }
        else {
            return <Button className="button boldText btn" onClick={() => { setProcessingStatus(true); verification("create"); }}>Crear</Button>;
        }
    }

    const copyToButton = () => {
        if (id) {
            if (!duplicate)
                return <Button className="button boldText btn" onClick={() => { setProcessingStatus(true); /*updateBill("copy")*/ navigateBill(); }}>Copiar a</Button>;
        }
        
    }

    const firstTopInputs = () => {

        if (id) {

            if (duplicate) {

                return <div className="container-first-inputs">

                    <label>Búsqueda</label>
                    <Select className="selectSearch" value={selectSearchValue} options={clientsList}
                        onInputChange={(e) => setClientsByCardCode(e)}
                        filterOption={(e)=>{return true;}}
                        onChange={(e) => { clientInfoBySearch(e.client); setSelectSearchValue({ value: e.value, label: e.label }) }} />
                    <label>Nombre</label>
                    <input className="createUserInformationInputs" defaultValue={clientName} onChange={(e) => { setClientName(e.target.value); }}></input>

                </div>

            } else {

                return <div className="container-first-inputs">

                    <label>Búsqueda</label>
                    <Select className="selectSearch" value={selectSearchValue} options={clientsList}
                        onInputChange={(e) => setClientsByCardCode(e)}
                        filterOption={(e)=>{return true;}}
                        onChange={(e) => { clientInfoBySearch(e.client); setSelectSearchValue({ value: e.value, label: e.label }) }} isOptionDisabled={(option) => true} isDisabled = {true} />
                    <label>Nombre</label>
                    <input className="createUserInformationDisabledInputs" defaultValue={clientName} disabled></input>

                </div>

            };

        } else {

            return <div className="container-first-inputs">

                <label>Búsqueda</label>
                <Select className="selectSearch" value={selectSearchValue} options={clientsList}
                    onInputChange={(e) => setClientsByCardCode(e)}
                    filterOption={(e)=>{return true;}}
                    onChange={(e) => { clientInfoBySearch(e.client); setSelectSearchValue({ value: e.value, label: e.label }) }} />
                <label>Nombre</label>
                <input className="createUserInformationInputs" defaultValue={clientName} onChange={(e) => { setClientName(e.target.value); }}></input>
            </div>

        };

    };

    const secondTopInputs = () => {

        if (id) {

            if (duplicate) {

                return <div>

                    <label>Cédula</label>
                    <input className="createUserInformationInputs" defaultValue={clientIdCard} onChange={(e) => setClientIdCard(e.target.value)} onKeyDown={handleKeyDown} onBlur={handleBlur}></input>
                    <label>Código</label>
                    <input className="createUserInformationDisabledInputs" defaultValue={clientCode} disabled></input>

                </div>

            } else {

                return <div>

                    <label>Cédula</label>
                    <input className="createUserInformationDisabledInputs" defaultValue={clientIdCard} disabled></input>
                    <label>Código</label>
                    <input className="createUserInformationDisabledInputs" defaultValue={clientCode} disabled></input>

                </div>

            };

        } else {

            return <div>

                <label>Cédula</label>
                {
                    clientContado === "N" ?
                        <input className="createUserInformationInputs" defaultValue={clientIdCard} disabled></input>
                        :
                        <input className="createUserInformationInputs" defaultValue={clientIdCard} onChange={(e) => setClientIdCard(e.target.value)} onKeyDown={handleKeyDown} onBlur={handleBlur}></input>
                }
                <label>Código</label>
                <input className="createUserInformationDisabledInputs" defaultValue={clientCode} disabled></input>

            </div>

        };

    };

    const thirdTopInputs = () => {

        if (id) {

            if (duplicate) {

                return <div>

                    <label>Fecha</label>
                    <DatePicker value={date} className="datePicker createUserInformationDisabledInputs" disabled />
                    <label>Correo electrónico</label>
                    <input className="createUserInformationInputs" defaultValue={clientEmail} onChange={(e) => {setClientEmail(e.target.value);}}></input>

                </div>

            } else {

                return <div>

                    <label>Fecha</label>
                    <DatePicker value={date} className="datePicker createUserInformationDisabledInputs" disabled />
                    <label>Correo electrónico</label>
                    <input className="createUserInformationDisabledInputs" defaultValue={clientEmail} disabled></input>

                </div>

            };

        } else {

            return <div>

                <label>Fecha</label>
                <DatePicker value={date} className="datePicker createUserInformationDisabledInputs" disabled />
                <label>Correo electrónico</label>
                <input className="createUserInformationInputs" defaultValue={clientEmail} onChange={(e) => {setClientEmail(e.target.value);}}></input>

            </div>

        };

    };

    return (

        <div className="divPrincipal">
            <Sidebar sidebarClass={sidebarClass} handleViewSidebar={handleViewSidebar}
                proformaCreateSelected={proformaCreateSelected} sidebarToggleClass={sidebarToggleClass} />
            <div className={proformaCreateClass}>
                <Header></Header>

                <div>
                    <p className="boldText pagesTitle">Creación de proformas</p>
                </div>

                <div className="divHeader">
                    <div className="col-sm createUserinformationAlign createrUserInformationMargin">
                        {firstTopInputs()}
                    </div>
                    <div className="col-sm createUserinformationAlign">
                        {secondTopInputs()}
                    </div>
                    <div className="col-sm createUserinformationAlign">
                        {thirdTopInputs()}
                    </div>
                    <div className="verticalLine" />
                    <div className="col-sm">
                        <label>Información del cliente</label>
                        <label>Disponible: <CurrencyInput placeholder="0" type="text" name="available" className="appearance" value={clientAvailable} disabled/></label>
                        <label>Balance: <CurrencyInput placeholder="0" type="text" name="available" className="appearance" value={clientBalance} disabled/></label>
                        <label>Teléfono: {clientPhone}</label>
                        <label>Email: {clientEmail}</label>
                        <label>Autorizados: {clientAuthorized}</label>
                    </div>
                </div>
                <hr />

                <div className="row card">
                    <div className="col-md-12 column rowTable">
                        <table
                            className="table table-bordered table-hover"
                            id="tab_logic">
                            <thead>
                                <tr>
                                    <th><input className="form-check-input mx-2" type="checkbox" checked={selectAll} onChange={selectAllTable}></input></th>
                                    <th><label>#</label></th>
                                    <th><div>Artículo - Descripción</div></th>
                                    <th><div className="thStockWidth">Stock</div></th>
                                    <th><div className="thQuantityWidth">Cantidad</div></th>
                                    <th><div className="thPriceWidth">Precio</div></th>
                                    <th><div className="thDiscountWidth">Descuento</div></th>
                                    <th><div className="thPriceWidth">Precio con desc</div></th>
                                    <th><div className="thPriceWidth">Precio total I.V.I</div></th>
                                    <th><div className="thWarehouseWidth">Almacén</div></th>
                                    <th><div className="thTaxCodeWidth">Cod. Imp</div></th>
                                    <th><div className="thTaxRateWidth">% Imp</div></th>
                                    <th><div>Acción</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr className="text-left">
                                        <th><span className="text-left">Loading...</span></th>
                                    </tr>
                                ) : (
                                    <TableRows clientCode={clientCode} rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} taxBlur={taxBlur} storesEdit={storesEdit} taxEdit={taxEdit} priceEdit={priceEdit} idRol={idRol}
                                        callback={(rowTable, warehouse, articleCode, stock) => { editWarehouseStock(rowTable, warehouse, articleCode, stock) }}/>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <hr />
                    <div className="divBtns">
                        <button data-tip="Agregar"
                            data-for='agregar'
                            onClick={addTableRows}
                            className="btn btn-primary"
                            disabled={clientCode == '' ? true : false}>
                            <BsPlusCircleFill className="icon" />
                            {/* <ReactTooltip id="agregar" wrapper /> */}
                        </button>

                        <button data-tip="Borrar"
                            data-for='borrar'
                            onClick={deleteTableSelects}
                            disabled={clientCode == '' ? true : false}
                            className="btn btn-danger float-right">
                            <BsTrash className="icon" />
                            {/* <ReactTooltip id="borrar" /> */}
                        </button>
                        {rollback.length > 0 ? (
                            <button data-tip="Deshacer"
                                data-for='deshacer'
                                onClick={() => {
                                    setRowsData(rollback);
                                    setRollback([]);
                                    setUpdated(!updated);
                                }}
                                className="btn btn-danger float-right">
                                <RiArrowGoBackFill className="icon" />
                                {/* <ReactTooltip id="deshacer" /> */}
                            </button>
                        ) : (
                            ''
                        )}
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-4">
                        <label className="boldText">Vendedor</label>

                        <select className="selectFields fullSize" aria-label="size 3 select example" value={vendorId} onChange={(e) => setVendorId(e.target.value)}>
                            {displayVendors}
                        </select>
                    </div>
                    <div className="col-sm-8">
                       <div className="row justify-content-end">
                       <div className="col-sm-6">
                            <label className="boldText">Comentarios</label>
                            <textarea className="fullSize text" value={comments} onChange={(e) => setComments(e.target.value)}></textarea>
                        </div>
                        {userDataLocalStorage.userRol.OtrosComentarios == "Y" &&
                        <div className="col-sm-6">
                            <label className="boldText">Otros comentarios</label>
                            <textarea className="fullSize text" value={otherComments} onChange={(e) => setOtherComments(e.target.value)}></textarea>
                        </div> 
                         }
                       </div>
                    </div>
                </div>
                <div className="details">
                    <div className="detail">
                        <label className="detailtext">Total sin impuestos</label>
                        <input className="pricesTextAlign" value={totalSinImpuestos} disabled></input>
                    </div>
                    <div className="detail">
                        <label className="detailtext">Descuento</label>
                        <input className="pricesTextAlign" value={descuento} disabled></input>
                    </div>
                    <div className="detail">
                        <label className="detailtext">Impuesto</label>
                        <input className="pricesTextAlign" value={impuesto} disabled></input>
                    </div>
                    <div className="detail">
                        <label className="detailtext">Total</label>
                        <input className="pricesTextAlign" value={total.toLocaleString("en-US")} disabled></input>
                    </div>
                </div>
                <div className="divBtns createButtons">
                    {copyToButton()}
                    <Button className="button btn" onClick={() => { setValidationStatus(true) }}><FaKey /></Button>
                    <Button className="button boldText btn" onClick={() => { cancelWarning(); }}>Cancelar</Button>
                    {buttonAction()}
                </div>

                <div>
                    {
                        processingStatus ?

                            <div className="modalOverlay ">
                                <div>

                                    <p className="boldText processing">Procesando</p>

                                    <div className="spinner-border text-light procecessingSpinner" role="status">

                                    </div>

                                </div>

                            </div>
                            :
                            <></>

                    }

                    {
                        validationStatus ?
                      
                        <Validation setValidationStatus={setValidationStatus} societyId={logedUser.societyId} setAuthorizationUser={setAuthorizationUser}
                            callback={(idRol, allowsEditing, allowsTaxEditing, allowsPriceEditing) => { 
                                setIdRol(idRol); 
                                if(allowsEditing == 'Y'){setStoresEdit(true);} 
                                if(allowsTaxEditing == 'Y'){ setTaxEdit(true); }
                                if(allowsPriceEditing == 'Y'){ setPriceEdit(true); }  
                            }}></Validation>
                        :
                        <></>
                       
                    }

                </div>
                <Footer></Footer>
            </div>
        </div >
    );
};

export default ProformaCreate;
