import { Fragment, useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import SelectProducts from './selectProducts';
import Text from "../components/text";
import Modal from "../components/modal";
import Swal from 'sweetalert2';
import CurrencyInput from './CurrencyInput';
import CryptoJS from 'crypto-js';

function TableRows({ clientCode, rowsData, deleteTableRows, handleChange, taxBlur, storesEdit, taxEdit, priceEdit, idRol, callback }) {

    const [modalState, setModalState] = useState(false);
    const [modalContent, setModalContent] = useState();
    const [modalType, setModalType] = useState();
    let inputWarehouse = useRef('');
    let [articleCode, setArticleCode] = useState('');
    const [rowTable, setRowTable] = useState();
    const [warehouseList, setWarehouseList] = useState([]);
    let descRef = useRef();

    let bytes = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
    let originalText = bytes.toString(CryptoJS.enc.Utf8);

    const userDataLocalStorage = JSON.parse(originalText);

    useEffect(() => {
        if (modalType === "editar") {
            getWarehouseDataLinkedToAnArticle(idRol, articleCode);
        } else if (modalType === "") {
            setModalState(false);
        };
    }, [modalType])

    useEffect(() => {
        if (modalType === "editar") {
            setModalContent(<Fragment>

                <div className="row d-flex justify-content-center">

                    <div className='tableBodyModal'>
                        <table className='table table-hover'>
                            <thead>
                                <tr>
                                    <th className='sticky-top' scope='col'>Almac&eacute;n</th>
                                    <th className='sticky-top' scope='col'>Stock</th>
                                    <th className='sticky-top' scope='col'>Comprometido</th>
                                    <th className='sticky-top' scope='col'>Solicitado</th>
                                    <th className='sticky-top' scope='col'>Disponible</th>
                                </tr>
                            </thead>
                            <tbody>
                                {warehouseList.map((data, index) => {
                                    if (data != null) {
                                        const {
                                            Bodega,
                                            Stock,
                                            Comprometido,
                                            Solicitado,
                                            Disponible
                                        } = data;
                                        let id = uuidv4();
                                        return (
                                            <tr key={index} onClick={() => { inputWarehouse = Bodega; updateHighlightTableModal(index); }} id={"md_" + index}>
                                                <th scope="row" value={Bodega}>{Bodega}</th>
                                                <td value={Stock}>{parseInt(Stock).toLocaleString("en-US")}</td>
                                                <td value={Comprometido}>{parseInt(Comprometido).toLocaleString("en-US")}</td>
                                                <td value={Solicitado}>{parseInt(Solicitado).toLocaleString("en-US")}</td>
                                                <td value={Disponible}>{parseInt(Disponible).toLocaleString("en-US")}</td>
                                            </tr>
                                        )
                                    }
                                })
                                }

                            </tbody>
                        </table>
                    </div>
                </div>

                {storesEdit == true &&
                    <div className="modalBtnsBox">
                        <button className="boldText btnModal cancelBtn"
                            onClick={() => { setModalType("") }}>Cancelar</button>
                        <button className="boldText btnModal submitBtn"
                            onClick={() => { { updateWarehouse(rowTable); } }}>Editar</button>
                    </div>
                }

            </Fragment>);
            setModalState(true);
        } else if (modalType === "") {
            setModalState(false);
        };
    }, [warehouseList])

    const getPercentageTax = async (event, index) => {

        let securityToken = "";
        let bytes = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        const items = JSON.parse(originalText);

        if (items && items.token) {

            securityToken = items.token;

            let myHeaders = new Headers();
            myHeaders.append("Authorization", 'Bearer ' + securityToken);

            let requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            await fetch(`${process.env.REACT_APP_API_URL}facturacion/ConsultaPorcentajeImpuesto_por_Codigo?Codigo=${event.target.value}`, requestOptions)
                .then(response => response.text())
                .then(result => {
                    if (result != -1) {
                        taxBlur(result, index, false);
                        console.log('Impuesto desde BE', result);
                    } else {
                        taxBlur(result, index, true);
                        showErrorMessage('Debe digitar un código de impuesto válido');
                    }
                })
                .catch(error => showErrorMessage(error + '/' + securityToken));
        } else {
            showErrorMessage("Token -1");
        }
    }

    const getWarehouseDataLinkedToAnArticle = async (idRol, codigoArticulo) => {

        let securityToken = "";

        let bytes = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);

        const items = JSON.parse(originalText);

        if (items && items.token) {

            securityToken = items.token;

            let myHeaders = new Headers();
            myHeaders.append("Authorization", 'Bearer ' + securityToken);

            let requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            await fetch(`${process.env.REACT_APP_API_URL}facturacion/ObtenerArticulosEnBodega?IdRol=${idRol}&CodigoArticulo=${codigoArticulo}`, requestOptions)
                .then(response => response.text())
                .then(result => { setWarehouseList(JSON.parse(result)); })
                .catch(error => console.log('error', error));

        } else {
            showErrorMessage("Se produjo un error al cargar los almacenes");
        }
    }

    const updateHighlightTableModal = (index) => {

        let trs = document.querySelectorAll(".tableBodyModal tr");

        try {

            trs.forEach(element => {
                element.classList.remove("highlight");
            });

            let trs_selected = document.querySelectorAll("#md_" + index);

            trs_selected.forEach(element => {
                element.classList.add("highlight");
            });

        } catch (error) {
            console.log('Error al aplicar el highlight del modal');
        }
    }

    const updateWarehouse = (rowTable) => {

        setModalType("");

        if (inputWarehouse.length != undefined) {
            document.getElementsByClassName('td_' + rowTable).value = inputWarehouse;
            getStock(rowTable, inputWarehouse, articleCode);
        } else {
            showErrorMessage('Debe seleccionar un almacén');
        }
    }

    const getStock = async (rowTable, warehouse, productCode) => {

        let securityToken = "";

        let bytes = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);

        const items = JSON.parse(originalText);

        if (items && items.token) {

            securityToken = items.token;

            let myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + securityToken);
            myHeaders.append("Accept", "application/json, */*");

            let requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            await fetch(`${process.env.REACT_APP_API_URL}facturacion/ConsultaArticuloDisponibleEnBodega?CodigoArticulo=${productCode}&CodigoBodega=${warehouse}`, requestOptions)
                .then(response => response.text())
                .then((result) => { inputWarehouse = ''; callback(rowTable, warehouse, result); })
                .catch(error => showErrorMessage('Se produjo un error al carga el stock'));
        } else {
            console.log('Error al recuperar el stock del producto');
        }
    };

    const showErrorMessage = (errorMessage) => {
        Swal.fire({
            title: '!Error!',
            text: errorMessage,
            icon: 'error',
            confirmButtonText: 'Aceptar',
        })
    };

    return (

        rowsData.map((data, index) => {

            const {
                DATA,
                CHECK,
                ID,
                ARTICULO_DESCRIPCION,
                STOCK,
                CANTIDAD,
                PRECIO,
                DESCUENTO,
                PRECIO_CON_DESCUENTO,
                PRECIO_TOTAL_IVI,
                CODIGO_DE_IMPUESTO,
                PORCENTAJE_DE_IMPUESTO,
                ALMACEN,
            } = data;

            return (
                <tr id={'tr_' + uuidv4()} key={index}>
                    <td>
                        <input className="form-check-input tableCheckBox" type="checkbox" value={CHECK} checked={CHECK} onChange={(evnt) => (handleChange(index, evnt))} name="CHECK" ></input>
                    </td>
                    <td className="tdNumberWidth">
                        <label className="tdContentNumberWidth">
                            {index + 1}
                        </label>
                    </td>
                    <td><SelectProducts id={index} name="ARTICULO_DESCRIPCION"
                        index={index}
                        clientCode={clientCode}
                        handleChange={(evnt, data) => { setArticleCode(data.data.ItemCode); getStock(index, data.data.Almacen, data.data.ItemCode); handleChange(index, data) }}
                        value={ARTICULO_DESCRIPCION}
                    ></SelectProducts></td>
                    <td><input name="STOCK" value={parseInt(STOCK).toLocaleString("en-US")} onChange={(evnt) => (handleChange(index, evnt))} className="disabledInput tdWidth" disabled></input></td>
                    <td><input type="number" name="CANTIDAD" className="tdWidth" value={CANTIDAD} onChange={(evnt) => (handleChange(index, evnt))} min={0}></input></td>

                    {
                        userDataLocalStorage.userRol.EditaPrecio == "Y" || priceEdit == true || data.SERVICIO == true? 
                        data.PRECIO_MODIFICADO ?
                            <OverlayTrigger
                                placement={'right'}
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip>
                                    El precio del artículo se modificó, el precio anterior es <strong>{data.PRECIO_FACTURADO}</strong>
                                    </Tooltip>
                                }
                                >
                                <td><CurrencyInput placeholder="0" type="text"name="PRECIO" value={PRECIO} onChange={(evnt) => (handleChange(index, evnt))} className={`tdWidth pricesTextAlign ${data.PRECIO_MODIFICADO ? "red-border-color" : ""}`}/></td>
                            </OverlayTrigger>
                            :

                            <td><CurrencyInput placeholder="0" type="text"name="PRECIO" value={PRECIO} onChange={(evnt) => (handleChange(index, evnt))} className={`tdWidth pricesTextAlign ${data.PRECIO_MODIFICADO ? "red-border-color" : ""}`}/></td>
                        :
                        data.PRECIO_MODIFICADO ?
                            <OverlayTrigger
                                placement={'right'}
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip>
                                    El precio del artículo se modificó, el precio anterior es <strong>{data.PRECIO_FACTURADO}</strong>
                                    </Tooltip>
                                }
                                >
                                <td><input name="PRECIO" value={PRECIO.toLocaleString("en-US")} onChange={(evnt) => (handleChange(index, evnt))} className={`tdWidth disabledInput pricesTextAlign ${data.PRECIO_MODIFICADO ? "red-border-color" : ""}`} disabled></input></td>
                            </OverlayTrigger>
                            :
                            <td><input name="PRECIO" value={PRECIO.toLocaleString("en-US")} onChange={(evnt) => (handleChange(index, evnt))} className={`tdWidth disabledInput pricesTextAlign ${data.PRECIO_MODIFICADO ? "red-border-color" : ""}`} disabled></input></td>

                    }
                    

                    <td><input type="number" name="DESCUENTO" value={DESCUENTO} onChange={(evnt) => (handleChange(index, evnt))} className="tdWidth" min={0} max={100}></input></td>
                    <td><input name="PRECIO CON DESCUENTO" value={PRECIO_CON_DESCUENTO.toLocaleString("en-US")} onChange={(evnt) => (handleChange(index, evnt))} className="disabledInput pricesTextAlign tdWidth" disabled></input></td>
                    <td><input name="PRECIO TOTAL I.V.I" value={PRECIO_TOTAL_IVI.toLocaleString("en-US")} onChange={(evnt) => (handleChange(index, evnt))} className="disabledInput pricesTextAlign tdWidth" disabled></input></td>

                    {
                        storesEdit ?

                            <td><input name="ALMACEN" ref={descRef} onFocus={(e) => { descRef.current.blur(); }}
                                value={ALMACEN} onChange={(evnt) => (handleChange(index, evnt))} onClick={() => { setModalType('editar'); setRowTable(index); setArticleCode(DATA.ItemCode); }} className={"tdWidth td_" + index}></input>

                                <Modal className={"modalTable"} title={"Detalle de existencias por Almacén"} state={modalState} changeState={setModalState} modalType={setModalType} width={"60%"} minHeight={75}>
                                    {modalContent}
                                </Modal>
                            </td>

                            :

                            <td><input name="ALMACEN" value={ALMACEN} className="tdWidth" ref={descRef} onFocus={(e) => { descRef.current.blur(); }}
                                onChange={(evnt) => (handleChange(index, evnt))} onClick={() => { setModalType('editar'); setArticleCode(DATA.ItemCode); }}></input>
                                <Modal className={"modalTable"} title={"Detalle de existencias por Almacén"} state={modalState} changeState={setModalState} modalType={setModalType} width={"60%"} minHeight={75}>
                                    {modalContent}
                                </Modal>
                            </td>

                    }

                    {
                        userDataLocalStorage.userRol.EditaImpuesto == "Y" || taxEdit == true? 
                        <td><input name="CODIGO IMPUESTO" className="tdWidth" value={CODIGO_DE_IMPUESTO} onChange={(evnt) => (handleChange(index, evnt))} onBlur={(event)=>(getPercentageTax(event, index))}></input></td>
                        :
                        <td><input name="CODIGO IMPUESTO" value={CODIGO_DE_IMPUESTO} onChange={(evnt) => (handleChange(index, evnt))} className="disabledInput tdWidth" disabled></input></td>
                    }

                    <td><input name="PORCENTAJE IMPUESTO" value={parseInt(PORCENTAJE_DE_IMPUESTO).toLocaleString("en-US")} onChange={(evnt) => (handleChange(index, evnt))} className="disabledInput tdWidth" disabled></input></td>

                    <td><button onClick={() => {
                        deleteTableRows(index)
                    }}
                        className="btn btn-outline-danger btn-sm"
                    >
                        Borrar
                    </button></td>
                    <td><input type="hidden" name="VALIDO"></input></td>
                </tr>
            )
        })
    )
}

export default TableRows;