import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/sidebar";
import Header from "../components/header";
import Footer from "../components/footer";
import Box from "../components/box";
import Text from "../components/text";
import Button from "../components/button";
import Modal from "../components/modal";
import Swal from "sweetalert2";
import CryptoJS from 'crypto-js';

const Roles = ({
    roleClass,
    sidebarClass,
    sidebarToggleClass,
    handleViewSidebar,
    roleSelected
}) => {

    const [data, setData] = useState([]);
    const [displayRoles, setDisplayRoles] = useState();
    const [modalState, setModalState] = useState(false);
    const [modalContent, setModalContent] = useState();

    const [modalType, setModalType] = useState();
    const [rolId, setRolId] = useState();
    const [rolName, setRolName] = useState("");
    const [discount, setDiscount] = useState("");
    const [priceEdit, setPriceEdit] = useState(false);
    const [taxEdit, setTaxEdit] = useState(false);
    const [storeEdit, setStoreEdit] = useState(false);
    const [otherComments, setOtherComments] = useState(false);
    const [autorized, setAutorized] = useState(false);
    const [adminSection, setAdminSection] = useState(false);
    const [proformaSection, setProformaSection] = useState(false);
    const [billSection, setBillSection] = useState(false);

    const [creationStatus, setCreationStatus] = useState("");
    const [updateStatus, setUpdateStatus] = useState("");
    const [validacionCreacion, setValidacionCreacion] = useState(false);
    const [inactiveStatus, setInactiveStatus] = useState("");

    let navigate = useNavigate();

    let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
    let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
    const userDataLocalStorage = JSON.parse(originalText);

    if(userDataLocalStorage?.userRol?.ModuloAdministracion != "Y"){ navigate("/factura/crear"); }
    if(userDataLocalStorage?.userRol < 0 || userDataLocalStorage == undefined || userDataLocalStorage == null || userDataLocalStorage == '{"username":"","database":""}' ){ navigate("/"); }

    useEffect(() => {

        getRoles();
    }, [])

    useEffect(() => {

        if (creationStatus === "Ok") {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: 'Rol creado correctamente'
            })

            setCreationStatus("");
            getRoles();
        } else {
            showErrorMessage(creationStatus);
        }

    }, [creationStatus])

    useEffect(() => {

        if (updateStatus === "Ok") {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: 'Rol actualizado correctamente'
            })

            setUpdateStatus("");
            getRoles();
        } else {
            showErrorMessage(updateStatus);
        }

    }, [updateStatus])

    useEffect(() => {

        if (inactiveStatus === "Ok") {

            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: 'Rol eliminado correctamente'
            })

            setInactiveStatus("");
            getRoles();
        };

    }, [inactiveStatus])

    useEffect(() => {

        if (modalType === "crear") {
            setModalContent(<Fragment>
                <div className="row createRow">

                    <div className="col createInputscontainers">
                        <Text className="createTitles boldText">Nombre del rol</Text>
                        <input onChange={(e) => { setRolName(e.target.value) }}></input>

                        <Text className="createTitles boldText titleLittleMargin titleLittleMargin">Descuento permitido</Text>
                        <input onChange={(e) => { setDiscount(e.target.value) }}></input>
                    </div>

                    <div className="col createInputscontainers">
                        <Text className="createTitles boldText">Edita precio</Text>
                        <input type="checkbox" defaultChecked={priceEdit} onChange={(e) => { setPriceEdit(!priceEdit) }}></input>

                        <Text className="createTitles boldText">Edita impuesto</Text>
                        <input type="checkbox" defaultChecked={taxEdit} onChange={(e) => { setTaxEdit(!taxEdit); }}></input>

                        <Text className="createTitles boldText">Edita almacen</Text>
                        <input type="checkbox" defaultChecked={storeEdit} onChange={(e) => { setStoreEdit(!storeEdit) }}></input>
                    </div>

                    <div className="col createInputscontainers">
                        <Text className="createTitles boldText">Otros comentarios</Text>
                        <input type="checkbox" defaultChecked={otherComments} onChange={(e) => { setOtherComments(!otherComments) }}></input>

                        <Text className="createTitles boldText">Es autorizador</Text>
                        <input type="checkbox" defaultChecked={autorized} onChange={(e) => { setAutorized(!autorized) }}></input>
                    </div>

                    <div className="col createInputscontainers">
                        <Text className="createTitles boldText">Modulo de administracion</Text>
                        <input type="checkbox" defaultChecked={adminSection} onChange={(e) => { setAdminSection(!adminSection) }}></input>

                        <Text className="createTitles boldText">Modulo de proformas</Text>
                        <input type="checkbox" defaultChecked={proformaSection} onChange={(e) => { setProformaSection(!proformaSection) }}></input>

                        <Text className="createTitles boldText">Modulo de facturas</Text>
                        <input type="checkbox" defaultChecked={billSection} onChange={(e) => { setBillSection(!billSection) }}></input>
                    </div>

                    <div className="modalBtnsBox">
                        <button className="boldText btnModal cancelBtn"
                            onClick={() => setModalType("")}>Cancelar</button>
                        <button className="boldText btnModal submitBtn"
                            onClick={() => { createRol() }}
                        >Crear</button>
                    </div>
                </div>
            </Fragment>);

            setModalState(true);

        } else if (modalType === "eliminar") {

            inactiveWarning();

        } else if (modalType === "editar") {
            setModalContent(<Fragment>
                <div className="row createRow">
                    <div className="col createInputscontainers">
                        <Text className="createTitles boldText">Nombre del rol</Text>
                        <input defaultValue={rolName} onChange={(e) => { setRolName(e.target.value) }}></input>

                        <Text className="createTitles boldText titleLittleMargin titleLittleMargin">Descuento permitido</Text>
                        <input defaultValue={discount} onChange={(e) => { setDiscount(e.target.value) }}></input>
                    </div>

                    <div className="col createInputscontainers">
                        <Text className="createTitles boldText">Edita precio</Text>
                        <input type="checkbox" defaultChecked={priceEdit} onChange={(e) => { setPriceEdit(!priceEdit) }}></input>

                        <Text className="createTitles boldText">Edita impuesto</Text>
                        <input type="checkbox" defaultChecked={taxEdit} onChange={(e) => { setTaxEdit(!taxEdit); }}></input>

                        <Text className="createTitles boldText">Edita almacen</Text>
                        <input type="checkbox" defaultChecked={storeEdit} onChange={(e) => { setStoreEdit(!storeEdit) }}></input>
                    </div>

                    <div className="col createInputscontainers">
                        <Text className="createTitles boldText">Otros comentarios</Text>
                        <input type="checkbox" defaultChecked={otherComments} onChange={(e) => { setOtherComments(!otherComments) }}></input>

                        <Text className="createTitles boldText">Es autorizador</Text>
                        <input type="checkbox" defaultChecked={autorized} onChange={(e) => { setAutorized(!autorized) }}></input>
                    </div>

                    <div className="col createInputscontainers">
                        <Text className="createTitles boldText">Modulo de administracion</Text>
                        <input type="checkbox" defaultChecked={adminSection} onChange={(e) => { setAdminSection(!adminSection) }}></input>

                        <Text className="createTitles boldText">Modulo de proformas</Text>
                        <input type="checkbox" defaultChecked={proformaSection} onChange={(e) => { setProformaSection(!proformaSection) }}></input>

                        <Text className="createTitles boldText">Modulo de facturas</Text>
                        <input type="checkbox" defaultChecked={billSection} onChange={(e) => { setBillSection(!billSection) }}></input>
                    </div>

                    <div className="modalBtnsBox">
                        <button className="boldText btnModal cancelBtn"
                            onClick={() => setModalType("")}>Cancelar</button>
                        <button className="boldText btnModal submitBtn"
                            onClick={() => { updateRol() }}
                        >Actualizar</button>
                    </div>
                </div>
            </Fragment>);

            setModalState(true);

        } else if (modalType === "") {

            setModalState(false);
            setRolId();
            setRolName("");
            setDiscount("");
            setPriceEdit(false);
            setTaxEdit(false);
            setStoreEdit(false);
            setOtherComments(false);
            setAutorized(false);
            setAdminSection(false);
            setProformaSection(false);
            setBillSection(false);
        };

    }, [modalType, rolName, priceEdit, taxEdit, storeEdit, discount,
        otherComments, autorized, adminSection, proformaSection, billSection])

    useEffect(() => {

        const displayData = data !== null && Array.isArray(data) ? data.map((data) => {

            let price = false;
            let taxe = false;
            let store = false;
            let comments = false;
            let authorizer = false;
            let administration = false;
            let proforma = false;
            let bill = false;

            if (data.EditaPrecio === "Y") {
                price = true;
            };

            if (data.EditaImpuesto === "Y") {
                taxe = true;
            };

            if (data.EditaAlmacen === "Y") {
                store = true;
            };

            if (data.OtrosComentarios === "Y") {
                comments = true;
            };

            if (data.EsAutorizador === "Y") {
                authorizer = true;
            };

            if (data.ModuloAdministracion === "Y") {
                administration = true;
            };

            if (data.ModuloProformas === "Y") {
                proforma = true;
            };

            if (data.ModuloFacturas === "Y") {
                bill = true;
            };

            let stores = "";

            if (data.AlmacenesAsignados.length > 0) {

                for (let i = 0; i < data.AlmacenesAsignados.length; i++) {

                    if (i + 1 === data.AlmacenesAsignados.length) {
                        stores = stores + data.AlmacenesAsignados[i].NombreAlmacen + ".";
                    } else {
                        stores = stores + data.AlmacenesAsignados[i].NombreAlmacen + ", ";
                    };

                };
            };

            return (
                <Box key={data.Id} className="roles" width={"95%"} marginLeft={"2.5%"} marginTop={2}
                    marginRight={0} marginBottom={0} minHeight={7}
                    backgroundColor="#FFFFFF">

                    <div style={{ width: "12%", marginLeft: "2%", display: "flex", alignItems: "center" }}>
                        <Text className="text" color={"#000000"}
                            marginRight={0} padding={0} fontSize={1}
                            marginLeft={"0%"} marginTop={0} marginBottom={0}
                            width={"0%"}
                        >{data.Nombre}</Text>
                    </div>

                    <div className="checkBoxDiv" style={{ width: "4%", marginLeft: "0%" }}>
                        <input disabled type="checkbox" checked={price}></input>
                    </div>

                    <div className="checkBoxDiv" style={{ width: "5%", marginLeft: "1.3%" }}>
                        <input disabled type="checkbox" checked={taxe}></input>
                    </div>

                    <div className="checkBoxDiv" style={{ width: "5%", marginLeft: "1.5%" }}>
                        <input disabled type="checkbox" checked={store}></input>
                    </div>

                    <div className="checkBoxDiv" style={{ width: "6%", marginLeft: "1.2%" }}>
                        <Text className="text" color={"#000000"}
                            marginRight={0} padding={0} fontSize={1}
                            marginLeft={"0%"} marginTop={0} marginBottom={0}>
                            {data.DescuentoPermitido}
                        </Text>
                    </div>

                    <div className="checkBoxDiv" style={{ width: "9%", marginLeft: "1.5%" }}>
                        <Text className="stores" marginLeft={"0%"}>{stores}</Text>
                    </div>

                    <div className="checkBoxDiv" style={{ width: "7%", marginLeft: "1.5%" }}>
                        <input disabled type="checkbox" checked={comments}></input>
                    </div>

                    <div className="checkBoxDiv" style={{ width: "7%", marginLeft: "1.5%" }}>
                        <input disabled type="checkbox" checked={authorizer}></input>
                    </div>

                    <div className="checkBoxDiv" style={{ width: "8%", marginLeft: "1.5%" }}>
                        <input disabled type="checkbox" checked={administration}></input>
                    </div>

                    <div className="checkBoxDiv" style={{ width: "6%", marginLeft: "1.4%" }}>
                        <input disabled type="checkbox" checked={proforma}></input>
                    </div>

                    <div className="checkBoxDiv" style={{ width: "6%", marginLeft: "1.2%" }}>
                        <input disabled type="checkbox" checked={bill}></input>
                    </div>

                    <div style={{ width: "6%", marginLeft: "1.2%" }}>

                        <button className="resultBtn editBtn boldText"
                            onClick={() => {
                                setRolId(data.Id); setRolName(data.Nombre); setDiscount(data.DescuentoPermitido);
                                setPriceEdit(price); setTaxEdit(taxe); setStoreEdit(store); setOtherComments(comments);
                                setAutorized(authorizer); setAdminSection(administration); setProformaSection(proforma);
                                setBillSection(bill); setModalType("editar")
                            }}>Editar</button>

                        <button className="resultBtn delete boldText"
                            onClick={() => { setRolId(data.Id); setModalType("eliminar") }}>Eliminar</button>
                    </div>


                </Box>
            );
        }) : <></>;

        setDisplayRoles(displayData);

    }, [data])

    const showErrorMessage = (errorMessage) => {

        if (errorMessage !== null && errorMessage !== "") {
            Swal.fire({
                title: 'Error!',
                text: errorMessage,
                icon: 'error',
                confirmButtonText: 'Aceptar'
            })
        };

    };

    const inactiveWarning = () => {

        Swal.fire({
            title: '¡Advertencia!',
            text: "¿Está seguro de querer eliminar este rol?",
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Aceptar',
            denyButtonText: 'Cancelar'

        }).then((result) => {

            if (result.isConfirmed) {

                inactiveRol();
            } else if (result.isDenied) {
                setRolId();
                setRolName("");
                setDiscount("");
                setPriceEdit(false);
                setTaxEdit(false);
                setStoreEdit(false);
                setOtherComments(false);
                setAutorized(false);
                setAdminSection(false);
                setProformaSection(false);
                setBillSection(false);
            }
        });

    };

    const getRoles = async () => {

        let securityToken = "";

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        if (items) {
            securityToken = items.token;
        };

        let myHeaders = new Headers();
        myHeaders.append("Authorization", 'Bearer ' + securityToken);

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        await fetch(`${process.env.REACT_APP_API_URL}admin/ListarRoles`, requestOptions)
            .then(response => response.text())
            .then(result => setData(JSON.parse(result)))
            .catch(error => showErrorMessage(error));

    };

    const createRol = async () => {

        if (validacionCreacion === true) { return };

        setValidacionCreacion(true);

        let securityToken = "";

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        if (items) {
            securityToken = items.token;
        };

        let myHeaders = new Headers();
        myHeaders.append("Authorization", 'Bearer ' + securityToken);
        myHeaders.append("Content-Type", "application/json");

        let price = "N";
        let taxe = "N";
        let store = "N";
        let comments = "N";
        let authorizer = "N";
        let administration = "N";
        let proforma = "N";
        let bill = "N";

        if (priceEdit) {
            price = "Y";
        };

        if (taxEdit) {
            taxe = "Y";
        };

        if (storeEdit) {
            store = "Y";
        };

        if (otherComments) {
            comments = "Y";
        };

        if (autorized) {
            authorizer = "Y";
        };

        if (adminSection) {
            administration = "Y";
        };

        if (proformaSection) {
            proforma = "Y";
        };

        if (billSection) {
            bill = "Y";
        };

        let raw = JSON.stringify({
            "Nombre": rolName,
            "EditaPrecio": price,
            "EditaImpuesto": taxe,
            "EditaAlmacen": store,
            "DescuentoPermitido": discount,
            "OtrosComentarios": comments,
            "EsAutorizador": authorizer,
            "ModuloAdministracion": administration,
            "ModuloProformas": proforma,
            "ModuloFacturas": bill,
            "Activo": 1
        });

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        await fetch(`${process.env.REACT_APP_API_URL}admin/InsertarRoles`, requestOptions)
            .then(response => response.text())
            .then(result => { setCreationStatus(JSON.parse(result)) })
            .catch(error => showErrorMessage(error));

        setModalType("");
        setRolId();
        setRolName("");
        setDiscount("");
        setPriceEdit(false);
        setTaxEdit(false);
        setStoreEdit(false);
        setOtherComments(false);
        setAutorized(false);
        setAdminSection(false);
        setProformaSection(false);
        setBillSection(false);
        setValidacionCreacion(false);

    };

    const updateRol = async () => {

        let securityToken = "";

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        if (items) {
            securityToken = items.token;
        };

        let myHeaders = new Headers();
        myHeaders.append("Authorization", 'Bearer ' + securityToken);
        myHeaders.append("Content-Type", "application/json");

        let price = "N";
        let taxe = "N";
        let store = "N";
        let comments = "N";
        let authorizer = "N";
        let administration = "N";
        let proforma = "N";
        let bill = "N";

        if (priceEdit) {
            price = "Y";
        };

        if (taxEdit) {
            taxe = "Y";
        };

        if (storeEdit) {
            store = "Y";
        };

        if (otherComments) {
            comments = "Y";
        };

        if (autorized) {
            authorizer = "Y";
        };

        if (adminSection) {
            administration = "Y";
        };

        if (proformaSection) {
            proforma = "Y";
        };

        if (billSection) {
            bill = "Y";
        };

        let raw = JSON.stringify({
            "Id": rolId,
            "Nombre": rolName,
            "EditaPrecio": price,
            "EditaImpuesto": taxe,
            "EditaAlmacen": store,
            "DescuentoPermitido": discount,
            "OtrosComentarios": comments,
            "EsAutorizador": authorizer,
            "ModuloAdministracion": administration,
            "ModuloProformas": proforma,
            "ModuloFacturas": bill,
            "Activo": 1
        });

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        await fetch(`${process.env.REACT_APP_API_URL}admin/ActualizarRol`, requestOptions)
            .then(response => response.text())
            .then(result => { setUpdateStatus(JSON.parse(result)) })
            .catch(error => showErrorMessage(error));

        setModalType("");
        setRolId();
        setRolName("");
        setDiscount("");
        setPriceEdit(false);
        setTaxEdit(false);
        setStoreEdit(false);
        setOtherComments(false);
        setAutorized(false);
        setAdminSection(false);
        setProformaSection(false);
        setBillSection(false);

    };

    const inactiveRol = async () => {

        let myHeaders = new Headers();

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        myHeaders.append("Authorization", 'Bearer ' + items.token);
        myHeaders.append("Content-Type", "application/json");

        let raw = JSON.stringify({
            "Id": rolId,
            "Activo": 0
        });

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        await fetch(`${process.env.REACT_APP_API_URL}admin/Inactivar_Activar_Roles`, requestOptions)
            .then(response => response.text())
            .then(result => setInactiveStatus(JSON.parse(result)))
            .catch(error => showErrorMessage(error));

        setModalType("");
        setRolId();
        setRolName("");
        setDiscount("");
        setPriceEdit(false);
        setTaxEdit(false);
        setStoreEdit(false);
        setOtherComments(false);
        setAutorized(false);
        setAdminSection(false);
        setProformaSection(false);
        setBillSection(false);
        getRoles();

    };

    return (
        <div style={{ display: "flex" }}>
            <Sidebar sidebarClass={sidebarClass} handleViewSidebar={handleViewSidebar}
                roleSelected={roleSelected} sidebarToggleClass={sidebarToggleClass} />

            <div className={roleClass}>
                <Header></Header>

                <Text className="boldText" color="#000000" fontSize={1.5}
                    marginRight={0} padding={0}
                    marginLeft={"2.5%"} marginTop={4} marginBottom={0}>Listado de roles</Text>

                <button className="addRolBtn boldText"
                    onClick={() => setModalType("crear")}>Crear nuevo rol</button>


                <div className="permissionsTitles">

                    <Text className="boldText" color={"#808184"}
                        marginRight={0} padding={0} fontSize={1} width={"12%"}
                        marginLeft={"3%"} marginTop={3} marginBottom={1}>Nombre del rol</Text>

                    <Text className="boldText" color={"#808184"}
                        marginRight={0} padding={0} fontSize={1} width={"4%"}
                        marginLeft={"1%"} marginTop={3} marginBottom={1}>Edita precio</Text>

                    <Text className="boldText" color={"#808184"}
                        marginRight={0} padding={0} fontSize={1} width={"5%"}
                        marginLeft={"1%"} marginTop={3} marginBottom={1}>Edita impuesto</Text>

                    <Text className="boldText" color={"#808184"}
                        marginRight={0} padding={0} fontSize={1} width={"5%"}
                        marginLeft={"1%"} marginTop={3} marginBottom={1}>Edita almacen</Text>

                    <Text className="boldText" color={"#808184"}
                        marginRight={0} padding={0} fontSize={1} width={"6%"}
                        marginLeft={"1%"} marginTop={3} marginBottom={1}>Descuento permitido</Text>

                    <Text className="boldText" color={"#808184"}
                        marginRight={0} padding={0} fontSize={1} width={"9%"}
                        marginLeft={"1%"} marginTop={3} marginBottom={1}>Almacenes asignados</Text>

                    <Text className="boldText" color={"#808184"}
                        marginRight={0} padding={0} fontSize={1} width={"7%"}
                        marginLeft={"1%"} marginTop={3} marginBottom={1}>Otros comentarios</Text>

                    <Text className="boldText" color={"#808184"}
                        marginRight={0} padding={0} fontSize={1} width={"7%"}
                        marginLeft={"1%"} marginTop={3} marginBottom={1}>Es autorizador</Text>

                    <Text className="boldText" color={"#808184"}
                        marginRight={0} padding={0} fontSize={1} width={"8%"}
                        marginLeft={"1%"} marginTop={3} marginBottom={1}>Modulo de administracion</Text>

                    <Text className="boldText" color={"#808184"}
                        marginRight={0} padding={0} fontSize={1} width={"6%"}
                        marginLeft={"1%"} marginTop={3} marginBottom={1}>Modulo de proformas</Text>

                    <Text className="boldText" color={"#808184"}
                        marginRight={0} padding={0} fontSize={1} width={"6%"}
                        marginLeft={"1%"} marginTop={3} marginBottom={1}>Modulo de facturas</Text>

                </div>

                <div className="permissionsResults" style={{ marginBottom: "8vh" }}>

                    {displayRoles}

                </div>

                <Modal className={"createInputs"} title={"Crear"} state={modalState} changeState={setModalState} modalType={setModalType} width={"60%"}>

                    {modalContent}

                </Modal>

                <Footer></Footer>
            </div>

        </div>
    );

};

export default Roles;