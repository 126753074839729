import React, { useState, useEffect, Fragment } from "react";
import Sidebar from "../components/sidebar";
import Header from "../components/header";
import Footer from "../components/footer";
import Box from "../components/box";
import Text from "../components/text";
import Button from "../components/button";
import Modal from "../components/modal";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import DatePicker from 'react-date-picker';

const Logs = ({
    logsClass,
    sidebarClass,
    sidebarToggleClass,
    handleViewSidebar,
    logsSelected
}) => {

    const [logs, setlogs] = useState([]);
    //const [displayOptions, setDisplaylogs] = useState();
    const [DatosEntradaResult, setDatosEntradaResult] = useState([]);
    const [DatosSalidaResult, setDatosSalidaResult] = useState([]);
    const [DatosResultadosResult, setDatosResultadosResult] = useState([]);
    const [DatosScriptResult, setDatosScriptResult] = useState([]);
    const [log, setlog] = useState("");
    const [idlog, setIdlog] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [modalState, setModalState] = useState(false);
    const [modalContent, setModalContent] = useState();
    const [modalType, setModalType] = useState();
    const [modalTitle, setModalTitle] = useState("");
    const [sinceDate, setSinceDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [Clase, setClase] = useState("");
    const [Metodo, setMetodo] = useState("");
    const [Estado, setEstado] = useState("");

    useEffect(() => {

        getlogs();

    }, [])
   

    const dataPerPage = 10;
    const pagesVisited = pageNumber * dataPerPage;
    const pageCount = Math.ceil(logs.length / dataPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected)
    };

    const displayOptions = logs !== null && Array.isArray(logs) ? logs.slice(pagesVisited, pagesVisited + dataPerPage)
        .map((logs) => {

            return (
                <Box key={logs.Id} className="filters" width={"95%"} marginLeft={"2.5%"} marginTop={2}
                    marginRight={0} marginBottom={0} minHeight={7}
                    backgroundColor="#FFFFFF">

                    <p className="result text secondColummn">{logs.Id}</p>

                    <p className="result text firstColummn">{logs.Clase}</p>

                    <p className="result text firstColummn">{logs.Metodo}</p>

                    <p className="result text firstColummn">{logs.Fecha}</p>

                    <p className="result text ThirdColummn">{logs.Estado}</p>
               
                    <button className="resultBtn VerBtn boldText"
                        onClick={() => { setModalTitle("Entrada"); /*setIdlog(logs.Id);*/ getDatosEntrada(logs.Id);
                        /*setModalType("Entrada")*/ }}>Entrada</button>

                    <button className="resultBtn VerBtn boldText"
                        onClick={() => { setModalTitle("Salida"); /*setIdlog(logs.Id);*/ getDatosSalida(logs.Id);
                        /*setModalType("Salida")*/ }}>Salida</button>                

                    <button className="resultBtn VerBtn boldText"
                        onClick={() => { setModalTitle("Resultado"); /*setIdlog(logs.Id);*/ getDatosResultado(logs.Id);
                        /*setModalType("Resultado")*/ }}>Resultado</button>

                    {/*<button className="resultBtn VerBtn boldText"
                        onClick={() => { setModalTitle("Script");  getDatosScripts(logs.Id);
            setModalType("Script") }}>Script</button>*/}

                </Box>
            );
            
        }) : <></>;

        //setDisplaylogs("");

    useEffect(() => {
     
    }, [modalType, logs])

    const showErrorMessage = (errorMessage) => {

        if (errorMessage !== null && errorMessage !== "") {
            Swal.fire({
                title: 'Error!',
                text: errorMessage,
                icon: 'error',
                confirmButtonText: 'Aceptar'
            })
        };

    };  

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    const formatDateBasic = (date) => {
        return [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
        ].join('/');
    }

    const getlogs = async (auxNoDoc = null) => {

        var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            //var tempDate = new Date(formatDateBasic(new Date()));
            var tempDate = new Date(formatDateBasic(new Date()));

        var raw = {                      
            //"FechaInicio": auxNoDoc ? (new Date(tempDate.toString().split('GMT')[0] + ' UTC').toISOString()) : (sinceDate.toISOString() == toDate.toISOString() ? (new Date(tempDate.toString().split('GMT')[0] + ' UTC').toISOString()) : (new Date(sinceDate.toString().split('GMT')[0] + ' UTC').toISOString())),
            //"FechaFin": new Date(toDate.toString().split('GMT')[0] + ' UTC').toISOString(), 
            "FechaInicio": new Date(sinceDate.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 10),
            "FechaFin": new Date(toDate.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 10),    
            "Clase": Clase,
            "Metodo": Metodo,
            "Estado": Estado
        };

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(raw),
            redirect: 'follow'
        };

        await fetch(`${process.env.REACT_APP_API_URL}Logs/ObtenerLogsFiltros`, requestOptions)
            .then(response => response.text())
            .then(result => setlogs(JSON.parse(result)))
            .catch(error => showErrorMessage(error));

    };

    async function getDatosEntrada(Id) {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            await fetch(`${process.env.REACT_APP_API_URL}Logs/ObtenerDatosEntradaLogs?Id=${Id}`, requestOptions)
                .then(response => response.text())
                .then(result => setDatosEntradaResult(JSON.parse(result)))
                .catch(error => showErrorMessage(error));   
                setModalType("Entrada");             
    };

    async function getDatosSalida(Id) {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        await fetch(`${process.env.REACT_APP_API_URL}Logs/ObtenerDatosSalidaLogs?Id=${Id}`, requestOptions)
            .then(response => response.text())
            .then(result => setDatosSalidaResult(JSON.parse(result)))
            .catch(error => showErrorMessage(error));
            setModalType("Salida");
};

async function getDatosResultado(Id) {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    await fetch(`${process.env.REACT_APP_API_URL}Logs/ObtenerDatosResultadoLogs?Id=${Id}`, requestOptions)
        .then(response => response.text())
        .then(result => setDatosResultadosResult(JSON.parse(result)))
        .catch(error => showErrorMessage(error));
        setModalType("Resultado");
};

/*async function getDatosScripts(Id) {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    await fetch(`${process.env.REACT_APP_API_URL}Logs/ObtenerDatosScriptLogs?Id=${Id}`, requestOptions)
        .then(response => response.text())
        .then(result => setDatosScriptResult(JSON.parse(result)))
        .catch(error => showErrorMessage(error));
        setModalType("Script");
};*/
    

    useEffect(() => {

        if (modalType === "Entrada") {
        setModalContent(<Fragment>
            <textarea style={{ width: "100%", marginLeft: "2px", marginRight: "2px", marginBottom: "30px"}} >{DatosEntradaResult}</textarea>
       
        </Fragment>);
    
        setModalState(true);
        
    } else if (modalType === "Salida") {
            setModalContent(<Fragment>
                
                <textarea style={{ width: "100%", marginLeft: "2px", marginRight: "2px", marginBottom: "30px"}} >{DatosSalidaResult}</textarea>
                
            </Fragment>);
        
        setModalState(true);            

        } else if (modalType === "Resultado") {
            setModalContent(<Fragment>
              
                <textarea style={{ width: "100%", marginLeft: "2px", marginRight: "2px", marginBottom: "30px"}} >{DatosResultadosResult}</textarea>
                
            </Fragment>);
        
        setModalState(true);

            } else if (modalType === "Script") {
                setModalContent(<Fragment>
                    
                <textarea style={{ width: "100%", marginLeft: "2px", marginRight: "2px", marginBottom: "30px"}} >{DatosScriptResult}</textarea>
                
                </Fragment>);
            
            setModalState(true);
                }


    }, [modalType, DatosEntradaResult, DatosSalidaResult, DatosResultadosResult, DatosScriptResult, logs])


    return (
        <div style={{ display: "flex" }}>
            <Sidebar sidebarClass={sidebarClass} handleViewSidebar={handleViewSidebar}
                logsSelected={logsSelected} sidebarToggleClass={sidebarToggleClass} />

            <div className={logsClass}>
                <Header></Header>              

                <h2 className="boldText logTitle">Logs</h2>

                <Box className="searchFilters" width={"95%"} height={15} marginLeft={"2.5%"} marginTop={5}
                        backgroundColor="#FFFFFF">
                        <div className="searchContainer">
                            <Text className="boldText" color={"#808184"}
                                margin={0} padding={0} fontSize={1}
                                marginLeft={0} marginTop={2} marginBottom={1}
                            >Desde</Text>
                            <DatePicker onChange={(e) => setSinceDate(e)} value={sinceDate} className="datePicker" />
                        </div>

                        <div className="searchContainer">
                            <Text className="boldText" color={"#808184"}
                                margin={0} padding={0} fontSize={1}
                                marginLeft={0} marginTop={2} marginBottom={1}
                            >Hasta</Text>
                            <DatePicker onChange={(e) => setToDate(e)} value={toDate} className="datePicker" />
                        </div>
                        <div className="searchContainer">
                            <Text className="boldText" color={"#808184"}
                                margin={0} padding={0} fontSize={1}
                                marginLeft={0} marginTop={2} marginBottom={1}
                            >Clase</Text> 
                             <input className="inputFilter" type="text" value={Clase} onChange={(e) => setClase(e.target.value)}></input>   
                        </div>

                        <div className="searchContainer">
                            <Text className="boldText" color={"#808184"}
                                margin={0} padding={0} fontSize={1}
                                marginLeft={0} marginTop={2} marginBottom={1}
                            >Metodo</Text>
                           <input className="inputFilter" type="text" value={Metodo} onChange={(e) => setMetodo(e.target.value)}></input>
                        </div>

                        <div className="searchContainer">
                            <Text className="boldText" color={"#808184"}
                                margin={0} padding={0} fontSize={1}
                                marginLeft={0} marginTop={2} marginBottom={1}
                            >Estado</Text>
                           <input className="inputFilter" type="text" value={Estado} onChange={(e) => setEstado(e.target.value)}></input>
                        </div>

                        <Button className="boldText searchButton"
                            width={"13%"}
                            marginLeft={"1.1%"}
                            onClick={() => { getlogs() }}
                        >
                            Buscar
                        </Button>

                    </Box>

                <ReactPaginate
                        previousLabel={"Anterior"}
                        nextLabel={"Siguiente"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"paginationBtns"}
                        previousLinkClassName={"previousBtn"}
                        nextLinkClassName={"nextBtn"}
                        disabledClassName={"paginationDisable"}
                        activeClassName={"paginationActive"}
                    />
               
                <div className="resultsTitles">

                    <p className="boldText title firstTitle">Id</p>

                    <p className="boldText title secondTitle">Clase</p>

                    <p className="boldText title secondTitle">Metodo</p>

                    <p className="boldText title secondTitle">Fecha</p>

                    <p className="boldText title fourTitle">Estado</p>

                    <p className="boldText title fiveTitle">Datos para Visualizar</p>

                </div>

                <div className="logsResults">

                    {displayOptions}

                </div>

                <Modal title={modalTitle} state={modalState} changeState={setModalState} modalType={setModalType}>
                    {modalContent}
                </Modal>             

                <Footer></Footer>
            </div>

        </div>
    );

};

export default Logs;