import React from "react";
import Box from "./box";
import Text from "./text";
import Button from "./button";

const Modal = ({ children, state, changeState, modalType, title, width, minHeight, className }) => {

    return (
        <>
            {state &&
                <div className="modalOverlay">

                    <Box className={className + ' modaPopUp'} width={width || "30%"} minHeight={minHeight || 25} backgroundColor={"#FFFFFF"}>

                        <div className="modalHeader">
                            <p className="titleModal boltText">{title}</p>
                        </div>

                        {
                            modalType ?

                                <button className={"modalCloseButton"} onClick={() => {changeState(false); modalType("")}}>
                                    X
                                </button>
                                :
                                <button className={"modalCloseButton"} onClick={() => changeState(false)}>
                                    X
                                </button>
                        }



                        {children}

                    </Box>
                </div>
            }
        </>
    );
};

export default Modal;