import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/button";
import Logo from "../assets/Logos/logoColor.png";
import Swal from 'sweetalert2';

const Validation = ({

    setValidationStatus,
    societyId,
    setAuthorizationUser,
    callback

}) => {

    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");

    const [userInfo, setUserInfo] = useState("");

    const [rol, setRol] = useState([]);

    let navigate = useNavigate();

    useEffect(() => {

    }, [])

    useEffect(() => {

        if(userInfo){
            if(userInfo?.Error === ""){
                getRol();
            };
        };

    }, [userInfo])

    useEffect(() => {

        if(rol.length > 0){

            let authorizationUser = {

                userId: userInfo?.IdUsuario,
                userName: user,
                userSociety: userInfo?.IdSociedad,
                userRolId: userInfo?.IdRol,
                userRol : rol[0],
                userToken: userInfo?.Token
                
            };

            setAuthorizationUser(authorizationUser);
            setValidationStatus(false);

        };

    }, [rol])

    async function loginUser(requestOptions) {
        return fetch(`${process.env.REACT_APP_API_URL}autenticacion/login`, requestOptions);
    };

    const handleSubmit = async event => {

        event.preventDefault();

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json;charset=utf-8");
        myHeaders.append("Accept", "application/json, text/plain, */*");

        let raw = JSON.stringify({
            "Username": user,
            "Password": password,
            "IdSociedad": societyId
        });

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        if (!user || !password) {
            Swal.fire({
                title: 'Error!',
                text: 'Debe indicar un usuario y contraseña validos',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
        } else {
            const response = (await loginUser(requestOptions));

            if (response.ok) {
                let json = await response.json();

                if (json.Error !== '') {
                    Swal.fire({
                        title: 'Error!',
                        text: json.Error,
                        icon: 'error',
                        confirmButtonText: 'Aceptar'
                    })
                } else {
                    if (json.IdUsuario != "" && json.Token != "") {
                        setUserInfo(json);
                    };
                }
            } else {
                alert("HTTP-Error: " + response.status);
            }
        };

    };

    const getRol = async () => {

        let myHeaders = new Headers();
        myHeaders.append("Authorization", 'Bearer ' + userInfo?.Token);

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        await fetch(`${process.env.REACT_APP_API_URL}admin/ListarRoles_por_Id?IdRol=${userInfo?.IdRol}`, requestOptions)
            .then(response => response.text())
            .then(result => { setRol(JSON.parse(result)); callback(JSON.parse(userInfo.IdRol), JSON.parse(result)[0].EditaAlmacen, JSON.parse(result)[0].EditaImpuesto, JSON.parse(result)[0].EditaPrecio) })
            .catch(error => console.log('error', error));
    };

    return (
        <div className="modalOverlay">
            <div className="validationAccessContainer">
                <button className={"validationAccessCloseButton"} onClick={() => setValidationStatus(false)}>
                    X
                </button>
                <form onSubmit={handleSubmit}>
                    <img src={Logo} className="imgLogo" alt="" />
                    <label className="labelLogin">Usuario</label>
                    <input className="textFields" type="text" id="username"
                        onChange={(e) => setUser(e.target.value)} name="username"></input>
                    <br></br>
                    <label className="labelLogin">Contraseña</label>
                    <input className="textFields" type="password" id="password"
                        onChange={(e) => setPassword(e.target.value)} name="password"></input>
                    <br></br>
                    <Button className="btns validationAccessAcceptButton" type="submit">
                        Ingresar
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default Validation
