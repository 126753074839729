import React from "react";

const Box = ({ className, width, height, minHeight, borderColor, marginLeft, 
    marginTop, marginRight, marginBottom, backgroundColor, children }) => {

    return (
        <div
            className={`${className}`}
            style={{
                width: `${width || "auto"}`,
                height: `${height + "vh" || "auto"}`,
                minHeight: `${minHeight + "vh" || "auto"}`,
                marginLeft: `${marginLeft}`,
                marginTop: `${marginTop + "vh" || "auto"}`,
                marginRight:`${marginRight + "vw" || "auto"}`,
                marginBottom:`${marginBottom + "vh" || "auto"}`,
                borderColor: `${borderColor}`,
                backgroundColor: `${backgroundColor}`
            }}
        >
            {children}
        </div>
    );
};

export default Box;