import React from "react";

const Text = ({className, fontSize, margin, padding, marginTop, marginLeft, marginBottom, marginRight, color, width, children, overflowWrap}) => {

    return(
        <p className={`${className}`}
        style={{
            fontSize: `${fontSize + "vw" || "auto"}`,
            padding: `${padding}`,
            margin: `${margin}`,
            marginTop: `${marginTop + "vh" || "auto"}`,
            marginLeft: `${marginLeft || "auto"}`,
            marginBottom: `${marginBottom + "vh" || "auto"}`,
            marginRight: `${marginRight + "vw" || "auto"}`,
            color: `${color}`,
            width: `${width}`,
            overflowWrap: overflowWrap
        }}
        >
            {children}
        </p>
    );
};

export default Text;