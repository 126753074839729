import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../assets/Iconos/SALIR-39.png";
import Text from "./text";
import CryptoJS from 'crypto-js';

const  Header = ({
}) =>{

    let navigate = useNavigate();

    let userData = {
        "Username": "---",
        "database": "---"
    };

    const [userInfo, setUserInfo] = useState(userData);

    useEffect(() => {

        // Decrypt
        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);
        if (items) {
            setUserInfo(items);
        }
      }, []);

    function logOut() {

        localStorage.removeItem('userData');
        navigate("/");

    };

    return (
        <header>
            <div className="row"
                style={{
                    margin: "0",
                    padding: "0",
                    width: "100%",
                    display: "flex",
                    alignItems: "center"
                }}>

                <div className="col">
                    <Text fontSize={1} color={"#FFFFFF"} className={"boldText"} marginTop={2}
                    >{userInfo.username + ' | ' + userInfo.database}</Text>
                </div>

                <div className="col"
                    style={{
                        display: "flex",
                        justifyContent: "end"
                    }}>

                    <div style={{cursor: "pointer"}} onClick={logOut}>{/*display:flex align-items center */}
                        <span className="boldText" style={{ fontSize: "1vw", color: "#FFFFFF" }}>Salir</span>
                        <img style={{ width: "1vw", marginLeft: "0.5vw", marginTop: "1px"}} src={Icon}></img>
                    </div>

                </div>

            </div>
        </header>
    );
};

export default Header