import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/sidebar";
import Header from "../components/header";
import Footer from "../components/footer";
import Box from "../components/box";
import Text from "../components/text";
import Button from "../components/button";
import Modal from "../components/modal";
import Swal from "sweetalert2";
import CryptoJS from 'crypto-js';

const Societies = ({
    societiesClass,
    sidebarClass,
    sidebarToggleClass,
    handleViewSidebar,
    societiesSelected
}) => {

    const [societies, setSocieties] = useState([]);
    const [displaySocieties, setDisplaySocieties] = useState();
    const [dateBaseName, setDateBaseName] = useState("");
    const [society, setSociety] = useState("");
    const [idSociety, setIdSociety] = useState();
    const [creationStatus, setCreationStatus] = useState("");
    const [updateStatus, setUpdateStatus] = useState("");
    const [inactiveStatus, setInactiveStatus] = useState("");
    const [modalState, setModalState] = useState(false);
    const [modalContent, setModalContent] = useState();
    const [modalType, setModalType] = useState();
    const [modalTitle, setModalTitle] = useState("");

    let navigate = useNavigate();
    
    let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
    let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
    const userDataLocalStorage = JSON.parse(originalText);

    if(userDataLocalStorage?.userRol?.ModuloAdministracion != "Y"){ navigate("/factura/crear"); }
    if(userDataLocalStorage?.userRol < 0 || userDataLocalStorage == undefined || userDataLocalStorage == null || userDataLocalStorage == '{"username":"","database":""}'){ navigate("/"); }

    useEffect(() => {

        getSocieties();
    }, [])

    useEffect(() => {

        const displayOptions = societies !== null && Array.isArray(societies) ? societies.map((societie) => {
            return (
                <Box key={societie.Id} className="filters" width={"95%"} marginLeft={"2.5%"} marginTop={2}
                    marginRight={0} marginBottom={0} minHeight={7}
                    backgroundColor="#FFFFFF">

                    <p className="result text firstColummn">{societie.NombreBaseDatos}</p>

                    <p className="result text secondColummn">{societie.NombreSociedad}</p>

                    <p className="result text firstColummn">{societie.NombreUsuarioCreacion}</p>

                    <button className="resultBtn editBtn boldText"
                        onClick={() => { setModalTitle("Editar"); setIdSociety(societie.Id); setDateBaseName(societie.NombreBaseDatos);
                        setSociety(societie.NombreSociedad); setModalType("editar") }}>Editar</button>

                    <button className="resultBtn deleteBtn boldText"
                        onClick={() => { setModalTitle("Eliminar sociedad"); setIdSociety(societie.Id); setModalType("eliminar"); }}>Eliminar</button>

                </Box>
            );
        }) : <></>;

        setDisplaySocieties(displayOptions);

    }, [societies])

    useEffect(() => {

        if (creationStatus === "Ok") {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: 'Sociedad creada correctamente'
            })

            setCreationStatus("");
            getSocieties();
        };

    }, [creationStatus])

    useEffect(() => {

        if (updateStatus === "Ok") {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: 'Sociedad actualizada correctamente'
            })

            setUpdateStatus("");
            getSocieties();
        };

    }, [updateStatus])

    useEffect(() => {

        if (inactiveStatus === "Ok") {

            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: 'Sociedad eliminada correctamente'
            })

            setInactiveStatus("");
            getSocieties();
        };

    }, [inactiveStatus])

    useEffect(() => {

        if (modalType === "crear") {
            setModalContent(<Fragment>
                <p className="modalText text">Nombre de la Base de datos</p>

                <input onChange={(e) => { setDateBaseName(e.target.value) }} name="dbName"></input>

                <p className="modalText text">Nombre de la sociedad</p>
                <input onChange={(e) => { setSociety(e.target.value) }} name="societyName"></input>

                <div className="modalBtnsBox">
                    <button className="boldText btnModal cancelBtn"
                        onClick={() => setModalType("")}>Cancelar</button>
                    <button className="boldText btnModal submitBtn"
                        onClick={() => { createSociety() }}>Agregar</button>
                </div>
            </Fragment>);

            setModalState(true);

        } else if (modalType === "eliminar") {

            inactiveWarning();

        } else if (modalType === "editar") {
            setModalContent(<Fragment>

                <p className="modalText text">Nombre de la Base de datos</p>

                <input onChange={(e) => { setDateBaseName(e.target.value) }} defaultValue={dateBaseName} name="dbName"></input>

                <p className="modalText text">Nombre de la sociedad</p>
                <input onChange={(e) => { setSociety(e.target.value) }} defaultValue={society} name="societyName"></input>

                <div className="modalBtnsBox">
                    <button className="boldText btnModal cancelBtn"
                        onClick={() => { setModalType("") }}>Cancelar</button>
                    <button className="boldText btnModal submitBtn"
                        onClick={() => { updateSociety() }}>Editar</button>
                </div>

            </Fragment>);

            setModalState(true);

        } else if (modalType === "") {

            setDateBaseName("");
            setSociety("");
            setIdSociety();
            setModalState(false);


        };

    }, [modalType, dateBaseName, society])

    const showErrorMessage = (errorMessage) => {

        if (errorMessage !== null && errorMessage !== "") {
            Swal.fire({
                title: 'Error!',
                text: errorMessage,
                icon: 'error',
                confirmButtonText: 'Aceptar'
            })
        };

    };

    const inactiveWarning = () => {

        Swal.fire({
            title: '¡Advertencia!',
            text: "¿Está seguro de querer eliminar esta sociedad?",
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Aceptar',
            denyButtonText: 'Cancelar'

        }).then((result) => {

            if (result.isConfirmed) {

                inactiveSociety();
            } else if (result.isDenied) {
                setModalType("");
                setDateBaseName("");
                setSociety("");
                setIdSociety();
            }
        });

    };

    const getSocieties = async () => {

        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        await fetch(`${process.env.REACT_APP_API_URL}admin/ListarSociedades`, requestOptions)
            .then(response => response.text())
            .then(result => setSocieties(JSON.parse(result)))
            .catch(error => showErrorMessage(error));

    };

    const createSociety = async () => {

        let myHeaders = new Headers();

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        myHeaders.append("Authorization", 'Bearer ' + items.token);
        myHeaders.append("Content-Type", "application/json");

        if (dateBaseName !== "" && society !== "") {

            let raw = JSON.stringify({
                "NombreBaseDatos": dateBaseName,
                "NombreSociedad": society,
                "IdUsuarioCreacion": items.userId,
                "NombreUsuarioCreacion": items.Username,
                "Activo": 1
            });

            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            await fetch(`${process.env.REACT_APP_API_URL}admin/InsertarSociedades`, requestOptions)
                .then(response => response.text())
                .then(result => setCreationStatus(JSON.parse(result)))
                .catch(error => showErrorMessage(error));

            setModalType("");
            setDateBaseName("");
            setSociety("");
            setIdSociety();
        };

    };

    const updateSociety = async () => {

        let myHeaders = new Headers();

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        myHeaders.append("Authorization", 'Bearer ' + items.token);
        myHeaders.append("Content-Type", "application/json");

        if (dateBaseName !== "" && society !== "") {
            let raw = JSON.stringify({
                "Id": idSociety,
                "NombreBaseDatos": dateBaseName,
                "NombreSociedad": society,
                "IdUsuarioCreacion": items.userId,
                "NombreUsuarioCreacion": items.Username,
                "Activo": 1
            });

            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            await fetch(`${process.env.REACT_APP_API_URL}admin/ActualizarSociedad`, requestOptions)
                .then(response => response.text())
                .then(result => setUpdateStatus(JSON.parse(result)))
                .catch(error => showErrorMessage(error));

            setModalType("");
            setDateBaseName("");
            setSociety("");
            setIdSociety();
        };

    };

    const inactiveSociety = async () => {

        let myHeaders = new Headers();

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        myHeaders.append("Authorization", 'Bearer ' + items.token);
        myHeaders.append("Content-Type", "application/json");

        let raw = JSON.stringify({
            "Id": idSociety,
            "Activo": 0
        });

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        await fetch(`${process.env.REACT_APP_API_URL}admin/Inactivar_Activar_Sociedades`, requestOptions)
            .then(response => response.text())
            .then(result => setInactiveStatus(JSON.parse(result)))
            .catch(error => showErrorMessage(error));

        setModalType("");
        setDateBaseName("");
        setSociety("");
        setIdSociety();
        getSocieties();
    };

    return (
        <div style={{ display: "flex" }}>
            <Sidebar sidebarClass={sidebarClass} handleViewSidebar={handleViewSidebar}
                societiesSelected={societiesSelected} sidebarToggleClass={sidebarToggleClass} />

            <div className={societiesClass}>
                <Header></Header>

                <h2 className="boldText societyTitle">Lista de sociedades</h2>

                <button className="addSocietiesBtn boldText"
                    onClick={() => { setModalTitle("Crear"); setModalType("crear") }}>Agregar nueva sociedad</button>

                <div className="resultsTitles">

                    <p className="boldText title firstTitle">Bases de datos</p>

                    <p className="boldText title secondTitle">Nombre de la sociedad</p>

                    <p className="boldText title secondTitle">Usuario</p>

                </div>

                <div className="societiesResults">

                    {displaySocieties}

                </div>

                <Modal className={"createInputs"} title={modalTitle} state={modalState} changeState={setModalState} modalType={setModalType}>
                    {modalContent}
                </Modal>

                <Footer></Footer>
            </div>

        </div>
    );

};

export default Societies;
