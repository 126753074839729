import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';
import Select from 'react-select';
import CryptoJS from 'crypto-js';


function SelectProducts({ index, clientCode, handleChange, value }) {

    const [productsList, setProductsList] = useState([{ value: 'none', label: 'Buscando...' },]);

    const getProduct = async (productCode) => {
        if (productCode !== "") {

            // Decrypt
            let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
            let originalText = bytes.toString(CryptoJS.enc.Utf8);
            
            const items = JSON.parse(originalText);

            fetch(`${process.env.REACT_APP_API_URL}facturacion/ConsultaArticulos_por_Codigo?CardCode=${clientCode}&Codigo=${"" + productCode}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + items?.token
                }
            }).then(res => res.json())
                .then(response => {
                    if (response?.length > 0) {
                        let list = [];
                        setProductsList([{ value: 'none', label: 'Buscando...' },]);
                        response.forEach(element => {
                            list.push({ value: element.Articulo, label: element.Articulo, data: element });
                        });
                        console.log('Lista Artículos', list);
                        setProductsList(list);
                    };
                })
                .catch(error => console.error('Error:', error));
        };
    };

    return (
        <Select id={uuidv4()} name="ARTICULO_DESCRIPCION" options={productsList} className="selectProducts"
        filterOption={(e)=>{return true;}}
        menuPosition={'fixed'}
        menuPortalTarget={document.body}
        menuShouldBlockScroll={true}
            onInputChange={(e) => {
                getProduct(e);
            }}
            onChange={(evnt) => {
                handleChange(index, evnt);
            }}
            placeholder={value == "" ? 'Buscar...' : value}
            styles={{zIndex: 2}}
        ></Select>
    )
}

export default SelectProducts;