import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/login";
import Logs from "./pages/logs";
import ProformaSearch from "./pages/proformaSearch";
import ProformaCreate from "./pages/proformaCreate";
import BillSearch from "./pages/billSearch";
import BillCreate from "./pages/billCreate";
import Societies from "./pages/societies";
import Users from "./pages/users";
import Roles from "./pages/roles";
import Assigments from "./pages/assigments";
import Stores from "./pages/stores";
import Redirect from "./pages/redirect";

function App() {

  const [sidebarOpen, setSideBarOpen] = useState(true);
  const handleViewSidebar = () => {
    setSideBarOpen(!sidebarOpen);
  };

  const sidebarClass = sidebarOpen ? "sidebar open" : "sidebar";
  const sidebarToggleClass = sidebarOpen ? "sidebar-toggle" : "sidebar-toggle open";
  const proformaSearchClass = sidebarOpen ? "proformaSearch open" : "proformaSearch";
  const proformaCreateClass = sidebarOpen ? "proformaCreate open" : "proformaCreate";
  const billSearchClass = sidebarOpen ? "billSearch open" : "billSearch";
  const billCreateClass = sidebarOpen ? "billCreate open" : "billCreate";
  const societiesClass = sidebarOpen ? "societies open" : "societies";
  const usersClass = sidebarOpen ? "users open" : "users";
  const roleClass = sidebarOpen ? "role open" : "role";
  const assignmentClass = sidebarOpen ? "assigment open" : "assigment";
  const storeClass = sidebarOpen ? "store open" : "store";
  const logsClass = sidebarOpen ? "logs open" : "logs";

  return (

    <Router>
      <Routes>
        <Route path="*" element={<BillCreate billCreateClass={billCreateClass}
            sidebarToggleClass={sidebarToggleClass}
            sidebarClass={sidebarClass} handleViewSidebar={handleViewSidebar}
            billCreateSelected={"billCreateSelected"} />} />
        <Route path="/" element={<Login />}></Route>  
        <Route path="logs" element={<Logs logsClass={logsClass}
            sidebarToggleClass={sidebarToggleClass}
            sidebarClass={sidebarClass} handleViewSidebar={handleViewSidebar}
            logsSelected={"logsSelected"} />}></Route>   
        <Route path="/proforma">
          <Route path="buscar" element={<ProformaSearch proformaSearchClass={billSearchClass}
            sidebarToggleClass={sidebarToggleClass}
            sidebarClass={sidebarClass} handleViewSidebar={handleViewSidebar}
            proformaSearchSelected={"proformaSearchSelected"} />}></Route>

          <Route path="buscar/:last" element={<ProformaSearch proformaSearchClass={billSearchClass}
            sidebarToggleClass={sidebarToggleClass}
            sidebarClass={sidebarClass} handleViewSidebar={handleViewSidebar}
            proformaSearchSelected={"proformaSearchSelected"} />}></Route>

          <Route path="crear" element={<ProformaCreate proformaCreateClass={billCreateClass}
            sidebarToggleClass={sidebarToggleClass}
            sidebarClass={sidebarClass} handleViewSidebar={handleViewSidebar}
            proformaCreateSelected={"proformaCreateSelected"} />}></Route>

          <Route path="editar/:id" element={<ProformaCreate proformaCreateClass={billCreateClass}
            sidebarToggleClass={sidebarToggleClass}
            sidebarClass={sidebarClass} handleViewSidebar={handleViewSidebar}
            proformaCreateSelected={"proformaCreateSelected"} />}></Route>

          <Route path="crear/:id/:duplicate" element={<ProformaCreate proformaCreateClass={billCreateClass}
            sidebarToggleClass={sidebarToggleClass}
            sidebarClass={sidebarClass} handleViewSidebar={handleViewSidebar}
            proformaCreateSelected={"proformaCreateSelected"} />}></Route>
        </Route>

        <Route path="/factura">
          <Route path="buscar" element={<BillSearch billSearchClass={billSearchClass}
            sidebarToggleClass={sidebarToggleClass}
            sidebarClass={sidebarClass} handleViewSidebar={handleViewSidebar}
            billSearchSelected={"billSearchSelected"} />}></Route>

          <Route path="buscar/:last" element={<BillSearch billSearchClass={billSearchClass}
            sidebarToggleClass={sidebarToggleClass}
            sidebarClass={sidebarClass} handleViewSidebar={handleViewSidebar}
            billSearchSelected={"billSearchSelected"} />}></Route>

          <Route path="crear" element={<BillCreate billCreateClass={billCreateClass}
            sidebarToggleClass={sidebarToggleClass}
            sidebarClass={sidebarClass} handleViewSidebar={handleViewSidebar}
            billCreateSelected={"billCreateSelected"} />}></Route>

          <Route path="editar/:id" element={<BillCreate billCreateClass={billCreateClass}
            sidebarToggleClass={sidebarToggleClass}
            sidebarClass={sidebarClass} handleViewSidebar={handleViewSidebar}
            billCreateSelected={"billCreateSelected"} />}></Route>

          <Route path="crear/:id/:duplicate" element={<BillCreate billCreateClass={billCreateClass}
            sidebarToggleClass={sidebarToggleClass}
            sidebarClass={sidebarClass} handleViewSidebar={handleViewSidebar}
            billCreateSelected={"billCreateSelected"} />}></Route>
        </Route>

        <Route path="/administracion">
          <Route path="sociedades" element={<Societies societiesClass={societiesClass}
            sidebarToggleClass={sidebarToggleClass}
            sidebarClass={sidebarClass} handleViewSidebar={handleViewSidebar}
            societiesSelected={"societiesSelected"} />}></Route>

          <Route path="usuarios" element={<Users usersClass={usersClass}
            sidebarToggleClass={sidebarToggleClass}
            sidebarClass={sidebarClass} handleViewSidebar={handleViewSidebar}
            usersSelected={"usersSelected"} />}></Route>

          <Route path="roles" element={<Roles roleClass={roleClass}
            sidebarToggleClass={sidebarToggleClass}
            sidebarClass={sidebarClass} handleViewSidebar={handleViewSidebar}
            roleSelected={"roleSelected"} />}></Route>

          <Route path="asignaciones" element={<Assigments assignmentClass={assignmentClass}
            sidebarToggleClass={sidebarToggleClass}
            sidebarClass={sidebarClass} handleViewSidebar={handleViewSidebar}
            assigmentSelected={"assigmentSelected"} />}></Route>

          <Route path="almacenes" element={<Stores storeClass={storeClass}
            sidebarToggleClass={sidebarToggleClass}
            sidebarClass={sidebarClass} handleViewSidebar={handleViewSidebar}
            storeSelected={"storeSelected"} />}></Route>
        </Route>

        <Route path="/redirect/:link_parent/:link_children" element={<Redirect billSearchClass={billSearchClass}
          sidebarToggleClass={sidebarToggleClass}
          sidebarClass={sidebarClass} handleViewSidebar={handleViewSidebar}
          billSearchSelected={"billSearchSelected"} />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
