import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../components/sidebar";
import Header from "../components/header";
import Footer from "../components/footer";
import Box from "../components/box";
import Text from "../components/text";
import Button from "../components/button";
import editIcon from "../assets/Iconos/edit negro.png";
import duplicateIcon from "../assets/Iconos/dupl negro.png";
import printIcon from "../assets/Iconos/print negro.png";
import cancelIcon from "../assets/Iconos/cancel negro.png";
import ReactPaginate from "react-paginate";
import DatePicker from 'react-date-picker';
import Swal from "sweetalert2";
import Select from 'react-select';
import CryptoJS from 'crypto-js';

const ProformaSearch = ({
    proformaSearchClass,
    sidebarClass,
    sidebarToggleClass,
    handleViewSidebar,
    proformaSearchSelected
}) => {

    const [data, setData] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [vendorList, setVendorList] = useState();
    const [clientsResult, setClientsResult] = useState([]);
    const [clientsList, setClientsList] = useState([]);
    const [clientsByCardCode, setClientsByCardCode] = useState("");
    const [clientCardName, setClientCardName] = useState("");
    const [noDoc, setNoDoc] = useState('');
    const [clientCardCode, setClientCardCode] = useState("");
    const [vendorId, setVendorId] = useState("");
    const [sinceDate, setSinceDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [stateValue, setStateValue] = useState("O");
    const [selectSearchValue, setSelectSearchValue] = useState({ value: 'Seleccione', label: 'Seleccione' });
    const [pdf, setPdf] = useState("");
    const dataPerPage = 10;
    const pagesVisited = pageNumber * dataPerPage;
    const [processingStatus, setProcessingStatus] = useState(false);
    let displayData = useRef(null);

    const { last } = useParams();

    const [isOkayFetchGetBill, setIsOkayFetchGetBill] = useState(false);
    let intervalGetBill = useRef(null);

    let count = useRef(0);

    let navigate = useNavigate();

    let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
    let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
    const userDataLocalStorage = JSON.parse(originalText);

    const [input, setInput] = useState("");
    const [inputSave, setSave] = useState("");

    if(userDataLocalStorage?.userRol < 0 || userDataLocalStorage?.userRol?.ModuloProformas != "Y" || userDataLocalStorage == undefined || userDataLocalStorage == null || userDataLocalStorage == '{"username":"","database":""}'){ navigate("/"); }

    useEffect(() => {
        getVendors();

        setTimeout(() => {
            if (!last && count.current == 0){
                count.current = 1;
                getBills();
            }
        }, 300)
    }, [])

    useEffect(() => {
        if (clientsByCardCode === "") {
            setClientsList([]);
        } else {
            getClients(clientsByCardCode);
        };
    }, [clientsByCardCode])

    useEffect(() => {

        if (clientsResult !== null && Array.isArray(clientsResult)) {

            let list = [];

            for (let i = 0; i < clientsResult.length; i++) {
                let client = { value: clientsResult[i].CardCode, label: clientsResult[i].Cliente, name: clientsResult[i].CardName };

                list.push(client);
            };

            setClientsList(list);
        };

    }, [clientsResult])

    useEffect(() => {
        if (last && count.current == 0) {
            count.current = 1;
            setNoDoc(last);

            setTimeout(() => {
                getBills(last);
            }, 200)
        };
    }, [last])

    useEffect(() => {

        if (pdf !== "") {

            window.open(process.env.REACT_APP_PRINTPDF + pdf, '_blank');
            setPdf("");
        };

    }, [pdf])

    useEffect(() => {

        if(displayData != null){

            setProcessingStatus(false); 
        }

        if(data == -1){
            setData([]);
        }
        
    },[data])

    useEffect(() => {

        if(isOkayFetchGetBill){
            setIsOkayFetchGetBill(false);
            return ()=>clearInterval(intervalGetBill.current);
        }

    }, [isOkayFetchGetBill])

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    const formatDateBasic = (date) => {
        return [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
        ].join('/');
    }

    const displayVendors = vendorList !== null && Array.isArray(vendorList) ? vendorList.map((vendor) => {
        return (
            <option key={vendor.Codigo} value={vendor.Codigo} style={{ fontSize: "1vw" }}>{vendor.Nombre}</option>
        );
    }) : <></>;

    displayData = data !== null && Array.isArray(data) ? data.slice(pagesVisited, pagesVisited + dataPerPage)
        .map((data) => {
            return (
                <Box key={data.id} className="resultsFilters" width={"95%"} marginLeft={"2.5%"} marginTop={2}
                    marginRight={0} marginBottom={0}
                    backgroundColor="#FFFFFF">
                    <Text className="boldText" color={"#9A9DA4"}
                        marginRight={0} padding={0} fontSize={1} 
                        marginLeft={"1%"} marginTop={0} marginBottom={0}
                        width={"7.5%"}
                    >{data.DocNum}</Text>

                    <Text className="boldText" color={"#9A9DA4"}
                        marginRight={0} padding={0} fontSize={1}
                        marginLeft={"2%"} marginTop={0} marginBottom={0}
                        width={"7%"}
                    >{data.Fecha_Hora}</Text>

                    <Text className="boldText" color={"#9A9DA4"}
                        marginRight={0} padding={0} fontSize={1}
                        marginLeft={"1%"} marginTop={0} marginBottom={0}
                        width={"10%"} overflowWrap={"break-word"}
                    >{data.Cliente}</Text>

                    <Text className="boldText" color={"#9A9DA4"}
                        marginRight={0} padding={0} fontSize={1}
                        marginLeft={"1%"} marginTop={0} marginBottom={0}
                        width={"21%"}
                    >{data.Comentarios}</Text>

                    <Text className="boldText" color={"#9A9DA4"}
                        marginRight={0} padding={0} fontSize={1}
                        marginLeft={"2%"} marginTop={0} marginBottom={0}
                        width={"10%"}
                    >{data.Total.toLocaleString("en-US")}</Text>

                    <Text className="boldText" color={"#9A9DA4"}
                        marginRight={0} padding={0} fontSize={1}
                        marginLeft={"2%"} marginTop={0} marginBottom={0}
                        width={"9%"}
                    >{data.Vendedor}</Text>

                    <Text className="boldText" color={"#9A9DA4"}
                        marginRight={0} padding={0} fontSize={1}
                        marginLeft={"1%"} marginTop={0} marginBottom={0}
                        width={"6%"}
                    >{data.Estado}</Text>

                    <button className="imgButton imgButtonLitteMargin" onClick={() => { navigate(`/proforma/editar/${data.DocNum}`) }}><img className="img" src={editIcon}></img></button>

                    <button className="imgButton" onClick={() => { navigate(`/proforma/crear/${data.DocNum}/true`) }}><img className="img" src={duplicateIcon}></img></button>

                    <button className="imgButton" onClick={() => { printDoc(data.DocEntry) }}><img className="img" src={printIcon}></img></button>

                    <button className="imgButton" onClick={() => { cancelOrder(data.DocEntry) }}><img className="img" src={cancelIcon}></img></button>
                    {/* navigate(`/factura/crear/${data.DocNum}/false`)  */}
                </Box>
            );
        }) : <></>;

    const pageCount = Math.ceil(data.length / dataPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected)
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            setClientCardCode("");
            setSelectSearchValue({ value: 'Seleccione', label: 'Seleccione' })
        }
    };

    const showCancelStatus = (cancelStatus) => {

        if (cancelStatus === "Ok") {

            getBills();

            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: `Factura cancelada correctamente`
            })

        } else {
            console.log(cancelStatus);
        };

    };

    const showErrorMessage = (errorMessage) => {

        let split = errorMessage.split('/');

        if (errorMessage == "Token -1" || (split[1] && split[1] == "")) {
            Swal.fire({
                title: 'Se venció la sesión!',
                html: "Debes iniciar sesión nuevamente" + '<br/> serás redirigido automáticamente en 3 segundos...',
                timer: 3000,
                icon: 'error',
                showConfirmButton: false
            }).then((result) => {
                navigate("/");
            });
        } else if (errorMessage !== null && errorMessage !== "") {
            Swal.fire({
                title: 'Error!',
                text: errorMessage,
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
        };
    };

    async function getVendors() {

        let securityToken = "";

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        if (items && items.token) {
            securityToken = items.token;

            let myHeaders = new Headers();
            myHeaders.append("Authorization", 'Bearer ' + securityToken);
            myHeaders.append("Accept", "application/json, */*");

            let requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            await fetch(`${process.env.REACT_APP_API_URL}cotizacion/ListarVendedores_Cotizacion`, requestOptions)
                .then(response => response.text())
                .then(result => setVendorList(JSON.parse(result)))
                .catch(error => showErrorMessage(error + '/' + securityToken));
        } else {
            showErrorMessage("Token -1");
        }

    };

    const addZero = (num) => {
        return (num >= 0 && num < 10) ? "0" + num : num + "";
    }

    const formatDate = (newDate) => {
        let documentDatetime = new Date(newDate);
        let strDateTime = [
            [documentDatetime.getFullYear(),
                addZero(documentDatetime.getMonth() + 1),
            addZero(documentDatetime.getDate())
            ].join("-"),
            [addZero(documentDatetime.getHours()),
            addZero(documentDatetime.getMinutes()),
            addZero(documentDatetime.getSeconds()) + "." + addZero(documentDatetime.getMilliseconds())].join(":")].join(" ");

        return strDateTime;
    }

    async function getBills(auxNoDoc = null) {

        setProcessingStatus(true);

        let securityToken = "";

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        if (items && items.token) {
            securityToken = items.token;

            let myHeaders = new Headers();
            myHeaders.append("Authorization", 'Bearer ' + securityToken);
            myHeaders.append("Content-Type", "application/json");

            let tempDate = new Date(formatDateBasic(new Date()));

            let raw = {
                "DocNum": auxNoDoc ? auxNoDoc : (noDoc === "" ? null : noDoc),
                "CardCode": clientCardCode === "Seleccione" ? "" : clientCardCode,
                "CardName": clientCardName,
                "StartDate": auxNoDoc ? 
                (formatDate(tempDate)) : 
                (sinceDate.toISOString() == toDate.toISOString() ? 
                        (formatDate(tempDate)) : 
                        (formatDate(sinceDate))),
                "EndDate": formatDate(toDate),
                "SlpCode": vendorId === "-1" ? "" : vendorId,
                "DocStatus": stateValue
            };

            //console.log('DATOS DEL getBills en proformaSearch',JSON.stringify(raw));

            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(raw),
                redirect: 'follow'
            };

            intervalGetBill.current = setInterval(async () => {

                fetch(`${process.env.REACT_APP_API_URL}cotizacion/ConsultaOrdenesVenta_Cotizacion`, requestOptions)
                    .then(response => response.text())
                    .then(result => {setData(JSON.parse(result)); setIsOkayFetchGetBill(true); setClientCardCode(""); setClientCardName(""); })
                    .catch(error => {showErrorMessage(error + '/' + securityToken); setClientCardCode(""); setClientCardName(""); });
            }, 1500);


        } else {
            showErrorMessage("Token -1");
        }
    };

    async function getClients(cardCode) {

        let securityToken = "";

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        if (items && items.token) {
            securityToken = items.token;

            let myHeaders = new Headers();
            myHeaders.append("Authorization", 'Bearer ' + securityToken);

            let requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_API_URL}bp/ConsultaSocioNegocio_por_CardCode?CardCode=${cardCode}`, requestOptions)
                .then(response => response.text())
                .then(result => setClientsResult(JSON.parse(result)))
                .catch(error => showErrorMessage(error + '/' + securityToken));
        } else {
            showErrorMessage("Token -1");
        }
    };

    const printDoc = async (docEntry) => {

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        if (items && items.token) {

            let myHeaders = new Headers();
            myHeaders.append("Authorization", 'Bearer ' + items.token);
            myHeaders.append("Content-Type", "application/json");
            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };

            await fetch(`${process.env.REACT_APP_API_URL}facturacion/ImprimirDocumento?docEntry=${docEntry}&tipoDocumento=2`, requestOptions)
                .then(response => response.text())
                .then(result => setPdf(JSON.parse(result)))
                .catch(error => console.log('error', error));
        };

    };

    const cancelOrder = async (docEntry) => {

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        if (items && items.token) {

            let myHeaders = new Headers();
            myHeaders.append("Authorization", 'Bearer ' + items.token);
            myHeaders.append("Content-Type", "application/json");
            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };

            await fetch(`${process.env.REACT_APP_API_URL}cotizacion/UpdateStatusOrder_Cotizacion?DocEntry=${docEntry}&Estado=C`, requestOptions)
                .then(response => response.text())
                .then(result => showCancelStatus(JSON.parse(result)))
                .catch(error => console.log('error', error));

        };

    };

    return (
        <div style={{ display: "flex" }}>
            <Sidebar sidebarClass={sidebarClass} handleViewSidebar={handleViewSidebar}
                proformaSearchSelected={proformaSearchSelected} sidebarToggleClass={sidebarToggleClass} />

            <div className={proformaSearchClass}>
                <Header></Header>

                <div>
                    <p className="boldText pagesTitle">Búsqueda de proformas</p>
                </div>

                <div style={{ marginBottom: "10vh" }}>
                    <Box className="searchFilters" width={"95%"} height={15} marginLeft={"2.5%"} marginTop={5}
                        backgroundColor="#FFFFFF">

                        <div className="searchContainer">
                            <Text className="boldText" color={"#808184"}
                                margin={0} padding={0} fontSize={1}
                                marginLeft={0} marginTop={2} marginBottom={1}
                            >No. Doc</Text>
                            <input
                                className="inputFilter searchInputHeight" type="text" value={noDoc} onChange={(e) => setNoDoc(e.target.value)}></input>
                        </div>

                        {/* <div className="searchContainer">
                            <Text className="boldText" color={"#808184"}
                                margin={0} padding={0} fontSize={1}
                                marginLeft={0} marginTop={2} marginBottom={1}
                            >Cliente</Text>
                            <Select 
                                className="selectSearch" 
                                value={selectSearchValue} 
                                options={clientsList} 
                                onKeyDown={handleKeyDown} 
                                //onBlur={(value) => {setSelectSearchValue({ value: 'Seleccione', label: 'Seleccione' })}}
                                onInputChange={(value, action) => {
                                    setClientsByCardCode(value); console.log('onInputChange', action);
                                    if (action.action === "input-change") {setInput(value); setClientCardName(value); }
                                    //if(action.action === "menu-close"){setInput(action.prevInputValue); console.log('VALUEEEEEEEEEEE', action.prevInputValue);}
                                }} 
                                inputValue={input}
                                //blurInputOnSelect
                                onChange={(e) => { setClientCardCode(e.value); setSelectSearchValue({ value: e.value, label: e.label }); setClientCardName(e.name); }} 
                            />
                        </div> */}

                        <div className="searchContainer">
                            <Text className="boldText" color={"#808184"}
                                margin={0} padding={0} fontSize={1}
                                marginLeft={0} marginTop={2} marginBottom={1}
                            >Cliente</Text>
                            <Select 
                                className="selectSearch" 
                                placeholder={inputSave}
                                value=""
                                inputValue={input}
                                options={clientsList}
                                onInputChange={(value, action) => {
                                    setClientsByCardCode(value); setInput(value);
                                    if (action.action === "input-change") {setClientCardName(value); }
                                }} 
                                onKeyDown={handleKeyDown} 
                                onMenuClose={() => setSave(input)}
                                onFocus={() => {
                                    setInput(inputSave);
                                    setSave("");
                                }}
                                blurInputOnSelect
                                onChange={(e) => { setClientCardCode(e.value); setSelectSearchValue({ value: e.value, label: e.label }); setClientCardName(e.name); }} 
                            />
                        </div>

                        <div className="searchContainer">
                            <Text className="boldText" color={"#808184"}
                                margin={0} padding={0} fontSize={1}
                                marginLeft={0} marginTop={2} marginBottom={1}
                            >Vendedor</Text>
                            <select className="selectFilter" aria-label="size 3 select example" onChange={(e) => setVendorId(e.target.value)}>
                                {displayVendors}
                            </select>
                        </div>

                        <div className="searchContainer">
                            <Text className="boldText" color={"#808184"}
                                margin={0} padding={0} fontSize={1}
                                marginLeft={0} marginTop={2} marginBottom={1}
                            >Desde</Text>
                            <DatePicker onChange={(e) => setSinceDate(e)} value={sinceDate} className="datePicker" maxDate={toDate}/>
                        </div>

                        <div className="searchContainer">
                            <Text className="boldText" color={"#808184"}
                                margin={0} padding={0} fontSize={1}
                                marginLeft={0} marginTop={2} marginBottom={1}
                            >Hasta</Text>
                            <DatePicker onChange={(e) => setToDate(e)} value={toDate} className="datePicker" maxDate={new Date()}
                            minDate={sinceDate}/>
                        </div>

                        <div className="searchContainer">
                            <Text className="boldText" color={"#808184"}
                                margin={0} padding={0} fontSize={1}
                                marginLeft={0} marginTop={2} marginBottom={1}
                            >Estado</Text>
                            <select className="selectFilter" aria-label="size 3 select example" onChange={(e) => setStateValue(e.target.value)}>
                                <option value="O" >Abierto</option>
                                <option value="C" >Cerrado</option>
                            </select>
                        </div>

                        <Button className="boldText searchButton"
                            width={"13%"}
                            marginLeft={"1.1%"}
                            onClick={() => { getBills() }}
                        >
                            Buscar
                        </Button>
                    </Box>

                    <Text className="boldText" color="#000000" fontSize={1}
                        marginLeft={"4.5vw"} marginTop={4}>Documentos</Text>

                    <ReactPaginate
                        previousLabel={"Anterior"}
                        nextLabel={"Siguiente"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"paginationBtns"}
                        previousLinkClassName={"previousBtn"}
                        nextLinkClassName={"nextBtn"}
                        disabledClassName={"paginationDisable"}
                        activeClassName={"paginationActive"}
                        initialPage={0}
                    />

                    <div className="resultsTitles">

                        <Text className="boldText" color={"#808184"}
                            marginRight={0} padding={0} fontSize={1}
                            marginLeft={"1%"} marginTop={3} marginBottom={1}
                            width={"7.5%"}
                        >No. Doc</Text>

                        <Text className="boldText" color={"#808184"}
                            marginRight={0} padding={0} fontSize={1}
                            marginLeft={"2%"} marginTop={3} marginBottom={1}
                            width={"7%"}
                        >Fecha</Text>

                        <Text className="boldText" color={"#808184"}
                            marginRight={0} padding={0} fontSize={1}
                            marginLeft={"0.5%"} marginTop={3} marginBottom={1}
                            width={"10%"}
                        >Cliente</Text>

                        <Text className="boldText" color={"#808184"}
                            marginRight={0} padding={0} fontSize={1}
                            marginLeft={"1%"} marginTop={3} marginBottom={1}
                            width={"20%"}
                        >Comentarios</Text>

                        <Text className="boldText" color={"#808184"}
                            marginRight={0} padding={0} fontSize={1}
                            marginLeft={"2%"} marginTop={3} marginBottom={1}
                            width={"10%"}
                        >Total</Text>

                        <Text className="boldText" color={"#808184"}
                            marginRight={0} padding={0} fontSize={1}
                            marginLeft={"2%"} marginTop={3} marginBottom={1}
                            width={"9%"}
                        >Vendedor</Text>

                        <Text className="boldText" color={"#808184"}
                            marginRight={0} padding={0} fontSize={1}
                            marginLeft={"0.5%"} marginTop={3} marginBottom={1}
                            width={"6%"}
                        >Estado</Text>

                        <Text className="boldText" color={"#808184"}
                            marginRight={0} padding={0} fontSize={1}
                            marginLeft={"1.5%"} marginTop={3} marginBottom={1}
                            width={"3%"}
                        >EDIT.</Text>

                        <Text className="boldText" color={"#808184"}
                            marginRight={0} padding={0} fontSize={1}
                            marginLeft={"1.5%"} marginTop={3} marginBottom={1}
                            width={"3%"}
                        >Dupl.</Text>

                        <Text className="boldText" color={"#808184"}
                            marginRight={0} padding={0} fontSize={1}
                            marginLeft={"1.5%"} marginTop={3} marginBottom={1}
                            width={"3%"}
                        >Impr.</Text>

                        <Text className="boldText" color={"#808184"}
                            marginRight={0} padding={0} fontSize={1}
                            marginLeft={"1.5%"} marginTop={3} marginBottom={1}
                            width={"3%"}
                        >Cancel</Text>

                    </div>

                    <div className="results">

                        {displayData}

                    </div>

                </div>

                <div className="textAlign">
                    { processingStatus == true &&

                            <div className="modalOverlay ">
                                <div>

                                    <p className="boldText processing">Procesando</p>

                                    <div className="spinner-border text-light procecessingSpinner" role="status">

                                    </div>

                                </div>

                            </div>
                    }

                </div>

                <Footer></Footer>
            </div>

        </div>
    );

};

export default ProformaSearch;