import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/sidebar";
import Header from "../components/header";
import Footer from "../components/footer";
import Box from "../components/box";
import Text from "../components/text";
import Button from "../components/button";
import Modal from "../components/modal";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import CryptoJS from 'crypto-js';

const Users = ({
    usersClass,
    sidebarClass,
    sidebarToggleClass,
    handleViewSidebar,
    usersSelected
}) => {

    const [data, setData] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [userUid, setUserUid] = useState("");
    const [nameUser, setNameUser] = useState("");
    const [passwordUser, setPasswordUser] = useState("");
    const [activeUser, setActiveUser] = useState(false);
    const [inactiveStatus, setInactiveStatus] = useState("");
    const [modalState, setModalState] = useState(false);
    const [modalContent, setModalContent] = useState();
    const [modalType, setModalType] = useState();
    const [creationStatus, setCreationStatus] = useState("");
    const [updateStatus, setUpdateStatus] = useState("");
    const [changePasswordStatus, setChangePasswordStatus] = useState("");

    let navigate = useNavigate();

    let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
    let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
    const userDataLocalStorage = JSON.parse(originalText);
    if(userDataLocalStorage?.userRol?.ModuloAdministracion != "Y"){ navigate("/factura/crear"); }
    if(userDataLocalStorage?.userRol < 0 || userDataLocalStorage == undefined || userDataLocalStorage == null || userDataLocalStorage == '{"username":"","database":""}'){ navigate("/"); }

    useEffect(() => {

        getUsers();
    }, [])

    useEffect(() => {

        if (creationStatus === "Ok") {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: 'Usuario creado correctamente'
            })

            setCreationStatus("");
            getUsers();
        };

    }, [creationStatus])

    useEffect(() => {

        if (updateStatus === "Ok") {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: 'Usuario actualizado correctamente'
            })

            setUpdateStatus("");
            getUsers();
        };

    }, [updateStatus])

    useEffect(() => {

        if (changePasswordStatus === "Ok") {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: 'Contraseña actualizada correctamente'
            })

            setChangePasswordStatus("");
        };

    }, [changePasswordStatus])

    useEffect(() => {

        if (inactiveStatus === "Ok") {

            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: 'Estado actualizado correctamente'
            })

            setInactiveStatus("");
            getUsers();
        };

    }, [inactiveStatus])

    useEffect(() => {

        if (modalType === "crear") {
            setModalContent(<Fragment>
                <Text className="boldText" color={"#808184"}
                    marginRight={0} padding={0} fontSize={1}
                    marginLeft={"23%"} marginTop={2} marginBottom={1}
                >Nombre de usuario</Text>

                <input onChange={(e) => { setNameUser(e.target.value) }} name="userName"></input>

                <Text
                    className="boldText" color={"#808184"}
                    marginRight={0} padding={0} fontSize={1}
                    marginLeft={"23%"} marginTop={2} marginBottom={1}
                >Contraseña</Text>
                <input onChange={(e) => { setPasswordUser(e.target.value) }} name="userPassword"></input>

                <Text
                    className="boldText" color={"#808184"}
                    marginRight={0} padding={0} fontSize={1}
                    marginLeft={"23%"} marginTop={2} marginBottom={1}
                >Activo</Text>

                <input type="checkbox" onChange={(e) => { setActiveUser(!activeUser) }}></input>

                <div className="modalBtnsBox">
                    <button className="boldText btnModal cancelBtn"
                        onClick={() => setModalType("")}>Cancelar</button>
                    <button className="boldText btnModal submitBtn"
                        onClick={() => createUser()}>Crear</button>
                </div>
            </Fragment>);

            setModalState(true);

        } else if (modalType === "eliminar") {

            setModalContent(<Fragment>
                <Text
                    className="boldText" color={"#808184"}
                    marginRight={0} padding={0} fontSize={1}
                    marginLeft={"23%"} marginTop={2} marginBottom={1}
                >Activo</Text>

                <input type="checkbox" onChange={(e) => { setActiveUser(!activeUser) }} defaultChecked={activeUser}></input>

                <div className="modalBtnsBox">
                    <button className="boldText btnModal cancelBtn"
                        onClick={() => setModalType("")}>Cancelar</button>
                    <button className="boldText btnModal submitBtn"
                        onClick={() => inactiveUser()}>Actualizar</button>
                </div>
            </Fragment>);

            setModalState(true);

        } else if (modalType === "editar") {
            setModalContent(<Fragment>
                <Text className="boldText" color={"#808184"}
                    marginRight={0} padding={0} fontSize={1}
                    marginLeft={"23%"} marginTop={2} marginBottom={1}
                >Nombre de usuario</Text>

                <input onChange={(e) => { setNameUser(e.target.value) }} defaultValue={nameUser} name="userName"></input>

                <div className="modalBtnsBox">
                    <button className="boldText btnModal cancelBtn"
                        onClick={() => setModalType("")}>Cancelar</button>
                    <button className="boldText btnModal submitBtn"
                        onClick={() => updateUser()}>Actualizar</button>
                </div>
            </Fragment>);

            setModalState(true);

        } else if (modalType === "contrasena") {
            setModalContent(<Fragment>
                <Text
                    className="boldText" color={"#808184"}
                    marginRight={0} padding={0} fontSize={1}
                    marginLeft={"23%"} marginTop={2} marginBottom={1}
                >Contraseña</Text>
                <input onChange={(e) => { setPasswordUser(e.target.value) }} name="userPassword"></input>

                <div className="modalBtnsBox">
                    <button className="boldText btnModal cancelBtn"
                        onClick={() => setModalType("")}>Cancelar</button>
                    <button className="boldText btnModal submitBtn"
                        onClick={() => updatePassword()}>Actualizar</button>
                </div>
            </Fragment>);

            setModalState(true);

        } else if (modalType === "detalles") {
            setModalContent(<Fragment>
                <Text className="boldText" color={"#808184"}
                    marginRight={0} padding={0} fontSize={1}
                    marginLeft={"23%"} marginTop={2} marginBottom={1}
                >Nombre de usuario</Text>
                <input disabled onChange={(e) => { setNameUser(e.target.value) }} defaultValue={nameUser} name="userName"></input>

                <Text
                    className="boldText" color={"#808184"}
                    marginRight={0} padding={0} fontSize={1}
                    marginLeft={"23%"} marginTop={2} marginBottom={1}
                >Activo</Text>
                <input disabled type="checkbox" onChange={(e) => { setActiveUser(!activeUser) }} defaultChecked={activeUser}></input>

                <div className="modalBtnsBox">
                    <button className="boldText btnModal closeBtn"
                        onClick={() => setModalType("")}>Cerrar</button>
                </div>
            </Fragment>);

            setModalState(true);

        } else if (modalType === "") {

            setModalState(false);
            setUserUid("");
            setNameUser("");
            setPasswordUser("");
            setActiveUser(false);

        };

    }, [modalType, nameUser, passwordUser, activeUser])

    const dataPerPage = 10;
    const pagesVisited = pageNumber * dataPerPage;
    const pageCount = Math.ceil(data.length / dataPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected)
    };

    const displayData = data !== null && Array.isArray(data) ? data.slice(pagesVisited, pagesVisited + dataPerPage)
        .map((data) => {
            return (
                <Box key={data.Id} className="filters" width={"95%"} marginLeft={"2.5%"} marginTop={2}
                    marginRight={0} marginBottom={0} minHeight={7}
                    backgroundColor="#FFFFFF">

                    <p className="result text">{data.Username}</p>

                    <button className="resultBtn passwordBtn boldText"
                        onClick={() => {
                            setUserUid(data.Id); setNameUser(data.Username); setPasswordUser(data.Password);
                            setActiveUser(data.Activo); setModalType("contrasena")
                        }}>Cambiar contraseña</button>

                    <button className="resultBtn editBtn boldText"
                        onClick={() => {
                            setUserUid(data.Id); setNameUser(data.Username); setPasswordUser(data.Password);
                            setActiveUser(data.Activo); setModalType("editar")
                        }}
                    >Editar</button>

                    <button className="resultBtn detailBtn boldText"
                        onClick={() => {
                            setUserUid(data.Id); setNameUser(data.Username); setPasswordUser(data.Password);
                            setActiveUser(data.Activo); setModalType("detalles")
                        }}>Detalles</button>

                    <button className="resultBtn stateBtn boldText" 
                        onClick={() => {
                            setUserUid(data.Id); setNameUser(data.Username); setPasswordUser(data.Password);
                            setActiveUser(data.Activo); setModalType("eliminar")
                        }}>Cambiar estado</button>

                </Box>
            );
        }) : <></>;

    const showErrorMessage = (errorMessage) => {

        if (errorMessage !== null && errorMessage !== "") {
            Swal.fire({
                title: 'Error!',
                text: errorMessage,
                icon: 'error',
                confirmButtonText: 'Aceptar'
            })
        };

    };

    const inactiveWarning = () => {

        Swal.fire({
            title: '¡Advertencia!',
            text: "¿Está seguro de querer eliminar este usuario?",
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Aceptar',
            denyButtonText: 'Cancelar'

        }).then((result) => {

            if (result.isConfirmed) {

                inactiveUser();
            } else if (result.isDenied) {
                setModalType("");
                setUserUid("");
                setNameUser("");
                setPasswordUser("");
                setActiveUser(false);
            }
        });

    };

    const getUsers = async () => {

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        let myHeaders = new Headers();
        myHeaders.append("Authorization", 'Bearer ' + items.token);

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        await fetch(`${process.env.REACT_APP_API_URL}usuarios/ListaUsuarios`, requestOptions)
            .then(response => response.text())
            .then(result => setData(JSON.parse(result)))
            .catch(error => showErrorMessage(error));

    };

    const createUser = async () => {

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        let active = 0;

        if (activeUser) {
            active = 1;
        } else {
            active = 0;
        };

        let myHeaders = new Headers();
        myHeaders.append("Authorization", 'Bearer ' + items.token);
        myHeaders.append("Content-Type", "application/json");

        if (nameUser !== "" && passwordUser !== "") {
            let raw = JSON.stringify({
                "Username": nameUser,
                "Password": passwordUser,
                "Activo": active
            });

            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            await fetch(`${process.env.REACT_APP_API_URL}usuarios/InsertarUsuario`, requestOptions)
                .then(response => response.text())
                .then(result => setCreationStatus(JSON.parse(result)))
                .catch(error => showErrorMessage(error));


            setModalType("");
            setUserUid("");
            setNameUser("");
            setPasswordUser("");
            setActiveUser(false);

        };

    };

    const updateUser = async () => {

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        let active = 0;

        if (activeUser) {
            active = 1;
        } else {
            active = 0;
        };

        let myHeaders = new Headers();
        myHeaders.append("Authorization", 'Bearer ' + items.token);
        myHeaders.append("Content-Type", "application/json");

        if (nameUser) {
            let raw = JSON.stringify({
                "Id": userUid,
                "Username": nameUser,
                "Password": passwordUser,
                "Activo": active
            });

            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            await fetch(`${process.env.REACT_APP_API_URL}usuarios/ActualizarUsuario`, requestOptions)
                .then(response => response.text())
                .then(result => setUpdateStatus(JSON.parse(result)))
                .catch(error => showErrorMessage(error));

            setModalType("");
            setUserUid("");
            setNameUser("");
            setPasswordUser("");
            setActiveUser(false);
        };

    };

    const updatePassword = async () => {

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        let active = 0;

        if (activeUser) {
            active = 1;
        } else {
            active = 0;
        };

        let myHeaders = new Headers();
        myHeaders.append("Authorization", 'Bearer ' + items.token);
        myHeaders.append("Content-Type", "application/json");

        if (passwordUser !== "") {
            let raw = JSON.stringify({
                "Id": userUid,
                "Username": nameUser,
                "Password": passwordUser,
                "Activo": active
            });

            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            await fetch(`${process.env.REACT_APP_API_URL}usuarios/ActualizarUsuario`, requestOptions)
                .then(response => response.text())
                .then(result => setChangePasswordStatus(JSON.parse(result)))
                .catch(error => showErrorMessage(error));

            setModalType("");
            setUserUid("");
            setNameUser("");
            setPasswordUser("");
            setActiveUser(false);
            getUsers();
        };

    };

    const inactiveUser = async () => {

        let myHeaders = new Headers();

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        myHeaders.append("Authorization", 'Bearer ' + items.token);
        myHeaders.append("Content-Type", "application/json");

        let active = 0;

        if(activeUser){
            active = 1;
        };

        let raw = JSON.stringify({
            "Id": userUid,
            "Activo": active
        });

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        await fetch(`${process.env.REACT_APP_API_URL}admin/Inactivar_Activar_Usuarios`, requestOptions)
            .then(response => response.text())
            .then(result => setInactiveStatus(JSON.parse(result)))
            .catch(error => showErrorMessage(error));

        setModalType("");
        setUserUid("");
        setNameUser("");
        setPasswordUser("");
        setActiveUser(false);
        getUsers();
    };

    return (
        <div style={{ display: "flex" }}>
            <Sidebar sidebarClass={sidebarClass} handleViewSidebar={handleViewSidebar}
                usersSelected={usersSelected} sidebarToggleClass={sidebarToggleClass} />

            <div className={usersClass}>
                <Header></Header>

                <Text className="boldText" color="#000000" fontSize={1.5}
                    marginRight={0} padding={0}
                    marginLeft={"2.5%"} marginTop={4} marginBottom={0}>Lista de usuarios</Text>

                <button className="addUserBtn boldText"
                    onClick={() => setModalType("crear")}>Agregar nuevo usuario</button>

                <ReactPaginate
                    previousLabel={"Anterior"}
                    nextLabel={"Siguiente"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBtns"}
                    previousLinkClassName={"previousBtn"}
                    nextLinkClassName={"nextBtn"}
                    disabledClassName={"paginationDisable"}
                    activeClassName={"paginationActive"}
                />

                <div className="resultsTitles">

                    <Text className="boldText" color={"#808184"}
                        marginRight={0} padding={0} fontSize={1}
                        marginLeft={"4.5%"} marginTop={1} marginBottom={0}>Codigo de usuario</Text>

                </div>

                <div className="usersResults" style={{ marginBottom: "8vh" }}>

                    {displayData}

                </div>

                <Modal className={"createInputs"} title={"Crear"} state={modalState} changeState={setModalState} modalType={setModalType}>

                    {modalContent}

                </Modal>

                <Footer></Footer>
            </div>

        </div>
    );

};

export default Users;