import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/sidebar";
import Header from "../components/header";
import Footer from "../components/footer";
import Box from "../components/box";
import Text from "../components/text";
import Modal from "../components/modal";
import ReactPaginate from "react-paginate";
import Swal from 'sweetalert2';
import Box1 from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Select from 'react-select';
import Autocomplete from '@mui/material/Autocomplete';
import CryptoJS from 'crypto-js';

const Assigments = ({
    assignmentClass,
    sidebarClass,
    sidebarToggleClass,
    handleViewSidebar,
    assigmentSelected
}) => {

    const [data, setData] = useState([]);
    const [users, setUsers] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [societies, setSocieties] = useState([]);
    const [roles, setRoles] = useState([]);
    const [displayAssigments, setdisplayAssigments] = useState();
    const [societiesOptions, setSocietiesOptions] = useState();
    const [rolesOptions, setRolesOptions] = useState();

    const [pageNumber, setPageNumber] = useState(0);
    const [modalState, setModalState] = useState(false);
    const [assigmentId, setAssigmentId] = useState(0);
    const [userName, setUsername] = useState("");
    const [selectedUser, setSelectedUser] = useState(0);
    const [rolName, setRolName] = useState("");
    const [defaultIdRol, setDefaultIdRol] = useState(0);
    const [idRol, setIdRol] = useState(0);
    const [societyName, setSocietyName] = useState("");
    const [defaultSocietyId, setDefaultSocietyId] = useState(0);
    const [societyId, setSocietyId] = useState(0);
    const [inactiveStatus, setInactiveStatus] = useState("");
    const [modalContent, setModalContent] = useState();
    const [modalType, setModalType] = useState();
    const [creationStatus, setCreationStatus] = useState("");
    const [updateStatus, setUpdateStatus] = useState("");

    let navigate = useNavigate();

    let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
    let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
    const userDataLocalStorage = JSON.parse(originalText);

    if(userDataLocalStorage?.userRol?.ModuloAdministracion != "Y"){ navigate("/factura/crear"); }
    if(userDataLocalStorage?.userRol < 0 || userDataLocalStorage == undefined || userDataLocalStorage == null || userDataLocalStorage == '{"username":"","database":""}'){ navigate("/"); }

    useEffect(() => {

        getAssigments();
    }, [])

    useEffect(() => {

        const displayData = data !== null && Array.isArray(data) ? data.slice(pagesVisited, pagesVisited + dataPerPage)
            .map((data) => {

                return (
                    <Box key={data.Id} className="filters" width={"95%"} marginLeft={"2.5%"} marginTop={2}
                        marginRight={0} marginBottom={0} minHeight={7}
                        backgroundColor="#FFFFFF">

                        <Text className="text" color={"#000000"}
                            marginRight={0} padding={0} fontSize={1}
                            marginLeft={"2%"} marginTop={0} marginBottom={0}
                            width={"20%"}
                        >{data.NombreUsuario}</Text>


                        <Text className="text" color={"#000000"}
                            marginRight={0} padding={0} fontSize={1}
                            marginLeft={"0%"} marginTop={0} marginBottom={0}
                            width={"30%"}
                        >{data.Sociedades.NombreBD}</Text>



                        <Text className="text" color={"#000000"}
                            marginRight={0} padding={0} fontSize={1}
                            marginLeft={"0%"} marginTop={0} marginBottom={0}
                            width={"20%"}
                        >{data.Roles.NombreRol}</Text>

                        <button className="resultBtn editBtn boldText"
                            onClick={() => {
                                setAssigmentId(data.Id); setSelectedUser(data.IdUsuario); setUsername(data.NombreUsuario);
                                setRolName(data.Roles.NombreRol); setDefaultIdRol(data.Roles.IdRol); setIdRol(data.Roles.IdRol);
                                setSocietyName(data.Sociedades.NombreBD); setDefaultSocietyId(data.Sociedades.IdSociedad); setSocietyId(data.Sociedades.IdSociedad);
                                setModalType("editar")
                            }}>Editar</button>

                        <button className="resultBtn delete boldText"
                            onClick={() => { setAssigmentId(data.Id); setModalType("eliminar") }}>Eliminar</button>

                    </Box>
                );
            }) : <></>;

        setdisplayAssigments(displayData);

    }, [data])

    useEffect(() => {

        let list = [];

        if (users !== null && Array.isArray(users)) {

            users.forEach(element => {
                list.push({ value: element.Id, label: element.Username });
            });

        };


        setUsersList(list);

    }, [users])

    useEffect(() => {

        const societiesOptions = societies !== null && Array.isArray(societies) ? societies.map((societie) => {

            return (
                <option key={societie.Id} value={societie.Id} style={{ fontSize: "1vw" }}>{societie.NombreBaseDatos}</option>
            );
        }) : <></>;

        setSocietiesOptions(societiesOptions);

    }, [societies])

    useEffect(() => {

        const rolesOptions = roles !== null && Array.isArray(roles) ? roles.map((rol) => {
            return (
                <option key={rol.Id} value={rol.Id} style={{ fontSize: "1vw" }}>{rol.Nombre}</option>
            );
        }) : <></>;

        setRolesOptions(rolesOptions);

    }, [roles])

    useEffect(() => {

        if (creationStatus === "Ok") {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: 'Asignación creada correctamente'
            })

            setCreationStatus("");
            getAssigments();
        };

    }, [creationStatus])

    useEffect(() => {

        if (updateStatus === "Ok") {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: 'Asignación actualizada correctamente'
            })

            setUpdateStatus("");
            getAssigments();
        };

    }, [updateStatus])

    useEffect(() => {

        if (inactiveStatus === "Ok") {

            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: 'Asignación eliminada correctamente'
            })

            setInactiveStatus("");
            getAssigments();
        };

    }, [inactiveStatus])

    useEffect(() => {
        if (modalType === "crear") {
            setModalContent(<Fragment>

                <Text
                    className="boldText" color={"#808184"}
                    marginRight={0} padding={0} fontSize={1}
                    marginLeft={"23%"} marginTop={2} marginBottom={2}
                >Nombre de usuario</Text>

                <Select options={usersList} className="selectUsers"
                    onChange={(evnt) => {
                        setSelectedUser(evnt.value);
                    }}
                    placeholder={'Buscar...'}
                ></Select>

                <Text
                    className="boldText" color={"#808184"}
                    marginRight={0} padding={0} fontSize={1}
                    marginLeft={"23%"} marginTop={2} marginBottom={1}
                >Nombre de la sociedad</Text>
                <select className="selectFields" aria-label="size 3 select example" onChange={(e) => setSocietyId(e.target.value)}>
                    <option value={0} style={{ fontSize: "1vw" }}>Seleccione...</option>
                    {societiesOptions}
                </select>

                <Text
                    className="boldText" color={"#808184"}
                    marginRight={0} padding={0} fontSize={1}
                    marginLeft={"23%"} marginTop={2} marginBottom={1}
                >Nombre del rol</Text>
                <select className="selectFields" aria-label="size 3 select example" onChange={(e) => setIdRol(e.target.value)}>
                    <option value={0} style={{ fontSize: "1vw" }}>Seleccione...</option>
                    {rolesOptions}
                </select>

                <div className='modalBtnsBox'>
                    <button className="boldText btnModal cancelBtn"
                        onClick={() => setModalType("")}>Cancelar</button>
                    <button className="boldText btnModal submitBtn"
                        onClick={() => { createAssigment() }}>Crear</button>
                </div>
            </Fragment>);

            setModalState(true);

        } else if (modalType === "editar") {
            setModalContent(<Fragment>

                <Text
                    className="boldText" color={"#808184"}
                    marginRight={0} padding={0} fontSize={1}
                    marginLeft={"23%"} marginTop={2} marginBottom={2}
                >Nombre de usuario</Text>

                <Select options={usersList} className="selectUsers"
                    onChange={(evnt) => {
                        setSelectedUser(evnt.value);
                    }}
                    placeholder={userName}
                ></Select>

                <Text
                    className="boldText" color={"#808184"}
                    marginRight={0} padding={0} fontSize={1}
                    marginLeft={"23%"} marginTop={2} marginBottom={1}
                >Nombre de la sociedad</Text>
                <select className="selectFields" aria-label="size 3 select example" onChange={(e) => setSocietyId(e.target.value)}>
                    {/*TODO: revisar si dejar o eliminar <option value={defaultSocietyId} style={{ fontSize: "1vw" }}>{societyName}</option> */ }

                    {societiesOptions}
                </select>

                <Text
                    className="boldText" color={"#808184"}
                    marginRight={0} padding={0} fontSize={1}
                    marginLeft={"23%"} marginTop={2} marginBottom={1}
                >Nombre del rol</Text>
                <select className="selectFields" aria-label="size 3 select example" onChange={(e) => setIdRol(e.target.value)}>
                    {/*TODO: revisar si dejar o eliminar <option value={defaultIdRol} style={{ fontSize: "1vw" }}>{rolName}</option> */ }
                    {rolesOptions}
                </select>

                <div className='modalBtnsBox'>
                    <button className="boldText btnModal cancelBtn"
                        onClick={() => setModalType("")}>Cancelar</button>
                    <button className="boldText btnModal submitBtn"
                        onClick={() => { updateAssigment(assigmentId) }}>Actualizar</button>
                </div>
            </Fragment>);

            setModalState(true);

        } else if (modalType === "eliminar") {

            inactiveWarning();

        } else if (modalType === "") {

            setUsername("");
            setSelectedUser(0);
            setRolName("");
            setIdRol(0);
            setDefaultIdRol(0);
            setSocietyName("");
            setDefaultSocietyId(0);
            setSocietyId(0);
            setModalState(false);

        };

    }, [modalType, selectedUser, idRol, societyId])

    const dataPerPage = 10;
    const pagesVisited = pageNumber * dataPerPage;
    const pageCount = Math.ceil(data.length / dataPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected)
    };

    const showErrorMessage = (errorMessage) => {

        if (errorMessage !== null && errorMessage !== "") {
            Swal.fire({
                title: 'Error!',
                text: errorMessage,
                icon: 'error',
                confirmButtonText: 'Aceptar'
            })
        };

    };

    const inactiveWarning = () => {

        Swal.fire({
            title: '¡Advertencia!',
            text: "¿Está seguro de querer eliminar esta asignación?",
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Aceptar',
            denyButtonText: 'Cancelar'

        }).then((result) => {

            if (result.isConfirmed) {

                inactiveAssigment();
            } else if (result.isDenied) {

            }
        });

    };

    const getAssigments = async () => {

        let securityToken = "";

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        if (items) {
            securityToken = items.token;
        };

        let myHeaders = new Headers();
        myHeaders.append("Authorization", 'Bearer ' + securityToken);

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        await fetch(`${process.env.REACT_APP_API_URL}admin/ListarUsuario_Roles`, requestOptions)
            .then(response => response.text())
            .then(result => setData(JSON.parse(result)))
            .catch(error => showErrorMessage(error));

        await fetch(`${process.env.REACT_APP_API_URL}admin/ListarSociedades`, requestOptions)
            .then(response => response.text())
            .then(result => setSocieties(JSON.parse(result)))
            .catch(error => showErrorMessage(error));

        await fetch(`${process.env.REACT_APP_API_URL}admin/ListarRoles`, requestOptions)
            .then(response => response.text())
            .then(result => setRoles(JSON.parse(result)))
            .catch(error => showErrorMessage(error));

        await fetch(`${process.env.REACT_APP_API_URL}usuarios/ListaUsuarios`, requestOptions)
            .then(response => response.text())
            .then(result => setUsers(JSON.parse(result)))
            .catch(error => showErrorMessage(error));

    };

    const createAssigment = async () => {

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        let myHeaders = new Headers();
        myHeaders.append("Authorization", 'Bearer ' + items.token);
        myHeaders.append("Content-Type", "application/json");

        if (selectedUser !== 0 && idRol !== 0 && societyId !== 0) {
            let raw = JSON.stringify({
                "IdUsuario": selectedUser,
                "IdRol": idRol,
                "IdSociedad": societyId,
            });

            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            await fetch(`${process.env.REACT_APP_API_URL}admin/InsertarUsuarios_Roles`, requestOptions)
                .then(response => response.text())
                .then(result => setCreationStatus(JSON.parse(result)))
                .catch(error => showErrorMessage(error));


            setModalType("");
            setUsername("");
            setSelectedUser(0);
            setRolName("");
            setIdRol(0);
            setDefaultIdRol(0);
            setSocietyName("");
            setDefaultSocietyId(0);
            setSocietyId(0);
        };
    };

    const updateAssigment = async (assigmentId) => {

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        let myHeaders = new Headers();
        myHeaders.append("Authorization", 'Bearer ' + items.token);
        myHeaders.append("Content-Type", "application/json");

        if (selectedUser !== 0 && idRol !== 0 && societyId !== 0) {
            let raw = JSON.stringify({
                "Id": assigmentId,
                "IdUsuario": selectedUser,
                "IdRol": idRol,
                "IdSociedad": societyId,
            });

            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            await fetch(`${process.env.REACT_APP_API_URL}admin/ActualizarUsuario_Roles`, requestOptions)
                .then(response => response.text())
                .then(result => setUpdateStatus(JSON.parse(result)))
                .catch(error => showErrorMessage(error));


            setModalType("");
            setUsername("");
            setSelectedUser(0);
            setRolName("");
            setIdRol(0);
            setDefaultIdRol(0);
            setSocietyName("");
            setDefaultSocietyId(0);
            setSocietyId(0);
        };
    };

    const inactiveAssigment = async () => {

        let myHeaders = new Headers();

        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        const items = JSON.parse(originalText);

        myHeaders.append("Authorization", 'Bearer ' + items.token);
        myHeaders.append("Content-Type", "application/json");


        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };

        await fetch(`${process.env.REACT_APP_API_URL}admin/EliminarUsuarios_Roles?Id=${assigmentId}`, requestOptions)
            .then(response => response.text())
            .then(result => setInactiveStatus(JSON.parse(result)))
            .catch(error => showErrorMessage(error));

        setModalType("");
        setUsername("");
        setSelectedUser(0);
        setRolName("");
        setIdRol(0);
        setDefaultIdRol(0);
        setSocietyName("");
        setDefaultSocietyId(0);
        setSocietyId(0);
        getAssigments();

    };

    return (
        <div style={{ display: "flex" }}>
            <Sidebar sidebarClass={sidebarClass} handleViewSidebar={handleViewSidebar}
                assigmentSelected={assigmentSelected} sidebarToggleClass={sidebarToggleClass} />

            <div className={assignmentClass}>
                <Header></Header>

                <Text className="boldText" color="#000000" fontSize={1.5}
                    marginRight={0} padding={0}
                    marginLeft={"2.5%"} marginTop={4} marginBottom={0}>Asignaciones</Text>

                <button className="addAssigmentBtn boldText"
                    onClick={() => { setModalType("crear") }}>Agregar asignación</button>

                <ReactPaginate
                    previousLabel={"Anterior"}
                    nextLabel={"Siguiente"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBtns"}
                    previousLinkClassName={"previousBtn"}
                    nextLinkClassName={"nextBtn"}
                    disabledClassName={"paginationDisable"}
                    activeClassName={"paginationActive"}
                />


                <div className="resultsTitles">

                    <Text className="boldText" color={"#808184"}
                        marginRight={0} padding={0} fontSize={1} width={"19%"}
                        marginLeft={"4.5%"} marginTop={3} marginBottom={1}>Usuario</Text>

                    <Text className="boldText" color={"#808184"}
                        marginRight={0} padding={0} fontSize={1} width={"28.5%"}
                        marginLeft={"0%"} marginTop={3} marginBottom={1}>Sociedad</Text>

                    <Text className="boldText" color={"#808184"}
                        marginRight={0} padding={0} fontSize={1}
                        marginLeft={"0%"} marginTop={3} marginBottom={1}>Rol</Text>

                </div>

                <div className="assigmentsResults" style={{ marginBottom: "8vh" }}>

                    {displayAssigments}

                </div>

                <Modal className={"createInputs"} title={"Crear"} state={modalState} changeState={setModalState} modalType={setModalType}>

                    {modalContent}

                </Modal>

                <Footer></Footer>
            </div>

        </div>
    );

};

export default Assigments;