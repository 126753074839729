import React, { useEffect, useState } from "react";
import Logo from "../assets/Logos/Logo Small Color.png";
import proformaIcon1 from "../assets/Iconos/Proforma gris.png";
import facturaIcon1 from "../assets/Iconos/factura gris.png";
import adminIcon1 from "../assets/Iconos/admin gris.png";
import { Link } from "react-router-dom";
import { TbPoint } from 'react-icons/tb';
import { BsFillArrowRightSquareFill, BsFillArrowLeftSquareFill } from 'react-icons/bs';
import CryptoJS from 'crypto-js';

const Sidebar = ({
    sidebarClass,
    handleViewSidebar,
    sidebarToggleClass,
    proformaSearchSelected,
    proformaCreateSelected,
    billSearchSelected,
    billCreateSelected,
    societiesSelected,
    usersSelected,
    roleSelected,
    assigmentSelected,
    storeSelected
}) => {

    const [proformaView, setProformaView] = useState();
    const [billView, setBillView] = useState();
    const [adminView, setAdminView] = useState();

    let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('userData'), `${process.env.KEY_CRYPTO}`);
    let originalText = bytes.toString(CryptoJS.enc.Utf8);
        
    const items = JSON.parse(originalText);

    useEffect(() => {

        if (items) {

            if (items.userRol) {

                if (items.userRol.ModuloAdministracion === "Y") {
                    setAdminView(true);
                } else {
                    setAdminView(false);
                };

                if (items.userRol.ModuloFacturas === "Y") {
                    setBillView(true);
                } else {
                    setBillView(false);
                };

                if (items.userRol.ModuloProformas === "Y") {
                    setProformaView(true);
                } else {
                    setProformaView(false);
                };

            };

        };

    }, [items])

    return (
        <div>
            <div className={sidebarClass}>
                <div className="top">
                    <img src={Logo} style={{
                        width: "5vw"
                    }}></img>
                </div>

                <div className="center">
                    <ul>

                        {
                            proformaView ?
                                <>
                                    <li>

                                        <img src={proformaIcon1}
                                            style={{
                                                width: "1.5vw"
                                            }}></img>
                                        <span>Proforma</span>

                                    </li>

                                    <li className={`link ` + proformaSearchSelected}>
                                        <Link to={"/proforma/buscar"} style={{ textDecoration: "none" }}>
                                            <TbPoint color="#808184" style={{ marginLeft: "2vw" }} />
                                            <span>Buscar</span>
                                        </Link>
                                    </li>

                                    <li className={`link ` + proformaCreateSelected}>
                                        <Link to={"/proforma/crear"} style={{ textDecoration: "none" }}>
                                            <TbPoint color="#808184" style={{ marginLeft: "2vw" }} />
                                            <span>Crear</span>
                                        </Link>
                                    </li>
                                </>
                                :
                                <></>
                        }

                        {
                            billView ?
                                <>
                                    <li>

                                        <img src={facturaIcon1}
                                            style={{
                                                width: "1.5vw"
                                            }}></img>
                                        <span>Factura</span>

                                    </li>

                                    <li className={`link ` + billSearchSelected}>
                                        <Link to={"/redirect/factura/buscar"} style={{ textDecoration: "none" }}>
                                            <TbPoint color="#808184" style={{ marginLeft: "2vw" }} />
                                            <span>Buscar</span>
                                        </Link>
                                    </li>

                                    <li className={`link ` + billCreateSelected}>
                                        <Link to={"/factura/crear"} style={{ textDecoration: "none" }}>
                                            <TbPoint color="#808184" style={{ marginLeft: "2vw" }} />
                                            <span>Crear</span>
                                        </Link>
                                    </li>
                                </>
                                :
                                <></>
                        }

                        {
                            adminView /*&& items.userRolId === 1*/?
                                <>
                                    <li>

                                        <img src={adminIcon1}
                                            style={{
                                                width: "1.5vw"
                                            }}></img>
                                        <span>Administración</span>

                                    </li>

                                    <li className={`link ` + societiesSelected}>
                                        <Link to={"/administracion/sociedades"} style={{ textDecoration: "none" }}>
                                            <TbPoint color="#808184" style={{ marginLeft: "2vw" }} />
                                            <span>Sociedades</span>
                                        </Link>
                                    </li>

                                    <li className={`link ` + usersSelected}>
                                        <Link to={"/administracion/usuarios"} style={{ textDecoration: "none" }}>
                                            <TbPoint color="#808184" style={{ marginLeft: "2vw" }} />
                                            <span>Usuarios</span>
                                        </Link>
                                    </li>

                                    <li className={`link ` + roleSelected}>
                                        <Link to={"/administracion/roles"} style={{ textDecoration: "none" }}>
                                            <TbPoint color="#808184" style={{ marginLeft: "2vw" }} />
                                            <span>Roles</span>
                                        </Link>
                                    </li>

                                    <li className={`link ` + assigmentSelected}>
                                        <Link to={"/administracion/asignaciones"} style={{ textDecoration: "none" }}>
                                            <TbPoint color="#808184" style={{ marginLeft: "2vw" }} />
                                            <span>Asignaciones</span>
                                        </Link>
                                    </li>

                                    <li className={`link ` + storeSelected}>
                                        <Link to={"/administracion/almacenes"} style={{ textDecoration: "none" }}>
                                            <TbPoint color="#808184" style={{ marginLeft: "2vw" }} />
                                            <span>Almacenes</span>
                                        </Link>
                                    </li>
                                </>
                                :
                                <></>
                        }

                    </ul>
                </div>


            </div>

            <span onClick={handleViewSidebar} className={sidebarToggleClass}>
                {
                    sidebarClass === "sidebar open" ?
                        (
                            <BsFillArrowLeftSquareFill color="#808184" style={{ width: "4vw", height: "8vh", position: "sticky", top: "0" }} />
                        )
                        :
                        (
                            <BsFillArrowRightSquareFill color="#808184" style={{ width: "4vw", height: "8vh", position: "sticky", top: "0" }} />
                        )
                }

            </span>
        </div>
    );
};

export default Sidebar;