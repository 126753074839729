import React from "react";
import Logo from "../assets/Logos/LOGO GRUPO BOSTON Horizontal-37.png";
import Icon from "../assets/Iconos/Asset logo.png";
function Footer() {
    return (
        <footer>

            <div className="row"
                style={{
                    margin: "0",
                    padding: "0",
                    width: "100%"
                }}>

                <div className="col">
                    <img style={{ width: "10vw" }} src={Logo}></img>
                </div>

                <div className="col"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end"
                    }}>
                    <img style={{ width: "2vw" }} src={Icon}></img>
                </div>

            </div>

        </footer>
    );
};

export default Footer